import React, { useRef, useState } from 'react';
import _ from 'lodash';
import TextField from '@mui/material/TextField';
import classNames from 'classnames';
import { connect } from 'react-redux';
import TaxFlowResponseText from '@app/src/Components/TaxFlow/Common/TaxFlowResponseText';
import TaxFlowListItem from '@app/src/Components/TaxFlow/Question/TaxFlowListItem';
import TaxValidationInputLabel from '@app/src/Components/TaxValidation/TaxValidationInputLabel';
import AddIcon from '@app/src/assets/add.svg?react';
import { hasSpecialChars } from '@app/src/global/Helpers';
import { jobCategoriesSelector } from '@app/src/selectors/onboardingSelectors';
import { setCurrentAnswer } from '@app/src/services/taxFlowAnswerService';
import { INCOME_SLUGS } from '@app/src/taxflow/sections/income/incomeConstants';
import { currentAnswerSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { getKnownJobInfo } from '@app/src/taxflow/shared/utils/sharedUtils';

const TaxFlowFormCustomJobItem = (props) => {
  const {
    jobCategories,
    question,
    currentFormAnswer,
    isCustomJobSelected,
    setCurrentAnswer,
    setIsCustomJobSelected,
    setBusinessCode,
    onFocus,
    replaceStrings
  } = props;
  const [customJobText, setCustomJobText] = useState('');

  const inputRef = useRef();

  const setJobNameAnswer = (value) => {
    setCurrentAnswer({
      value: { ..._.get(currentFormAnswer, 'value', {}), [INCOME_SLUGS.FREELANCE_JOB_NAME]: { value: value } }
    });
  };

  const onChange = (e) => {
    if (e.target.value && hasSpecialChars(e.target.value, 'lettersNumbersSpacesHyphens')) {
      return;
    }

    setJobNameAnswer(e.target.value);
    setBusinessCode(null);

    if (question.slug === INCOME_SLUGS.FREELANCE_JOB_NAME) {
      setCustomJobText(e.target.value);
    }
  };

  const onCustomJobInputFocus = () => {
    setJobNameAnswer(customJobText);
    onFocus();
  };

  const handleListItemChange = () => {
    setIsCustomJobSelected(true);
    setJobNameAnswer(customJobText);
    setBusinessCode(null);
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.target.blur();
    }
  };

  let customJobName = customJobText;

  if (!_.isEmpty(customJobText)) {
    const knownJobCategory = getKnownJobInfo(jobCategories, customJobText);
    if (knownJobCategory && knownJobCategory.name) {
      customJobName = knownJobCategory.name;
    }
  }

  return (
    <>
      <TaxFlowListItem handleChange={handleListItemChange} checked={isCustomJobSelected}>
        <AddIcon className='tax-flow-list-item-job-icon' />
        <span>Something else</span>
      </TaxFlowListItem>
      {isCustomJobSelected && (
        <>
          <div className='tax-flow-input-wrap tax-flow-custom-job-item'>
            <TaxValidationInputLabel question={{ title: 'Enter another job or LLC' }} />
            <TextField
              fullWidth
              className={classNames('form-control', {
                'tax-flow-custom-job-item-selected': isCustomJobSelected
              })}
              inputRef={inputRef}
              type='text'
              value={customJobName}
              onChange={onChange}
              placeholder='e.g. Cleaner'
              onFocus={onCustomJobInputFocus}
              onKeyPress={onKeyPress}
              inputProps={{ 'data-testid': 'tax-flow-custom-job-item-selected' }}
            />
          </div>
          <TaxFlowResponseText
            responseTexts={{
              isWarningMessage: false,
              warningTexts: [
                'Got it! Your deductions will automatically get smart-split™ to this new work type. You can make adjustments in the Deductions tab.'
              ]
            }}
            replaceStrings={replaceStrings}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state, props) => ({
  currentFormAnswer: currentAnswerSelector(state),
  jobCategories: jobCategoriesSelector(state),
  replaceStrings: props.replaceStrings || _.identity
});

const mapDispatchToProps = {
  setCurrentAnswer
};

const ConnectedTaxFlowFormCustomJobItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowFormCustomJobItem);

export default ConnectedTaxFlowFormCustomJobItem;
