import React, { ReactElement } from 'react';

interface IconWithFallbackProps {
  iconUrl?: string;
  fallbackIcon?: ReactElement;
  altText: string;
  filter?: string;
}

const IconWithFallback = ({ iconUrl, fallbackIcon, altText, filter }: IconWithFallbackProps): ReactElement | null => {
  if (!iconUrl && !fallbackIcon) return null;
  if (iconUrl) {
    return <img src={iconUrl} height={24} width={24} alt={altText} style={{ filter }} />;
  }
  return fallbackIcon;
};

export default IconWithFallback;
