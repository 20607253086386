import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import OnboardingQuestionItem from '@app/src/Components/Onboarding/Question/OnboardingQuestionItem';
import {
  currentQuestionSelector,
  currentAnswerSelector,
  isCreatingAccountSelector
} from '@app/src/selectors/onboardingSelectors';
import { replaceJobStrings } from '@app/src/services/onboardingService';
import { scrollToTop } from '@app/src/utils';
import isValidInput from '@app/src/utils/onboarding/isValidInput';

class OnboardingQuestion extends React.Component {
  onPrev = () => {
    this.props.history.goBack();
  };

  onNext = () => {
    const { onNext } = this.props;

    if (onNext) {
      onNext();
    }
  };

  componentDidMount() {
    scrollToTop();
  }

  render() {
    const { currentQuestion, currentAnswer, isCreatingAccount, replaceJobStrings, ...props } = this.props;

    return (
      <div className='taxflow-box-wrap'>
        <OnboardingQuestionItem
          isPrev
          {...props}
          currentQuestion={currentQuestion}
          currentAnswer={currentAnswer}
          urlObj={{}}
          onNextQuest={this.onNext}
          onPrev={this.onPrev}
          onNext={this.onNext}
          isNextDisabled={
            !isValidInput({ question: currentQuestion, answer: currentAnswer, currentAnswer }) || isCreatingAccount
          }
          replaceStrings={replaceJobStrings}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentQuestion: currentQuestionSelector(state),
  currentAnswer: currentAnswerSelector(state),
  isCreatingAccount: isCreatingAccountSelector(state)
});

const mapDispatchToProps = {
  replaceJobStrings
};

const ConnectedOnboardingQuestion = connect(mapStateToProps, mapDispatchToProps)(withRouter(OnboardingQuestion));

export default ConnectedOnboardingQuestion;
