import React, { useState } from 'react';
import _ from 'lodash';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { Add } from 'iconsax-react';
import { PulseLoader } from 'react-spinners';
import AssistantFilePreview from '@app/src/Components/Assistant/AssistantFilePreview';
import SendIcon from '@app/src/assets/send.svg?react';
import { getAttachmentType } from '@app/src/global/Helpers';
import useChatFiles from '@app/src/hooks/useChatFiles';
import { colorFontLight, colorFontDark } from '@app/src/theme';
import '@app/src/Components/Assistant/Assistant.scss';

const AssistantFooter = ({ submitMessage, disableSubmit, typing, isLoading = false }) => {
  const [userMessage, setUserMessage] = useState('');
  const { stagedFile, setStagedFile, openFileSelector, onClose, clear, plainFiles, filesContent } = useChatFiles();
  const [isMessageSending, setIsMessageSending] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (disableSubmit) return;

    const currentMessage = userMessage?.trim();
    try {
      setUserMessage('');
      setIsMessageSending(true);
      const fileType = plainFiles[0]?.type;
      const fileContent = _.get(filesContent, '0.content', '');
      const file =
        fileType && fileContent ? { type: fileType, data: fileContent?.split(',')[1], imageUrl: fileContent } : null;

      await submitMessage(currentMessage, file);
    } finally {
      setStagedFile(null);
      clear();
      setIsMessageSending(false);
    }
  };

  return (
    <div className='assistant-footer'>
      <Box component='form' onSubmit={handleSubmit}>
        {typing && (
          <div className='assistant-typing-indicator'>
            <strong>{typing}</strong> is typing
            <div className='assistant-typing-animation'>
              <PulseLoader size={3} color={colorFontLight} speedMultiplier={0.6} />
            </div>
          </div>
        )}

        <div className='assistant-footer-input-container'>
          {stagedFile && (
            <AssistantFilePreview
              file={stagedFile}
              type={getAttachmentType(stagedFile?.type)}
              onClose={onClose}
              messageSending={isMessageSending}
            />
          )}
          <IconButton onClick={openFileSelector}>
            <Add className='assistant-footer-input-container-icon' size='24' color={colorFontDark} />
          </IconButton>
          <TextField
            fullWidth
            variant='outlined'
            onChange={(event) => {
              setUserMessage(event.target.value);
            }}
            placeholder='Ask any question'
            value={userMessage}
            maxRows={5}
            autoComplete='off'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    type='submit'
                    disabled={isLoading || disableSubmit || (_.isEmpty(userMessage.trim()) && !stagedFile)}
                  >
                    {isLoading ? <PulseLoader color='#90949E' speedMultiplier={0.5} size={5} /> : <SendIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
      </Box>
    </div>
  );
};

export default AssistantFooter;
