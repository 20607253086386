import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ToggleButton from '@app/src/Components/Common/ToggleButton/ToggleButton';
import ResponseText from '@app/src/Components/HolisticOnboarding/components/ResponseText/ResponseText';
import { setHolisticOnboardingMeal } from '@app/src/actions/holisticOnboardingActions';
import { contentSelector, loadingSelector, mealSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { onContinue } from '@app/src/services/holisticOnboardingService';
import { getStringSubstitutions } from '@app/src/utils/holisticOnboardingUtils';

const OnboardingMeal = () => {
  const dispatch = useDispatch();

  const {
    location: { pathname },
    push
  } = useHistory();

  const [formattedOptions, setFormattedOptions] = useState([]);

  const loading = useSelector(loadingSelector);
  const content = useSelector(contentSelector);
  const meal = useSelector(mealSelector);

  const options = content[pathname]?.options;

  useEffect(() => {
    if (options) {
      const formattedOptions = options.map(({ text, value }) => ({
        text: dispatch(getStringSubstitutions(text)),
        value
      }));

      setFormattedOptions(formattedOptions);
    }
  }, [options, dispatch, loading]);

  const handleSelect = (value) => {
    dispatch(setHolisticOnboardingMeal(value));

    if (value === 0) {
      setTimeout(() => dispatch(onContinue({ push, pathname })), 500);
    }
  };

  return (
    <Stack spacing={1}>
      {formattedOptions?.map(({ text, value }, idx) => (
        <ToggleButton key={idx} selected={meal === value} onClick={() => handleSelect(value)}>
          {dispatch(getStringSubstitutions(text))}
        </ToggleButton>
      ))}
      <ResponseText pathname={pathname} visible={meal === 1} />
    </Stack>
  );
};

export default OnboardingMeal;
