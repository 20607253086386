import React, { useEffect } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import BankAccountLoader from '@app/src/Components/Common/BankAccountLoader/BankAccountLoader';
import { bankListSelector } from '@app/src/selectors/bankSelectors';
import { onContinue } from '@app/src/services/holisticOnboardingService';
import getImageUrl from '@app/src/utils/getImageUrl';

const OnboardingLoadingInitial = () => {
  const dispatch = useDispatch();

  const bankList = useSelector(bankListSelector);

  const {
    location: { pathname },
    push
  } = useHistory();

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(onContinue({ pathname, push }));
    }, 5500);

    return () => clearTimeout(timer);
  }, [dispatch, pathname, push]);

  const bankName = _.get(bankList, [0, 'institutionName'], '');

  return (
    <div className='onboarding-2-loading'>
      <img
        style={{ display: 'flex', width: '100%', height: 'auto', margin: '0 auto', maxWidth: '300px' }}
        src={getImageUrl('bank-statement.webp')}
        alt='transaction scan'
      />
      <BankAccountLoader name={_.truncate(bankName, 20)} style={{ marginTop: '16px' }} startProgress={30} />
    </div>
  );
};

export default OnboardingLoadingInitial;
