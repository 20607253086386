import _ from 'lodash';
import { createSelector } from 'reselect';
import { workInfoSelector } from '@app/src/api/profileApi';
import {
  PATHNAME_ONBOARDING__DRIVE,
  PATHNAME_ONBOARDING__HOME,
  PATHNAME_ONBOARDING__JOB_DURATION,
  PATHNAME_ONBOARDING__JOB_SELECT,
  PATHNAME_ONBOARDING__LINK,
  PATHNAME_ONBOARDING__MEAL,
  PATHNAME_ONBOARDING__PERCENTAGES,
  PATHNAME_ONBOARDING__PHONE,
  PATHNAME_ONBOARDING__SIGNUP,
  PATHNAME_ONBOARDING__SITUATIONS,
  PATHNAME_ONBOARDING__TRAVEL,
  PATHS_WITHOUT_BACK_BUTTON
} from '@app/src/constants/onboardingConstants';
import { isEmail } from '@app/src/global/Helpers';
import { bankListSelector, viewableAccountsSelector } from '@app/src/selectors/bankSelectors';

// Holistic onboarding selectors
export const contentSelector = (state) => _.get(state, ['holisticOnboarding', 'content']);
export const dependentsSelector = (state) => _.get(state, ['holisticOnboarding', 'dependents']);
export const drawerOpenSelector = (state) => _.get(state, ['holisticOnboarding', 'drawerOpen']);
export const driveSelector = (state) => _.get(state, ['holisticOnboarding', 'drive']);
export const emailSelector = (state) => _.get(state, ['holisticOnboarding', 'email']);
export const errorSelector = (state) => _.get(state, ['holisticOnboarding', 'error']);
export const firstNameSelector = (state) => _.get(state, ['holisticOnboarding', 'firstname']);
export const homeOwnerSelector = (state) => _.get(state, ['holisticOnboarding', 'homeOwner']);
export const homeSelector = (state) => _.get(state, ['holisticOnboarding', 'home']);
export const incomeTypeSelector = (state) => _.get(state, ['holisticOnboarding', 'incomeType']);
export const initialLoadSelector = (state) => _.get(state, ['holisticOnboarding', 'initialLoad']);
export const jobCategoriesSelector = (state) => _.get(state, ['holisticOnboarding', 'jobCategories']);
export const jobDurationSelector = (state) => _.get(state, ['holisticOnboarding', 'jobDuration']);
export const jobsSelector = (state) => _.get(state, ['holisticOnboarding', 'jobs']);
export const lastNameSelector = (state) => _.get(state, ['holisticOnboarding', 'lastname']);
export const loadingSelector = (state) => _.get(state, ['holisticOnboarding', 'loading']);
export const marriedSelector = (state) => _.get(state, ['holisticOnboarding', 'married']);
export const mealSelector = (state) => _.get(state, ['holisticOnboarding', 'meal']);
export const phoneSelector = (state) => _.get(state, ['holisticOnboarding', 'phone']);
export const rentSelector = (state) => _.get(state, ['holisticOnboarding', 'rent']);
export const studentLoansSelector = (state) => _.get(state, ['holisticOnboarding', 'studentLoans']);
export const travelSelector = (state) => _.get(state, ['holisticOnboarding', 'travel']);

export const responseTextSelector = createSelector(
  [contentSelector, (_, { pathname }) => pathname],
  (content, pathname) => _.get(content, `${pathname}.responseText`)
);

// Validation selectors
const incomeTypeValidationSelector = createSelector(incomeTypeSelector, (incomeType) => incomeType?.length > 0);
const driveValidationSelector = createSelector(driveSelector, (drive) => drive !== null);
const emailValidationSelector = createSelector(emailSelector, (email) => isEmail(email));
const firstNameValidationSelector = createSelector(firstNameSelector, (firstName) => firstName.length > 0);
const homeValidationSelector = createSelector(homeSelector, (home) => home !== null);
const jobValidationSelector = createSelector(jobsSelector, (jobs) => jobs?.length > 0);
const lastNameValidationSelector = createSelector(lastNameSelector, (lastName) => lastName.length > 0);
const mealValidationSelector = createSelector(mealSelector, (meal) => meal !== null);
const phoneValidationSelector = createSelector(phoneSelector, (phone) => phone.length === 10);
const travelValidationSelector = createSelector(travelSelector, (travel) => travel !== null);

const taxProfileValidationSelector = createSelector([workInfoSelector], (workInfo) => workInfo?.state_residence);

export const isPreviousSelector = createSelector(
  [contentSelector, (_, { pathname }) => pathname],
  (content, pathname) =>
    _.get(content, `${pathname}.isEntryPoint`, true) === false && !PATHS_WITHOUT_BACK_BUTTON.includes(pathname)
);

export const learnMoreSelector = createSelector(
  [contentSelector, (_, { pathname }) => pathname],
  (content, pathname) => {
    const learnMoreInfo = _.get(content, `${pathname}.learnMoreInfo`);

    return _.get(learnMoreInfo, 'fields', null);
  }
);

export const skippableSelector = createSelector(
  [viewableAccountsSelector, (_, { pathname }) => pathname],
  (bankLinks, pathname) => {
    switch (pathname) {
      case PATHNAME_ONBOARDING__LINK:
        return bankLinks.length === 0;
    }
  }
);

export const validationSelector = createSelector(
  [
    firstNameValidationSelector,
    lastNameValidationSelector,
    emailValidationSelector,
    phoneValidationSelector,
    incomeTypeValidationSelector,
    jobValidationSelector,
    jobDurationSelector,
    driveValidationSelector,
    homeValidationSelector,
    mealValidationSelector,
    travelValidationSelector,
    bankListSelector,
    taxProfileValidationSelector,
    (_, { pathname }) => pathname
  ],
  (
    firstName,
    lastName,
    email,
    phone,
    incomeTypeValidation,
    job,
    jobDuration,
    driveValidation,
    homeValidation,
    mealValidation,
    travelValidation,
    banklist,
    taxProfileValidation,
    pathname
  ) => {
    const validators = {
      [PATHNAME_ONBOARDING__SIGNUP]: firstName && lastName && email,
      [PATHNAME_ONBOARDING__PHONE]: phone,
      [PATHNAME_ONBOARDING__JOB_SELECT]: job,
      [PATHNAME_ONBOARDING__JOB_DURATION]: jobDuration,
      [PATHNAME_ONBOARDING__DRIVE]: driveValidation,
      [PATHNAME_ONBOARDING__HOME]: homeValidation,
      [PATHNAME_ONBOARDING__LINK]: banklist?.length > 0,
      [PATHNAME_ONBOARDING__MEAL]: mealValidation,
      [PATHNAME_ONBOARDING__TRAVEL]: travelValidation,
      [PATHNAME_ONBOARDING__SITUATIONS]: incomeTypeValidation,
      [PATHNAME_ONBOARDING__PERCENTAGES]: taxProfileValidation
    };

    return !!validators[pathname];
  }
);

export const currentProgressSelector = createSelector(
  [contentSelector, (_, { pathname }) => pathname],
  (content, pathname) => {
    if (_.isEmpty(content)) return { totalSections: 10, progress: 0 };

    const activePaths = Object.keys(content);
    const entryPoint = activePaths.find((path) => _.get(content, `${path}.isEntryPoint`, false));
    const order = [entryPoint];

    let nextUrl = _.get(content, `${entryPoint}.nextUrl`);

    while (nextUrl) {
      order.push(nextUrl);
      nextUrl = _.get(content, `${nextUrl}.nextUrl`);
    }

    const formattedPathname = pathname.replace('-w2', '');

    const totalSections = order.length;
    const progress = order.indexOf(formattedPathname) + 1;

    return { totalSections, progress };
  }
);
