import React from 'react';
import { useSelector } from 'react-redux';
import ExpenseReviewModalAdd from '@app/src/Components/ExpenseReview/ExpenseReviewModalAdd';
import ExpenseReviewModalAutoRule from '@app/src/Components/ExpenseReview/ExpenseReviewModalAutoRule';
import ExpenseReviewModalBulkNote from '@app/src/Components/ExpenseReview/ExpenseReviewModalBulkNote';
import ExpenseReviewModalBulkSplit from '@app/src/Components/ExpenseReview/ExpenseReviewModalBulkSplit';
import ExpenseReviewModalEdit from '@app/src/Components/ExpenseReview/ExpenseReviewModalEdit';
import ExpenseReviewModalEditRule from '@app/src/Components/ExpenseReview/ExpenseReviewModalEditRule';
import SubscriptionFlow from '@app/src/Components/Subscription/SubscriptionFlow';
import { expenseReviewModalTypeSelector } from '@app/src/selectors/expenseReviewSelectors';

const ExpenseReviewModalContent = () => {
  const modalType = useSelector(expenseReviewModalTypeSelector);

  switch (modalType) {
    case 'add':
      return <ExpenseReviewModalAdd />;
    case 'edit':
      return <ExpenseReviewModalEdit />;
    case 'rule':
      return <ExpenseReviewModalEditRule />;
    case 'auto-rule':
      return <ExpenseReviewModalAutoRule />;
    case 'bulk-split':
      return <ExpenseReviewModalBulkSplit />;
    case 'bulk-note':
      return <ExpenseReviewModalBulkNote />;
    case 'paywall':
      return <SubscriptionFlow />;
    default:
      return null;
  }
};

export default ExpenseReviewModalContent;
