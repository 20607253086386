import React from 'react';
import { ASK_BUTTON_INTRO_RESPONSE } from '@app/src/Components/Assistant/assistantConstants';
import AskButton from '@app/src/Components/TaxProfile/AskButton';

const AssistantMessagesHeader = () => {
  return (
    <div className='assistant-messages-header'>
      <div className='assistant-messages-header-title'>Welcome to Ask Keeper!</div>
      <div className='assistant-messages-header-subtitle'>
        From your most difficult tax questions to those you're embarrassed to ask, we're here to help.
      </div>
      <div className='assistant-messages-header-button'>
        <AskButton
          message='Tell me more about Ask Keeper'
          response={ASK_BUTTON_INTRO_RESPONSE}
          responseDelay={1}
          origin='ask tab'
          type='info - ask header'
        >
          <div className='assistant-messages-header-button-text'>Learn more</div>
        </AskButton>
      </div>
    </div>
  );
};

export default AssistantMessagesHeader;
