import React, { useState } from 'react';
import PricingMethodContent from '@app/src/Components/Subscription/PricingMethodContent/PricingMethodContent';
import SubscriptionContent from '@app/src/Components/Subscription/SubscriptionContent/SubscriptionContent';
import SubscriptionType from '@app/src/Components/Subscription/SubscriptionType/SubscriptionType';

const SubscriptionFlow = ({ initialScreen }) => {
  const [screen, setScreen] = useState(initialScreen || 'subscriptionBenefits');

  const showBackButton = (screen) => screen !== initialScreen;

  return (
    <>
      {screen === 'subscriptionBenefits' && <SubscriptionContent onNext={setScreen} />}
      {screen === 'subscriptionType' && <SubscriptionType onNext={setScreen} showBackButton={showBackButton} />}
      {screen === 'pricingMethod' && <PricingMethodContent onNext={setScreen} showBackButton={showBackButton} />}
    </>
  );
};

export default SubscriptionFlow;
