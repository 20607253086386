import React from 'react';
import Rating from '@mui/material/Rating';
import { Android, Apple } from 'iconsax-react';

const FreeTrialRatings = () => (
  <div className='flex row space-evenly'>
    <div className='flex column center text-center'>
      <div>
        <span className='bold' style={{ fontSize: '24px' }}>
          4.8
        </span>{' '}
        out of 5
      </div>
      <Rating name='text-feedback' value={4.8} readOnly precision={0.1} />
    </div>
    <div className='flex column center' style={{ lineHeight: '24px' }}>
      <div className='bold'>20,000+ reviews</div>
      <div className='flex row space-evenly'>
        <Apple variant='Bold' />
        <Android variant='Bold' />
      </div>
    </div>
  </div>
);

export default FreeTrialRatings;
