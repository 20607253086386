import React, { useEffect } from 'react';
import _ from 'lodash';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import classNames from 'classnames';
import { ArrowLeft2 } from 'iconsax-react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PaymentElements from '@app/src/Components/Subscription/PaymentModal/PaymentElements';
import { PRICING_ORIGIN__SETTINGS } from '@app/src/constants/pricingConstants';
import { currencyWith0DecimalPlaces } from '@app/src/global/Helpers';
import {
  originSelector,
  subscriptionDefaultsSelector,
  subscriptionPriceSelector,
  subscriptionTypeSelector
} from '@app/src/selectors/pricingSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import '@app/src/Components/Subscription/PricingMethodContent/PricingMethodContent.scss';

const PricingMethodContent = ({ onNext, showBackButton, className }) => {
  const history = useHistory();

  const origin = useSelector(originSelector);

  const defaultInfo = useSelector((state) => subscriptionDefaultsSelector(state, 'pricingMethod'));

  const subscriptionType = useSelector(subscriptionTypeSelector);

  const price = useSelector((state) => subscriptionPriceSelector(state, subscriptionType));
  const period = subscriptionType === 'monthly' ? 'mo' : 'yr';

  const settingsOverrides = {
    title: 'Update payment method',
    CTA: {
      primary: {
        text: 'Update'
      }
    }
  };

  const subscriptionInfo = origin === PRICING_ORIGIN__SETTINGS ? { ...defaultInfo, ...settingsOverrides } : defaultInfo;

  const title = _.get(subscriptionInfo, 'title');
  const cta = _.get(subscriptionInfo, ['CTA', 'primary', 'text']);
  const refundCopy = _.get(subscriptionInfo, 'refundCopy');
  const subtitle = _.chain(subscriptionInfo)
    .get('subtitle')
    .replace('{price}', currencyWith0DecimalPlaces(price))
    .replace('{period}', period)
    .value();

  const backButton = showBackButton('pricingMethod');

  useEffect(() => {
    trackActivity('Expense review: paywall', {});
  });

  return (
    <div className={classNames('paywall-modal', className)}>
      {backButton && (
        <IconButton
          onClick={() => onNext('subscriptionType')}
          style={{ position: 'absolute', left: 0, top: 4, margin: 8 }}
        >
          <ArrowLeft2 />
        </IconButton>
      )}
      <DialogTitle variant='paywall'>{title}</DialogTitle>
      <Stack spacing={2} className='container'>
        {subtitle && <DialogContentText style={{ textAlign: 'left' }}>{subtitle}</DialogContentText>}
        <PaymentElements buttonCopy={cta} history={history} />
        {refundCopy && (
          <div className='paywall-modal-item'>
            <DialogContentText>{refundCopy}</DialogContentText>
          </div>
        )}
      </Stack>
    </div>
  );
};

export default PricingMethodContent;
