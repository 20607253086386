import React from 'react';
import Fade from '@mui/material/Fade';
import { Verify } from 'iconsax-react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { responseTextSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { getStringSubstitutions } from '@app/src/utils/holisticOnboardingUtils';
import '@app/src/Components/HolisticOnboarding/components/ResponseText/ResponseText.scss';

const ResponseText = ({ style, visible }) => {
  const dispatch = useDispatch();

  const location = useLocation();
  const responseText = useSelector((state) => responseTextSelector(state, location));

  return (
    <Fade in={visible} timeout={500}>
      <div style={style} className='response-container'>
        <Verify size={24} />
        <div className='response-container-text'>{dispatch(getStringSubstitutions(responseText))}</div>
      </div>
    </Fade>
  );
};

export default ResponseText;
