import {
  PRICING2__SET_ACCOUNT_DETAILS,
  PRICING2__SET_BANK,
  PRICING2__SET_BANK_LINKS,
  PRICING2__SET_ERROR,
  PRICING2__SET_IS_CARD_VALID,
  PRICING2__SET_LOADING,
  PRICING2__SET_ORIGIN,
  PRICING2__SET_SETTINGS,
  PRICING2__SET_SUBSCRIPTION_INFO,
  PRICING2__SET_SUBSCRIPTION_TYPE,
  PRICING2__SET_SUBSCRIPTION_TYPES,
  PRICING2__SET_UPDATING,
  PRICING2__SET_WALLET_TYPE,
  PRICING2__SET_PLANS_SHOWN
} from '@app/src/constants/pricingConstants';

const initialState = {
  settings: {},
  bankLinks: [],
  subscriptionTypes: [],
  subscriptionInfo: {},
  plansShown: [],
  accountDetails: {},
  loading: false,
  updating: false,
  error: null,
  walletType: null,
  subscriptionType: null,
  isCardValid: false,
  bank: null,
  origin: null
};

const pricingReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRICING2__SET_SETTINGS: {
      return {
        ...state,
        settings: action.settings
      };
    }

    case PRICING2__SET_BANK_LINKS: {
      return {
        ...state,
        bankLinks: action.bankLinks
      };
    }

    case PRICING2__SET_SUBSCRIPTION_TYPES: {
      return {
        ...state,
        subscriptionTypes: action.subscriptionTypes
      };
    }

    case PRICING2__SET_SUBSCRIPTION_INFO: {
      return {
        ...state,
        subscriptionInfo: action.subscriptionInfo
      };
    }

    case PRICING2__SET_PLANS_SHOWN: {
      return {
        ...state,
        plansShown: action.plansShown
      };
    }

    case PRICING2__SET_ACCOUNT_DETAILS: {
      return {
        ...state,
        accountDetails: action.accountDetails
      };
    }

    case PRICING2__SET_SUBSCRIPTION_TYPE: {
      return {
        ...state,
        subscriptionType: action.subscriptionType
      };
    }

    case PRICING2__SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case PRICING2__SET_UPDATING: {
      return {
        ...state,
        updating: action.updating
      };
    }

    case PRICING2__SET_ERROR: {
      return {
        ...state,
        error: action.error
      };
    }

    case PRICING2__SET_WALLET_TYPE: {
      return {
        ...state,
        walletType: action.walletType
      };
    }

    case PRICING2__SET_IS_CARD_VALID: {
      return {
        ...state,
        isCardValid: action.isCardValid
      };
    }

    case PRICING2__SET_BANK: {
      return {
        ...state,
        bank: action.bank
      };
    }

    case PRICING2__SET_ORIGIN: {
      return {
        ...state,
        origin: action.origin
      };
    }

    default: {
      return state;
    }
  }
};

export default pricingReducer;
