import React from 'react';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'react-redux';
import { sendStreamMessage } from '@app/src/actions/assistantActions';
import HelpSupportIcon from '@app/src/assets/HelpSupportIcon';
import { RESPONSE_TEXT_DELAY_MS } from '@app/src/taxflow/shared/constants/sharedConstants';

const AskButton = ({ message, response, responseDelay, field, children, type, origin }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      sendStreamMessage({
        text_type: type || `info - taxProfile.${field}`,
        sub_type: type || `info - taxProfile.${field}`,
        text: message,
        origin,
        response_text: response,
        response_delay: responseDelay || RESPONSE_TEXT_DELAY_MS,
        hide_open_graph_preview: type === 'info - ask header'
      })
    );
  };

  return (
    <IconButton edge='end' onClick={handleClick}>
      {children || <HelpSupportIcon />}
    </IconButton>
  );
};

export default AskButton;
