import React, { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import PhoneField from '@app/src/Components/Common/PhoneField/PhoneField';
import { isEmail, removeExtraWhitespace } from '@app/src/global/Helpers';
import { isImpersonatorSelector, userSettingsSelector } from '@app/src/selectors/authSelectors';
import {
  isEmailLoadingSelector,
  isFirstnameLoadingSelector,
  isLastnameLoadingSelector,
  isPhoneLoadingSelector
} from '@app/src/selectors/settingsSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { updateEmail, updateFirstname, updateLastname, updatePhone } from '@app/src/services/workService';
import '@app/src/Components/Settings/SettingsLoginCredentials.scss';

const SettingsLoginCredentials = ({
  isEmailLoading,
  isPhoneLoading,
  isFirstnameLoading,
  isLastnameLoading,
  updateEmail,
  updatePhone,
  updateFirstname,
  updateLastname,
  settings,
  isImpersonator
}) => {
  const [email, setEmail] = useState(settings.email);
  const [phone, setPhone] = useState(settings.phone);
  const [firstname, setFirstname] = useState(settings.firstname);
  const [lastname, setLastname] = useState(settings.lastname);

  useEffect(() => {
    trackActivity('navigation: login credentials');
  }, []);

  useEffect(() => {
    setEmail(settings.email);
    setPhone(settings.phone);
    setFirstname(settings.firstname);
    setLastname(settings.lastname);
  }, [settings]);

  const onEmailChange = ({ target: { value } }) => {
    setEmail(value);
  };

  const onPhoneChange = ({ target: { value } }) => {
    setPhone(value);
  };

  const onFirstnameChange = ({ target: { value } }) => {
    setFirstname(value);
  };

  const onLastnameChange = ({ target: { value } }) => {
    setLastname(value.replace(/[^a-zA-Z -]/g, ''));
  };

  const onUpdateEmail = async () => {
    if (isEmail(email) && email !== settings.email) {
      await updateEmail(email, settings.email);
    }
  };

  const onUpdatePhone = async () => {
    const phoneNumber = phone.replace(/\D/g, '');

    if (phoneNumber !== settings.phone && phoneNumber.length === 11) {
      await updatePhone(phoneNumber, settings.phone);
    }
  };

  const onUpdateFirstname = async () => {
    const newFirstname = removeExtraWhitespace(firstname);

    if (newFirstname.length >= 1 && newFirstname !== settings.firstname) {
      await updateFirstname(newFirstname, settings.firstname);
    }
  };

  const onUpdateLastname = async () => {
    const newLastname = removeExtraWhitespace(lastname);

    if (newLastname.length >= 1 && newLastname !== settings.lastname) {
      await updateLastname(newLastname, settings.lastname);
    }
  };

  return (
    <>
      <div className='question-title'>Login credentials</div>
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <label htmlFor='firstname'>First name</label>
          <TextField
            fullWidth
            className='form-control'
            id='firstname'
            type='text'
            name='firstname'
            value={firstname}
            disabled={isFirstnameLoading || isImpersonator}
            onChange={onFirstnameChange}
            onBlur={onUpdateFirstname}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <label htmlFor='lastname'>Last name</label>
          <TextField
            fullWidth
            className='form-control'
            id='lastname'
            type='text'
            name='lastname'
            value={lastname}
            disabled={isLastnameLoading || isImpersonator}
            onChange={onLastnameChange}
            onBlur={onUpdateLastname}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <label htmlFor='email'>Email address</label>
          <TextField
            fullWidth
            className='form-control'
            id='email'
            type='email'
            name='email'
            value={email}
            disabled={isEmailLoading || isImpersonator}
            onChange={onEmailChange}
            onBlur={onUpdateEmail}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <label htmlFor='phone'>Phone</label>
          <PhoneField
            name='phone'
            className='form-control'
            mask='+# (###) ###-####'
            required
            value={phone}
            disabled={isPhoneLoading || isImpersonator}
            onChange={onPhoneChange}
            onBlur={onUpdatePhone}
            type='tel'
            inputMode='tel'
            autoComplete='tel'
          />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  isEmailLoading: isEmailLoadingSelector(state),
  isPhoneLoading: isPhoneLoadingSelector(state),
  isFirstnameLoading: isFirstnameLoadingSelector(state),
  isLastnameLoading: isLastnameLoadingSelector(state),
  settings: userSettingsSelector(state),
  isImpersonator: isImpersonatorSelector(state)
});

const mapDispatchToProps = {
  updateEmail,
  updatePhone,
  updateFirstname,
  updateLastname
};

const ConnectedSettingsLoginCredentials = connect(mapStateToProps, mapDispatchToProps)(SettingsLoginCredentials);

export default ConnectedSettingsLoginCredentials;
