import _ from 'lodash';
import axios from 'axios';
import { setCurrentNavigationSection } from '@app/src/actions/taxFlowActions';
import { serverUrl } from '@app/src/global/Environment';
import { currentNavigationSectionSelector } from '@app/src/selectors/navigationListSelectors';

const baseUrl = serverUrl();

const getCurrentNavigationSection = () => async (dispatch) => {
  const res = await axios.get(`${baseUrl}api/taxes/get-current-nav-section`);
  const currentNavSection = _.get(res, ['data', 'data', 'currentNavigationSection'], {});
  dispatch(setCurrentNavigationSection(currentNavSection));
};

export const requireNavigationData = () => async (dispatch, getState) => {
  const currentNavigationSection = currentNavigationSectionSelector(getState());

  if (_.isEmpty(currentNavigationSection)) {
    await dispatch(getCurrentNavigationSection());
  }
};
