import React from 'react';
import Stack from '@mui/material/Stack';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setBank } from '@app/src/actions/pricingActions';
import BankIcon from '@app/src/assets/bank-account.svg?react';
import { bankSelector, chargeableAccountsSelector, updatingSelector } from '@app/src/selectors/pricingSelectors';
import { saveBankPaymentMethod } from '@app/src/services/pricingService';
import '@app/src/Components/Subscription/PaymentModal/PaymentBank.scss';

const PaymentBank = ({ className, history }) => {
  const dispatch = useDispatch();

  const bank = useSelector(bankSelector);
  const chargeableAccounts = useSelector(chargeableAccountsSelector);
  const updating = useSelector(updatingSelector);

  const onToggleBank = (accountId) => {
    if (updating) {
      return;
    }

    dispatch(setBank({ bank: accountId === bank ? null : accountId }));
    dispatch(saveBankPaymentMethod({ history }));
  };

  if (!chargeableAccounts.length) {
    return null;
  }

  return (
    <Stack className={classNames('payment-bank', className)} spacing={1}>
      <div className='payment-bank-text'>Select one of your connected bank accounts:</div>
      {chargeableAccounts.map((chargeableAccount) => (
        <div
          key={chargeableAccount.account_id}
          className={classNames('payment-bank-bank', {
            'payment-bank-bank-active': chargeableAccount.account_id === bank,
            'payment-bank-bank-disabled': updating
          })}
          onClick={() => onToggleBank(chargeableAccount.account_id)}
          role='button'
        >
          {chargeableAccount.logo ? (
            <img
              className='payment-bank-bank-icon'
              src={`data:image/png;base64,${chargeableAccount.logo}`}
              alt={chargeableAccount.institution_name}
            />
          ) : (
            <BankIcon className='payment-bank-bank-icon' />
          )}
          <span>
            {chargeableAccount.name}
            {chargeableAccount.mask && <span> - {chargeableAccount.mask}</span>}
          </span>
        </div>
      ))}
    </Stack>
  );
};

export default PaymentBank;
