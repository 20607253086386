import axios from 'axios';
import { setIsCancelingSubscription, setSettingsLoading, setSettingsModalType } from '@app/src/actions/settingsActions';
import { serverUrl } from '@app/src/global/Environment';
import { subscriptionTypeSelector } from '@app/src/selectors/pricingSelectors';
import { userSelector } from '@app/src/selectors/userSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { getSettings } from '@app/src/services/authService';
import { getChargeableAccount } from '@app/src/services/bankService';
import {
  cancelSubscription,
  createSubscription,
  deleteSubscription,
  getAccountDetails,
  getSubscriptionInfo,
  getSubscriptionTypes,
  refundSubscription
} from '@app/src/services/pricingService';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import { notify } from '@app/src/utils/snackbarUtils';

const baseUrl = serverUrl();

export const loadSettings = () => async (dispatch) => {
  try {
    await Promise.all([
      dispatch(getSettings()),
      dispatch(getAccountDetails()),
      dispatch(getSubscriptionInfo()),
      dispatch(getSubscriptionTypes()),
      dispatch(getChargeableAccount())
    ]);
  } catch (e) {
    defaultCaptureException(e);
  }
};

export const sendChurnFeedback = (comment) => async (dispatch, getState) => {
  try {
    const { email, phone } = userSelector(getState());
    const slackMsg = {
      text: 'Got a new feedback comment',
      attachments: [
        {
          title: phone,
          text: email,
          fields: [
            comment
              ? {
                  title: 'Comment',
                  value: String(comment)
                }
              : {}
          ]
        }
      ]
    };
    await axios.post(`${baseUrl}api/profile/churn-feedback`, { slackMsg });
  } catch (e) {
    defaultCaptureException(e);
  }
};

export const cancelMembership =
  ({ comment, refund }) =>
  async (dispatch) => {
    try {
      dispatch(setIsCancelingSubscription(true));

      if (refund) {
        await dispatch(refundSubscription());

        const message =
          'Your membership has been cancelled and a refund has been initiated. Refunds may take 5 - 10 days to appear on your statement.';

        notify(message);
      }

      await dispatch(cancelSubscription());
      await dispatch(deleteSubscription());
      await dispatch(getAccountDetails());
      await dispatch(getSubscriptionInfo());

      trackActivity('settings: membership canceled', { origin: 'web', comment });
    } catch (e) {
      defaultCaptureException(e);
    } finally {
      dispatch(setIsCancelingSubscription(false));
    }
  };

export const updateSubscriptionType = () => async (dispatch, getState) => {
  try {
    dispatch(setSettingsLoading(true));

    const planType = subscriptionTypeSelector(getState());

    const res = await dispatch(createSubscription({ planType }));

    if (res.data.msg === 'Subscription not created') {
      throw new Error('Something went wrong updating your subscription. Try again, or send us a message in Ask!');
    }

    await dispatch(getSubscriptionInfo());
    await dispatch(getSettings());
    await dispatch(getAccountDetails());

    notify(`Subscription updated to ${planType}.`);
    dispatch(setSettingsModalType(''));
  } catch (e) {
    notify(e.message);
    defaultCaptureException(e);
  } finally {
    dispatch(setSettingsLoading(false));
  }
};
