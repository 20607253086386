import React, { useEffect } from 'react';
import InfoDrawer from '@app/src/Components/HolisticOnboarding/components/InfoDrawer/InfoDrawer';
import LearnMoreButton from '@app/src/Components/HolisticOnboarding/components/LearnMoreButton/LearnMoreButton';
import OnboardingFooter from '@app/src/Components/HolisticOnboarding/components/OnboardingFooter';
import PageTransition from '@app/src/Components/HolisticOnboarding/components/PageTransition';
import ProgressBarHeader from '@app/src/Components/HolisticOnboarding/components/ProgressBarHeader/ProgressBarHeader';
import QuestionBody from '@app/src/Components/HolisticOnboarding/components/QuestionBody';
import QuestionHeader from '@app/src/Components/HolisticOnboarding/components/QuestionHeader';
import RetroLoadingProgressBar from '@app/src/Components/HolisticOnboarding/components/RetroLoadingProgressBar/RetroLoadingProgressBar';
import '@app/src/Components/HolisticOnboarding/containers/HolisticOnboardingLayout.scss';

const HolisticOnboardingLayout = ({ children }) => {
  useEffect(() => {
    const container = document.querySelector('.h-onboarding-container');

    if (container) {
      container.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [children]);

  return (
    <div className='h-onboarding'>
      <div className='h-onboarding-container'>
        <div className='h-onboarding-content'>
          <ProgressBarHeader />
          <RetroLoadingProgressBar />
          <PageTransition>
            <div>
              <QuestionHeader />
              <LearnMoreButton />
              <QuestionBody>{children}</QuestionBody>
            </div>
          </PageTransition>
        </div>
      </div>
      <OnboardingFooter />
      <InfoDrawer />
    </div>
  );
};

export default HolisticOnboardingLayout;
