export function getSubscriptionFeatures(isEligibleForReferralPricing = false) {
  const DISCOUNT_MULTIPLIER = 0.75;
  const multiplier = isEligibleForReferralPricing ? DISCOUNT_MULTIPLIER : 1;

  return {
    premium: {
      title: 'Premium',
      subtitle: 'Billed yearly',
      price: (396 * multiplier).toFixed(0),
      priceSubtitle: `$${((396 * multiplier) / 12).toFixed(0)} /mo`,
      currency: '$',
      frequency: '/yr',
      features: [
        { text: 'File taxes, prepared by tax pro', included: true },
        { text: 'Find deductions automatically', included: true },
        { text: '24/7 expert tax assistance', included: true },
        { text: 'Keeper files your quarterly taxes', included: true },
        { text: 'Guaranteed audit representation', included: true },
        { text: 'Amend or file prior year returns', included: true },
        { text: 'K1s, rental income, etc.', included: true }
      ]
    },
    annual: {
      title: 'Annual',
      subtitle: 'Billed yearly',
      price: (192 * multiplier).toFixed(0),
      priceSubtitle: `$${((192 * multiplier) / 12).toFixed(0)} /mo`,
      currency: '$',
      frequency: '/yr',
      features: [
        { text: 'File taxes, prepared by tax pro', included: true },
        { text: 'Find deductions automatically', included: true },
        { text: '24/7 expert tax assistance', included: true }
      ]
    },
    monthly: {
      title: 'Bookkeeping Only',
      subtitle: '$240/year, billed monthly',
      price: '20',
      currency: '$',
      frequency: '/mo',
      features: [
        { text: 'File taxes, prepared by tax pro', included: false },
        { text: 'Find deductions automatically', included: true },
        { text: '24/7 expert tax assistance', included: true }
      ]
    }
  };
}
