import React from 'react';
import { TickCircle } from 'iconsax-react';
import moment from 'moment';
import { colorNeutralGainsboro, colorSuccess } from '@app/src/theme';
import '@app/src/Components/Subscription/SubscriptionContent/FreeTrialTimeline.scss';

const FreeTrialTimeline = () => (
  <div className='free-trial-container'>
    <div className='free-trial-component'>
      <div className='flex row mb-20'>
        <div className='free-trial-icon'>
          <TickCircle size='24' color={colorSuccess} variant='Bold' />
          <div
            className='free-trial-vertical-line free-trial-vertical-line-green'
            style={{ backgroundColor: colorSuccess }}
          />
        </div>
        <div>
          <div className='text-base bold'>Today: Get instant access</div>
          <div className='base-label'>Get your deductions and find out how much money you'll save!</div>
        </div>
      </div>
      <div className='flex row mb-20'>
        <div className='free-trial-icon'>
          <TickCircle size='24' color={colorNeutralGainsboro} />
          <div className='free-trial-vertical-line free-trial-vertical-line-grey' />
        </div>
        <div>
          <div className='text-base bold'>Your free trial ends on {moment().add(7, 'days').format('MMM DD')}</div>
          <div className='base-label'>Easily cancel anytime if not satisfied.</div>
        </div>
      </div>
      <div className='flex row'>
        <div className='free-trial-icon'>
          <TickCircle size='24' color={colorNeutralGainsboro} />
        </div>
        <div>
          <div className='text-base bold'>At tax time</div>
          <div className='base-label'>
            File with Keeper or easily export your deductions, your largest tax refund guaranteed!
          </div>
        </div>
      </div>
      <div className='free-trial-grey-box'>
        <div className='text-base bold'>100% satisfied or your money back</div>
        <div className='base-label'>
          No-questions-asked refund available until {moment().add(30, 'days').format('MMM DD')}.
        </div>
      </div>
    </div>
  </div>
);

export default FreeTrialTimeline;
