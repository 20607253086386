import React, { useEffect, useRef, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  setHolisticOnboardingEmail,
  setHolisticOnboardingFirstName,
  setHolisticOnboardingLastName
} from '@app/src/actions/holisticOnboardingActions';
import { isEmail } from '@app/src/global/Helpers';
import { emailSelector, firstNameSelector, lastNameSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { onContinue } from '@app/src/services/holisticOnboardingService';

const OnboardingSignup = () => {
  const dispatch = useDispatch();

  const firstname = useSelector(firstNameSelector);
  const lastname = useSelector(lastNameSelector);
  const email = useSelector(emailSelector);

  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);

  const { pathname } = useLocation();
  const { push } = useHistory();

  const nameInput = useRef(null);
  const emailInput = useRef(null);

  useEffect(() => {
    dispatch(setHolisticOnboardingFirstName(''));
    dispatch(setHolisticOnboardingLastName(''));
    dispatch(setHolisticOnboardingEmail(''));
  }, [dispatch]);

  const invalidateName = () => {
    if (firstname.length === 0 || lastname.length === 0) {
      setNameError('Full name is required');
      return false;
    }

    setNameError(false);
    return true;
  };

  const invalidateEmail = () => {
    if (email.length === 0) {
      setEmailError('Email is required');
      return false;
    } else if (!isEmail(email)) {
      setEmailError('Invalid email');
      return false;
    }

    setEmailError(false);
    return true;
  };

  const handleInvalidate = ({ key, target: { name } }) => {
    if (key !== 'Enter') {
      return;
    }

    const isValidName = invalidateName();

    if (name === 'name' && isValidName) {
      emailInput.current.focus();
    }

    const isValidEmail = invalidateEmail();

    if (firstname && lastname && email && isValidName && isValidEmail) {
      dispatch(onContinue({ pathname, push }));
    }
  };

  const handleInput = ({ target: { value, name } }) => {
    if (name === 'name') {
      const [firstName, ...lastName] = value.trim().split(' ');

      dispatch(setHolisticOnboardingFirstName(firstName.trim()));
      dispatch(setHolisticOnboardingLastName(lastName.join(' ').trim()));
    }

    if (name === 'email') {
      dispatch(setHolisticOnboardingEmail(value.trim()));
    }
  };

  return (
    <Stack spacing={1} direction='column'>
      <div className='flex column'>
        <InputLabel htmlFor='name'>Full name</InputLabel>
        <TextField
          inputRef={nameInput}
          id='name'
          name='name'
          variant='outlined'
          margin='none'
          placeholder='e.g. Jordan Smith'
          onBlur={invalidateName}
          onChange={handleInput}
          onKeyDown={handleInvalidate}
          error={!!nameError}
          helperText={nameError}
        />
      </div>
      <div className='flex column'>
        <InputLabel htmlFor='email'>Email</InputLabel>
        <TextField
          inputRef={emailInput}
          id='email'
          name='email'
          variant='outlined'
          margin='none'
          placeholder='e.g. jordan@gmail.com'
          onBlur={invalidateEmail}
          onChange={handleInput}
          onKeyDown={handleInvalidate}
          inputProps={{ inputMode: 'email' }}
          error={!!emailError}
          helperText={emailError}
        />
      </div>
    </Stack>
  );
};

export default OnboardingSignup;
