import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import Oauth from '@app/src/Components/Common/Oauth/Oauth';
import HolisticOnboardingLayout from '@app/src/Components/HolisticOnboarding/containers/HolisticOnboardingLayout';
import OnboardingAppDownload from '@app/src/Components/HolisticOnboarding/pages/OnboardingAppDownload';
import OnboardingBankLink from '@app/src/Components/HolisticOnboarding/pages/OnboardingBankLink';
import OnboardingDrive from '@app/src/Components/HolisticOnboarding/pages/OnboardingDrive';
import OnboardingHome from '@app/src/Components/HolisticOnboarding/pages/OnboardingHome';
import OnboardingJobDuration from '@app/src/Components/HolisticOnboarding/pages/OnboardingJobDuration';
import OnboardingJobSelect from '@app/src/Components/HolisticOnboarding/pages/OnboardingJobSelect';
import OnboardingLoadingInitial from '@app/src/Components/HolisticOnboarding/pages/OnboardingLoadingInitial';
import OnboardingMeal from '@app/src/Components/HolisticOnboarding/pages/OnboardingMeal';
import OnboardingPercentages from '@app/src/Components/HolisticOnboarding/pages/OnboardingPercentages';
import OnboardingPhone from '@app/src/Components/HolisticOnboarding/pages/OnboardingPhone';
import OnboardingSignup from '@app/src/Components/HolisticOnboarding/pages/OnboardingSignup';
import OnboardingSituations from '@app/src/Components/HolisticOnboarding/pages/OnboardingSituations';
import OnboardingTravel from '@app/src/Components/HolisticOnboarding/pages/OnboardingTravel';
import OnboardingInit from '@app/src/Components/Onboarding/pages/OnboardingInit';
import OnboardingRedirect from '@app/src/Components/Onboarding/pages/OnboardingRedirect';
import StripeProvider from '@app/src/Components/Stripe/StripeProvider';
import {
  PATHNAME_ONBOARDING__APP_DOWNLOAD,
  PATHNAME_ONBOARDING__DRIVE,
  PATHNAME_ONBOARDING__HOME,
  PATHNAME_ONBOARDING__JOB_DURATION,
  PATHNAME_ONBOARDING__JOB_SELECT,
  PATHNAME_ONBOARDING__LINK,
  PATHNAME_ONBOARDING__LOADING_INITIAL,
  PATHNAME_ONBOARDING__MEAL,
  PATHNAME_ONBOARDING__PERCENTAGES,
  PATHNAME_ONBOARDING__PHONE,
  PATHNAME_ONBOARDING__SIGNUP,
  PATHNAME_ONBOARDING__SITUATIONS,
  PATHNAME_ONBOARDING__TRAVEL
} from '@app/src/constants/onboardingConstants';
import { contentSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { initOnboarding, initPage } from '@app/src/services/holisticOnboardingService';
import { getOnboardingPrerequisites } from '@app/src/services/onboardingService';

const routes = [
  { path: '/onboarding', Component: OnboardingInit },
  { path: PATHNAME_ONBOARDING__APP_DOWNLOAD, Component: OnboardingAppDownload },
  { path: PATHNAME_ONBOARDING__DRIVE, Component: OnboardingDrive },
  { path: PATHNAME_ONBOARDING__HOME, Component: OnboardingHome },
  { path: PATHNAME_ONBOARDING__JOB_DURATION, Component: OnboardingJobDuration },
  { path: PATHNAME_ONBOARDING__JOB_SELECT, Component: OnboardingJobSelect },
  { path: PATHNAME_ONBOARDING__LINK, Component: OnboardingBankLink },
  { path: PATHNAME_ONBOARDING__LOADING_INITIAL, Component: OnboardingLoadingInitial },
  { path: PATHNAME_ONBOARDING__PERCENTAGES, Component: OnboardingPercentages },
  { path: PATHNAME_ONBOARDING__MEAL, Component: OnboardingMeal },
  { path: PATHNAME_ONBOARDING__PHONE, Component: OnboardingPhone },
  { path: PATHNAME_ONBOARDING__SIGNUP, Component: OnboardingSignup },
  { path: PATHNAME_ONBOARDING__SITUATIONS, Component: OnboardingSituations },
  { path: PATHNAME_ONBOARDING__TRAVEL, Component: OnboardingTravel },
  { path: '/onboarding/oauth', Component: (props) => <Oauth {...props} defaultRoute={PATHNAME_ONBOARDING__LINK} /> },
  { path: '*', Component: OnboardingRedirect }
];

const HolisticOnboardingApp = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const { pathname } = location;

  const content = useSelector(contentSelector);

  useEffect(() => {
    const run = async () => {
      if (_.isEmpty(content)) {
        dispatch(initOnboarding());
      }

      dispatch(initPage({ url: pathname }));
    };

    run();
  }, [content, pathname, dispatch]);

  useEffect(() => {
    dispatch(getOnboardingPrerequisites());
  }, [dispatch]);

  const renderRoute = useCallback(
    () => (
      <StripeProvider>
        <HolisticOnboardingLayout>
          <Switch location={location}>
            {routes.map(({ path, Component }) => (
              <Route key={path} exact path={path} component={Component} />
            ))}
          </Switch>
        </HolisticOnboardingLayout>
      </StripeProvider>
    ),
    [location]
  );

  return <Route render={renderRoute} />;
};

export default HolisticOnboardingApp;
