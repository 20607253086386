import _ from 'lodash';
import moment from 'moment';
import { REFERRER_ID_STORAGE_KEY, REFERRER_STORAGE_KEY } from '@app/src/constants/constants';
import {
  Url_FACEBOOK_CLICK_ID_STORAGE_KEY,
  Url_GOOGLE_CLICK_ID_STORAGE_KEY,
  Url_TIKTOK_STORAGE_KEY
} from '@app/src/constants/onboardingConstants';
import { getCookie } from '@app/src/global/Helpers';
import {
  incomeTypeSelector,
  jobCategoriesSelector,
  jobsSelector,
  marriedSelector
} from '@app/src/selectors/holisticOnboardingSelectors';
import { userSelector } from '@app/src/selectors/userSelectors';
import { generateClientDedupId, getAnalyticsTrait, trackActivity } from '@app/src/services/analyticsService';
import { parseExperimentProperties, parseSignupExperiments } from '@app/src/services/onboardingExperimentService';

export const getMonthsToStartOfLastYear = () => {
  const currentMonth = moment().add(1, 'month').startOf('month');
  const beginningOfLastYear = moment().subtract(1, 'year').startOf('year');

  const months = [];

  while (beginningOfLastYear.isBefore(currentMonth, 'month')) {
    const text = beginningOfLastYear.format('YYYY - MMMM');
    months.push(text);
    beginningOfLastYear.add(1, 'month');
  }

  return months.reverse();
};

export const consolidateContentfulValues = (contentful) => {
  const { items } = contentful;

  return items.reduce((acc, { fields }) => ({ ...acc, [fields.url]: fields }), {});
};

const getWorkAsVerb = (incomeTypes) => {
  if (incomeTypes.includes('contractor') && incomeTypes.includes('owner')) {
    return 'business / freelancing';
  } else if (incomeTypes.includes('owner')) {
    return 'business';
  } else {
    return '1099 / freelancing';
  }
};

const getWorkAsNoun = (incomeTypes, hasArticle, isPlural) => {
  if (incomeTypes.includes('contractor') && incomeTypes.includes('owner')) {
    return (hasArticle ? 'a ' : '') + 'freelancer / business owner' + (isPlural ? 's' : '');
  } else if (incomeTypes.includes('owner')) {
    return (hasArticle ? 'a ' : '') + 'business owner' + (isPlural ? 's' : '');
  } else {
    return (hasArticle ? 'a ' : '') + 'freelancer' + (isPlural ? 's' : '');
  }
};

export const getStringSubstitutions =
  (value = '') =>
  (dispatch, getState) => {
    const localStorage = JSON.parse(getLocalStorage('onboardingState'));

    const jobs = jobsSelector(getState()) || _.get(localStorage, 'jobs', []);
    const rXincomeType = incomeTypeSelector(getState());
    const lsIncomeType = _.get(localStorage, 'incomeType', []);

    const incomeType = !_.isEmpty(rXincomeType) ? rXincomeType : lsIncomeType;

    let updatedValue = value;

    // handle plurals before handling singular
    if (updatedValue.includes('{incomeTypeAsNoun}s')) {
      updatedValue = updatedValue.replaceAll('{incomeTypeAsNoun}s', getWorkAsNoun(incomeType, false, true));
    }

    if (updatedValue.includes('{incomeTypeAsNoun}')) {
      updatedValue = updatedValue.replace('{incomeTypeAsNoun}', getWorkAsNoun(incomeType, true, false));
    }

    if (updatedValue?.includes('{incomeTypeAsVerb}')) {
      updatedValue = updatedValue.replace('{incomeTypeAsVerb}', getWorkAsVerb(incomeType));
    }

    if (updatedValue.includes('{workAsNoun}')) {
      if (jobs.length === 1) {
        const jobInfo = jobCategoriesSelector(getState()).find((category) => category.slug === jobs[0].slug);

        updatedValue = updatedValue.replaceAll('{workAsNoun}', jobInfo?.name?.toLowerCase());
      } else {
        updatedValue = updatedValue.replaceAll('{workAsNoun}', getWorkAsNoun(incomeType));
      }
    }

    if (updatedValue.includes('{workAsNounWithArticle}')) {
      if (jobs.length === 1) {
        const jobInfo = jobCategoriesSelector(getState()).find((category) => category.slug === jobs[0].slug);

        updatedValue = updatedValue.replaceAll('{workAsNounWithArticle}', _.get(jobInfo, 'jobNameWithArticle', ''));
      } else {
        updatedValue = updatedValue.replaceAll('{workAsNounWithArticle}', getWorkAsNoun(incomeType, true, false));
      }
    }

    if (updatedValue.includes('{workAsVerb}')) {
      if (jobs.length === 1) {
        const jobInfo = jobCategoriesSelector(getState()).find((category) => category.slug === jobs[0].slug);

        updatedValue = updatedValue.replaceAll('{workAsVerb}', _.get(jobInfo, 'jobAsVerb', ''));
      } else {
        updatedValue = updatedValue.replaceAll('{workAsVerb}', getWorkAsVerb(incomeType));
      }
    }

    if (updatedValue.includes('{dynamicYouAnd}')) {
      const isMarried = _.get(localStorage, 'married', marriedSelector(getState())) === 'married';

      updatedValue = updatedValue.replaceAll('{dynamicYouAnd}', isMarried ? 'you and your spouse' : 'you');
    }

    if (updatedValue.includes('{dynamicI}')) {
      const isMarried = _.get(localStorage, 'married', marriedSelector(getState())) === 'married';

      updatedValue = updatedValue.replaceAll('{dynamicI}', isMarried ? 'I / my spouse' : 'I');
    }

    if (updatedValue.includes('{dynamicYouOr}')) {
      const isMarried = _.get(localStorage, 'married', marriedSelector(getState())) === 'married';

      updatedValue = updatedValue.replaceAll('{dynamicYouOr}', isMarried ? 'you or your spouse' : 'you');
    }

    if (updatedValue.includes('{carRelatedDescription}')) {
      const job = _.get(jobs, '[0].name');
      const jobInfo = jobCategoriesSelector(getState()).find((category) => category.name === job);

      updatedValue = updatedValue.replaceAll('{carRelatedDescription}', _.get(jobInfo, 'carRelatedDescription'));
    }

    if (updatedValue.includes('{homeOfficeDescription}')) {
      const job = _.get(jobs, '[0].name');
      const jobInfo = jobCategoriesSelector(getState()).find((category) => category.name === job);

      const values = _.get(jobInfo, 'homeOfficeDescription');

      updatedValue = updatedValue.replaceAll('{homeOfficeDescription}', values);
    }

    if (updatedValue.includes('{travelHelpText}')) {
      const job = _.get(jobs, '[0].name');
      const jobInfo = jobCategoriesSelector(getState()).find((category) => category.name === job);

      updatedValue = updatedValue.replaceAll('{travelHelpText}', _.get(jobInfo, 'travelHelpText'));
    }

    if (updatedValue.includes('{whyMightThisPersonGetABusinessMeal}')) {
      const job = _.get(localStorage, 'jobs[0].name');
      const jobInfo = jobCategoriesSelector(getState()).find((category) => category.name === job);

      updatedValue = updatedValue.replaceAll(
        '{whyMightThisPersonGetABusinessMeal}',
        _.get(jobInfo, 'whyMightThisPersonGetABusinessMeal')
      );
    }

    if (updatedValue.includes('{contentfulTypeJobs}')) {
      updatedValue = updatedValue.replaceAll('{contentfulTypeJobs}', userSelector(getState()).contentfulTypeJobs);
    }

    return updatedValue;
  };

export const getLocalStorage = (key) => localStorage.getItem(key);
const setLocalStorage = (key, value) => localStorage.setItem(key, value);
export const clearOnboardingState = () => localStorage.removeItem('onboardingState');

export const preserveProgress = (answer, analyticsInfo) => {
  const onboardingState = JSON.parse(getLocalStorage('onboardingState')) || {};

  setLocalStorage('onboardingState', JSON.stringify({ ...onboardingState, ...answer }));

  trackActivity('question: answer', {
    flow: 'onboarding',
    type: 'holistic-onboarding',
    ...analyticsInfo
  });
};

export const getExperimentProperties = (experiments) => {
  const experimentProperties = parseExperimentProperties(experiments);
  const signupExperiments = parseSignupExperiments(experimentProperties.experiments);

  return {
    experiments: signupExperiments
  };
};

export const getMarketingData = () => {
  const referrer = getCookie(REFERRER_STORAGE_KEY);
  const referrer_id = getCookie(REFERRER_ID_STORAGE_KEY);
  const fbc = getAnalyticsTrait(Url_FACEBOOK_CLICK_ID_STORAGE_KEY);
  const gclid = getAnalyticsTrait(Url_GOOGLE_CLICK_ID_STORAGE_KEY);
  const ttclid = getAnalyticsTrait(Url_TIKTOK_STORAGE_KEY);
  const client_dedup_id = generateClientDedupId();

  return {
    referrer,
    referrer_id,
    client_dedup_id,
    ...(fbc && { fbc }),
    ...(ttclid && { ttclid }),
    ...(gclid && { gclid })
  };
};
