import React, { useEffect } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import BankUnlinkedButton from '@app/src/Components/ExpenseReview/Common/BankUnlinkedButton/BankUnlinkedButton';
import ExpenseReviewExpensesSavingsContainer from '@app/src/Components/ExpenseReview/ExpenseReviewExpensesSavingsContainer';
import ExpenseReviewMenuBar from '@app/src/Components/ExpenseReview/ExpenseReviewMenuBar';
import ExpenseReviewExpensesTable from '@app/src/Components/ExpenseReview/Table/ExpenseTable/ExpenseTable';
import TaxFlowConfirmModal from '@app/src/Components/TaxFlow/Common/TaxFlowConfirmModal';
import TaxFlowPremiumSelfUnlockModal from '@app/src/Components/TaxFlow/Common/TaxFlowPremiumSelfUnlockModal';
import {
  setCategoryFilterSelections,
  setExpenseReviewModalType,
  setExpensesYear
} from '@app/src/actions/expenseReviewActions';
import { useGetCampaignQuery } from '@app/src/api/profileApi';
import { useIsPremiumSubscriber } from '@app/src/hooks/pricingHooks';
import history from '@app/src/keeperHistory';
import {
  expensesYearSelector,
  hasFiltersSelectedSelector,
  isInitialExpensesLoadSelector,
  loadingSelector,
  orderedExpensesSelector
} from '@app/src/selectors/expenseReviewSelectors';
import { selfUnlockModalOpenSelector } from '@app/src/selectors/navigationListSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { getBankSummaryV2 } from '@app/src/services/bankService';
import {
  getExpenseWaiting,
  getInitialExpenses,
  getRules,
  initExpenseReview
} from '@app/src/services/expenseReviewService';
import { getSubscriptionInfo, getSubscriptionTypes } from '@app/src/services/pricingService';
import { moveToNotStarted } from '@app/src/services/taxFlowService';
import { hasActiveSubscriptionSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { advanceToCurrentSectionPage } from '@app/src/taxflow/main/services/mainService';
import { setSelfUnlockModalOpen } from '@app/src/taxflow/navigation/actions/navigationActions';
import '@app/src/Components/ExpenseReview/ExpenseReview.scss';
import '@app/src/Components/ExpenseReview/ExpenseReviewExpenses.scss';

const ExpenseReviewExpenses = ({
  initExpenseReview,
  hasActiveSubscription,
  className,
  expensesYear,
  getExpenseWaiting,
  setExpenseReviewModalType,
  selfUnlockModalOpen,
  setSelfUnlockModalOpen,
  advanceToCurrentSectionPage,
  loading,
  isInitialExpensesLoad,
  setExpensesYear,
  setCategoryFilterSelections
}) => {
  const { search } = useLocation();
  const { data: isPremiumSubscriber } = useIsPremiumSubscriber();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const categoryFilter = searchParams.getAll('category');

    setCategoryFilterSelections(categoryFilter);

    if (searchParams.has('year')) {
      const year = searchParams.get('year');

      if (year === `${moment().year()}` || year === `${moment().year() - 1}`) {
        setExpensesYear(year);
      }

      // Needed for ama
      searchParams.delete('year');

      history.replace({
        search: searchParams.toString()
      });
    }
  }, [search, setExpensesYear, setCategoryFilterSelections]);

  useEffect(() => {
    if (!isInitialExpensesLoad && !loading) {
      const timeout = setTimeout(() => {
        if (!hasActiveSubscription) {
          setExpenseReviewModalType('paywall');
        }
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [hasActiveSubscription, isInitialExpensesLoad, setExpenseReviewModalType, loading]);

  useEffect(() => {
    initExpenseReview();

    // expensesYear must be included because only the selected year's expenses are fetched at any given time
  }, [initExpenseReview, expensesYear]);

  useEffect(() => {
    (async () => {
      await getExpenseWaiting();
    })();
  }, [getExpenseWaiting]);

  useGetCampaignQuery();

  return (
    <div className={classNames('expense-review-expenses', className)}>
      <BankUnlinkedButton />
      <ExpenseReviewMenuBar className='expense-review-expenses-menu-bar' />
      <ExpenseReviewExpensesSavingsContainer className='expense-review-expenses-savings-container' />
      <ExpenseReviewExpensesTable className='expense-review-expenses-table' />
      {isPremiumSubscriber ? (
        <TaxFlowPremiumSelfUnlockModal />
      ) : (
        <TaxFlowConfirmModal
          open={selfUnlockModalOpen}
          setOpen={setSelfUnlockModalOpen}
          header='Edit your return and re-submit?'
          body="You'll need to re-submit for review after editing your return."
          // non breaking hyphen
          confirmText='Yes, edit and re&#8209;submit'
          onConfirm={async () => {
            trackActivity('self unlock: modal confirmed');

            await moveToNotStarted();
            await advanceToCurrentSectionPage({ history });
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  orderedExpenses: orderedExpensesSelector(state),
  isInitialExpensesLoad: isInitialExpensesLoadSelector(state),
  loading: loadingSelector(state),
  hasActiveSubscription: hasActiveSubscriptionSelector(state),
  expensesYear: expensesYearSelector(state),
  hasFiltersSelected: hasFiltersSelectedSelector(state),
  selfUnlockModalOpen: selfUnlockModalOpenSelector(state)
});

const mapDispatchToProps = {
  getInitialExpenses,
  getExpenseWaiting,
  getRules,
  setExpenseReviewModalType,
  setSelfUnlockModalOpen,
  advanceToCurrentSectionPage,
  setExpensesYear,
  initExpenseReview,
  getBankSummaryV2,
  getSubscriptionInfo,
  getSubscriptionTypes,
  setCategoryFilterSelections
};

const ConnectedExpenseReviewExpenses = connect(mapStateToProps, mapDispatchToProps)(ExpenseReviewExpenses);

export default ConnectedExpenseReviewExpenses;
