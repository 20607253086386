import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Skeleton from '@mui/material/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PATHNAME_ONBOARDING__APP_DOWNLOAD } from '@app/src/constants/onboardingConstants';
import { contentSelector, initialLoadSelector, loadingSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { getStringSubstitutions } from '@app/src/utils/holisticOnboardingUtils';

const QuestionHeader = () => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const content = useSelector(contentSelector);
  const initialLoad = useSelector(initialLoadSelector);
  const loading = useSelector(loadingSelector);

  const [formattedTitle, setFormattedTitle] = useState('');
  const [formattedSubtitle, setFormattedSubtitle] = useState('');

  const title = _.get(content, `${pathname}.title`, '');
  const subtitle = _.get(content, `${pathname}.subtitle`, '');

  const overrideStyles = pathname === PATHNAME_ONBOARDING__APP_DOWNLOAD ? { textAlign: 'center' } : {};

  useEffect(() => {
    if (!loading) {
      const formattedTitle = dispatch(getStringSubstitutions(title));
      const formattedSubtitle = dispatch(getStringSubstitutions(subtitle));

      setFormattedTitle(formattedTitle);
      setFormattedSubtitle(formattedSubtitle);
    }
  }, [title, subtitle, loading, dispatch]);

  return (
    <div className='h-onboarding-content-sticky'>
      <div className='h-onboarding-content-question-title'>
        {initialLoad ? (
          <Skeleton variant='text' width={300} height={40} animation='pulse' />
        ) : (
          <div style={overrideStyles}>{formattedTitle}</div>
        )}
      </div>
      <div className='h-onboarding-content-question-subtitle'>
        {initialLoad ? (
          <Skeleton variant='text' width={150} height={20} animation='pulse' />
        ) : (
          <>{subtitle && <div dangerouslySetInnerHTML={{ __html: formattedSubtitle }} style={overrideStyles} />}</>
        )}
      </div>
    </div>
  );
};

export default QuestionHeader;
