import { useMemo } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useGetWriteoffsQuery } from '@app/src/api/expensesApi';
import { useGetWorkInfoQuery } from '@app/src/api/profileApi';
import { expensesYearSelector, totalBusinessExpensesSelector } from '@app/src/selectors/expenseReviewSelectors';

export const useTaxEstimateInput = (year = 2023) => {
  const { data: workinfo, isSuccess: workinfoQuerySucceeded } = useGetWorkInfoQuery();

  const expenseYear = useSelector(expensesYearSelector);
  const totalBusinessExpenses = useSelector(totalBusinessExpensesSelector);

  const { data: expenses, isSuccess: expensesQuerySucceeded } = useGetWriteoffsQuery(year, {
    skip: expenseYear === String(year),
    refetchOnMountOrArgChange: true
  });

  const skip = !workinfoQuerySucceeded || (expenseYear !== String(year) && !expensesQuerySucceeded);

  const taxCalculatorInput = useMemo(() => {
    if (skip) {
      return;
    }

    return {
      stateAbbreviation: workinfo.state_residence,
      filingStatus: workinfo.filing_status,
      numDependents: workinfo.tax_calc_credits?.numDependents || 0,
      income: {
        taxpayer: {
          w2: workinfo.w2_income_amount ?? 0,
          1099: workinfo.agi ?? 0,
          federalTaxesWithheld: workinfo.w2_federal_income_taxes_withheld,
          stateTaxesWithheld: workinfo.w2_state_income_taxes_withheld,
          totalBusinessExpenses:
            expenseYear === String(year) ? totalBusinessExpenses : _.sumBy(expenses, 'deductionAmount')
        },
        spouse: {
          w2: workinfo.spouse_w2_income ?? 0,
          1099: workinfo.spouse_agi ?? 0,
          federalTaxesWithheld: workinfo.spouse_w2_federal_income_taxes_withheld,
          stateTaxesWithheld: workinfo.spouse_w2_state_income_taxes_withheld
        }
      },
      studentTuitionPaid: workinfo.tax_calc_credits?.tuitionFeesAmt ?? 0,
      mortgageInterestPaid: workinfo.tax_calc_credits?.mortgageIntAmt ?? 0,
      traditionalIraContributions: workinfo.tax_calc_credits?.iraContrAmt ?? 0,
      quarterlyTaxPayments: workinfo.quarterly_tax_payments ?? 0,
      taxYear: 2023
    };
  }, [skip, workinfo, expenseYear, year, totalBusinessExpenses, expenses]);

  return [taxCalculatorInput, { skip }];
};
