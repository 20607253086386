import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import Fuse from 'fuse.js';
import { Briefcase } from 'iconsax-react';
import { connect } from 'react-redux';
import SearchList from '@app/src/Components/Common/SearchList/SearchList';
import { jobCategoriesSelector, topJobCategoriesSelector } from '@app/src/selectors/onboardingSelectors';
import { trackActivity } from '@app/src/services/analyticsService';

export const JobSelect = ({
  allJobs,
  topJobCategories,
  selectedJobs,
  onAdd,
  onRemove,
  placeholderText,
  origin,
  style = { margin: 'auto' },
  emptyUntilFocused = true,
  listProps
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [listItems, setListItems] = useState([]);
  const [inputFocused, setInputFocused] = useState(!emptyUntilFocused);

  const formatJobItem = useCallback(
    (job) => {
      const slug = _.get(job, 'slug', '');
      const name = _.get(job, 'name', slug);
      const iconUrl = _.get(job, 'icon_url', '');

      return {
        id: slug,
        slug,
        name,
        iconUrl,
        showInList: true,
        checked: _.some(selectedJobs, { slug }),
        pillOrder: _.findIndex(selectedJobs, { slug })
      };
    },
    [selectedJobs]
  );

  const listHeaderText = !searchQuery.trim().length
    ? inputFocused
      ? 'Frequently selected jobs:'
      : `e.g. “healthcare professional”, or “consultant”`
    : null;

  const debouncedAnalytics = useRef(
    _.debounce((val) => {
      trackActivity('search jobs', {
        search_text: val.toLowerCase(),
        origin
      });
    }, 300)
  ).current;

  const selectedItems = selectedJobs.map(formatJobItem);

  const handleSearch = (search) => {
    setSearchQuery(search);

    if (search) {
      debouncedAnalytics(search);
    }
  };

  const fuse = useMemo(
    () =>
      new Fuse(
        allJobs.filter((job) => job.name !== 'Other'),
        {
          keys: ['name', 'synonymsForThisJobType'],
          threshold: 0.5
        }
      ),
    [allJobs]
  );

  useEffect(() => {
    if (!searchQuery.trim().length) {
      setListItems(inputFocused ? topJobCategories.map(formatJobItem) : []);
      return;
    }

    const searchMatches = fuse.search(searchQuery);

    if (searchMatches.length > 0) {
      setListItems(searchMatches.map(({ item }) => formatJobItem(item)));
    } else {
      const otherJob = _.find(allJobs, { slug: 'other' });
      setListItems([formatJobItem(otherJob)]);
    }
  }, [allJobs, formatJobItem, searchQuery, topJobCategories, inputFocused, fuse]);

  return (
    <SearchList
      items={listItems}
      listHeaderText={listHeaderText}
      onAddItem={onAdd}
      onFocus={() => setInputFocused(true)}
      selectedItems={selectedItems}
      onRemoveItem={onRemove}
      onSearch={handleSearch}
      query={searchQuery}
      searchPlaceholderText={placeholderText}
      style={style}
      fallbackIcon={<Briefcase />}
      chipStyle='square'
      {...listProps}
    />
  );
};

const mapStateToProps = (state) => ({
  allJobs: jobCategoriesSelector(state),
  topJobCategories: topJobCategoriesSelector(state)
});

const mapDispatchToProps = {};

const ConnectedJobSelect = connect(mapStateToProps, mapDispatchToProps)(JobSelect);

export default ConnectedJobSelect;
