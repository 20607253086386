import React from 'react';
import Button from '@mui/material/Button';
import { useSnackbarRef } from '@app/src/Components/Snackbar/Snackbar';
import {
  SIGNUP_EMAIL_EXISTS_SNACKBAR_MESSAGE,
  SIGNUP_PHONE_EXISTS_SNACKBAR_MESSAGE
} from '@app/src/constants/onboardingConstants';
import { trackActivity } from '@app/src/services/analyticsService';
import { colorAccent } from '@app/src/theme';

const RetryButton = () => {
  const onRetry = () => {
    trackActivity('snackbar: click', { type: 'retry' });

    window.location.reload();
  };

  return (
    <Button sx={{ color: colorAccent }} onClick={onRetry}>
      Reload
    </Button>
  );
};

const LoginButton = () => {
  const onLogin = () => {
    trackActivity('snackbar: click', { type: 'login' });

    window.location.href = '/login';
  };

  return (
    <Button sx={{ color: colorAccent }} onClick={onLogin}>
      Login
    </Button>
  );
};

const DismissButton = () => {
  const onClose = () => {
    trackActivity('snackbar: click', { type: 'dismiss' });

    useSnackbarRef.closeSnackbar();
  };

  return (
    <Button sx={{ color: colorAccent }} onClick={onClose}>
      Dismiss
    </Button>
  );
};

const UndoButton = ({ onUndo }) => {
  const handleUndo = () => {
    trackActivity('snackbar: click', { type: 'undo' });

    useSnackbarRef.closeSnackbar();
    onUndo();
  };

  return (
    <Button sx={{ color: colorAccent }} onClick={handleUndo}>
      Undo
    </Button>
  );
};

export const SnackbarActions = ({ message, data }) => {
  if (data?.onUndo) {
    return <UndoButton onUndo={data?.onUndo} />;
  }

  switch (message) {
    case 'Unauthorized Request':
      return <LoginButton />;
    case SIGNUP_PHONE_EXISTS_SNACKBAR_MESSAGE:
      return <LoginButton />;
    case SIGNUP_EMAIL_EXISTS_SNACKBAR_MESSAGE:
      return <LoginButton />;
    case 'Network Error':
      return <RetryButton />;
    default:
      return <DismissButton />;
  }
};
