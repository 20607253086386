import React from 'react';
import _ from 'lodash';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ControlledYearlyMoneyInput from '@app/src/Components/TaxProfile/ControlledYearlyMoneyInput';
import { useTaxProfileFormContext } from '@app/src/Components/TaxProfile/TaxProfileFormContext';
import TaxProfileSelect from '@app/src/Components/TaxProfile/TaxProfileSelect';
import TaxProfileSlider from '@app/src/Components/TaxProfile/TaxProfileSlider';
import { useJobType } from '@app/src/hooks/useJobType';
import { jobCategoriesSelector } from '@app/src/selectors/onboardingSelectors';
import { STATES } from '@app/src/taxflow/shared/constants/sharedConstants';

const OnboardingTaxProfileInputs = ({ workInfo }) => {
  const { handleSubmit, watch, setValue } = useFormContext();
  const { onSubmit } = useTaxProfileFormContext();

  const allJobs = useSelector(jobCategoriesSelector);
  const jobs = workInfo?.jobs || [];
  const incomeTypes = workInfo?.income_type || [];

  const { jobType, incomeType } = useJobType(jobs, incomeTypes, allJobs);

  const car = watch('car');
  const home = watch('home');
  const filingStatus = watch('filing_status');

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={4}>
        <Controller
          name='filing_status'
          defaultValue=''
          rules={{
            required: 'Filing status is required'
          }}
          render={({ field, fieldState: { error } }) => (
            <TaxProfileSelect
              {...field}
              id='filingStatus'
              error={error}
              label='Filing status'
              placeholder='Select a status'
              onChange={(event) => {
                field.onChange(event);

                if (event.target.value === 'single') {
                  setValue('tax_calc_credits.numDependents', null);
                }

                handleSubmit(onSubmit(field.name))();
              }}
              options={[
                {
                  value: 'single',
                  label: 'Single'
                },
                {
                  value: 'married',
                  label: 'Married filing jointly'
                },
                {
                  value: 'married_separate',
                  label: 'Married filing separately'
                },
                {
                  value: 'head',
                  label: 'Head of household'
                }
              ]}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Controller
          name='tax_calc_credits.numDependents'
          defaultValue={''}
          rules={{
            min: {
              value: 0,
              message: 'Number of dependents cannot be negative'
            },
            max: {
              value: 10,
              message: 'Number of dependents cannot exceed 10'
            }
          }}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth error={!!error}>
              <InputLabel htmlFor='numDependents' id='numDependentsLabel' shrink='false'>
                Dependents
              </InputLabel>
              <TextField
                {...field}
                value={_.isNil(field.value) ? '' : field.value}
                error={!!error}
                helperText={error?.message}
                onChange={(e) => {
                  const value = parseInt(e.target.value);

                  if (isNaN(value)) {
                    field.onChange(null);
                    return;
                  }

                  field.onChange(value);
                }}
                onBlur={handleSubmit(onSubmit(field.name))}
                id='numDependents'
                type='number'
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: 10
                  },
                  fullWidth: true,
                  style: { width: '100%' }
                }}
                placeholder='e.g. 1'
              />
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name='state_residence'
          defaultValue=''
          render={({ field, fieldState: { error } }) => (
            <TaxProfileSelect
              {...field}
              id='state'
              label='State'
              error={error}
              placeholder='Select a state'
              value={_.isNil(field.value) ? '' : field.value}
              onChange={(event) => {
                field.onChange(event);

                handleSubmit(onSubmit(field.name))();
              }}
              options={STATES.map(({ code, name }) => ({
                value: code,
                label: name
              }))}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ControlledYearlyMoneyInput name='w2_income_amount' label='W-2 employee income' defaultValue={0} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ControlledYearlyMoneyInput name='agi' label={`${_.capitalize(jobType)} income`} defaultValue={0} />
      </Grid>
      {filingStatus === 'married' && (
        <>
          <Grid item xs={12} md={6}>
            <ControlledYearlyMoneyInput name='spouse_w2_income' label='Spouse W-2 employee income' defaultValue={0} />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledYearlyMoneyInput name='spouse_agi' label={`Spouse ${incomeType} income`} defaultValue={0} />
          </Grid>
        </>
      )}
      <Grid item xs={12} md={6}>
        <Controller
          name='percent_car'
          rules={{
            min: 0,
            max: 100
          }}
          render={({ field: { value, onChange, name } }) => (
            <TaxProfileSlider
              style={{ width: '95%' }}
              label={`Percent of driving for ${jobType}`}
              id='percent_car'
              value={car ? value : 0}
              onChange={(value) => {
                const parsedValue = parseInt(value);

                setValue('car', parsedValue > 0 ? 1 : 0);

                onChange(parsedValue);
              }}
              onBlur={handleSubmit(onSubmit(name))}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name='percent_home'
          rules={{
            min: 0,
            max: 100
          }}
          render={({ field: { value, onChange, name } }) => {
            return (
              <TaxProfileSlider
                style={{ width: '95%' }}
                label='Percent of home used for home office'
                id='percent_home'
                value={home ? value : 0}
                onChange={(value) => {
                  const parsedValue = parseInt(value);

                  setValue('home', parsedValue > 0 ? 1 : 0);

                  onChange(parsedValue);
                }}
                onBlur={handleSubmit(onSubmit(name))}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name='percent_phone'
          rules={{
            min: 0,
            max: 100
          }}
          render={({ field: { value, onChange, name } }) => (
            <TaxProfileSlider
              style={{ width: '95%' }}
              label={`Percent of phone usage for ${jobType}`}
              id='percent_phone'
              value={value}
              onChange={(value) => onChange(parseInt(value))}
              onBlur={handleSubmit(onSubmit(name))}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default OnboardingTaxProfileInputs;
