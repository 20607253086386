/**
 * @desc Environment Variables
 */
/** @type {'production' | 'development' | 'staging-prod-db' | 'staging'} */
export const REACT_APP_ENV = process.env.REACT_APP_ENV || 'development';
export const REACT_APP_VERSION = process.env.REACT_APP_VERSION || 'replaceme';
export const SERVER_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:5001/';
export const APP_URL = process.env.REACT_APP_APP_URL || 'http://localhost:3000';
export const STRIP_PUBLIC_KEY = process.env.REACT_APP_STRIP_PUBLIC_KEY || 'pk_test_8UZKU29tH0Gk7Z0sQQapNHJj';
export const STREAM_KEY = process.env.REACT_APP_STREAM_KEY;

// contentful variables
export const CONTENTFUL_SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID || 'xauw5r0xpp02';
export const CONTENTFUL_ENVIRONMENT = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT || 'sandbox';
export const CONTENTFUL_ACCESS_TOKEN =
  process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN || 'vWZlG1WKzfBfKPh_sZfdaJck3FrNuGGSRzvxy2btBVg';
// leaving this undefined is fine and will default to regular cdn
export const CONTENTFUL_HOST = process.env.REACT_APP_CONTENTFUL_HOST;
export const CONTENTFUL_CONTENT_TYPES = {
  ABOUT_YOU_ITEMS: 'aboutYouItems',
  BUSINESS_CODES: 'businessCodes',
  JOBS: 'jobsNew',
  MARKETING_CONTENT: 'marketingContent',
  TAX_FLOW_DROPDOWN_WARNINGS: 'taxFlowDropdownWarnings',
  TAX_FLOW_INFO: 'taxFlowInfo',
  TAX_FLOW_NAVIGATION: 'taxFlowNavigation',
  TAX_FLOW_QUESTION: 'taxFlowQuestion',
  WORK_CATEGORIES: 'workCategories'
};

// capital one
export const CAPITAL_ONE_HOST = process.env.REACT_APP_CAPITAL_ONE_HOST || 'apiit.capitalone.com';
export const CAPITAL_ONE_CLIENT_ID = process.env.REACT_APP_CAPITAL_ONE_CLIENT_ID || '90688682556441fe860ef04aa369c551';

// amplitude
export const AMPLITUDE_API_KEY = process.env.AMPLITUDE_API_KEY || '212ed2feb2663c8004ae16498974992b';

export const TUNE_GOALS_MAP = {
  link: 4,
  signup: 2
};

/**
 * @desc Get server url from env var
 */
export function serverUrl() {
  return SERVER_URL;
}

/**
 * @desc Get app url from env var
 */
export function appUrl() {
  return APP_URL;
}
