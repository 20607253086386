import { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { useFilePicker } from 'use-file-picker';
import { getAttachmentType } from '@app/src/global/Helpers';
import { trackActivity } from '@app/src/services/analyticsService';
import { expenseUpload } from '@app/src/services/expenseUploadService';

const useExpenseUploadFiles = ({ origin, setExpenseReviewModalType }) => {
  const [openFileSelector, { plainFiles, filesContent }] = useFilePicker({
    accept: ['.csv', '.xlsx', '.pdf'],
    multiple: false,
    readAs: 'DataURL',
    maxFileSize: 10
  });

  const handleUploadClick = useCallback(() => {
    trackActivity('expense upload: clicked', { origin });
    openFileSelector();
  }, [openFileSelector, origin]);

  const uploadSelectedFile = useCallback(() => {
    if (plainFiles.length > 0) {
      const fileContent = _.get(filesContent, '0.content', '');
      const fileType = plainFiles[0]?.type;
      const file = {
        data: fileContent?.split(',')[1],
        filename: plainFiles[0]?.name,
        type: getAttachmentType(fileType)
      };

      expenseUpload({ file });

      setTimeout(() => {
        setExpenseReviewModalType('');
      }, 0);
    }
  }, [plainFiles, filesContent, setExpenseReviewModalType]);

  useEffect(() => {
    if (plainFiles.length > 0) {
      uploadSelectedFile();
    }
  }, [plainFiles, uploadSelectedFile]);

  return { handleUploadClick };
};

export default useExpenseUploadFiles;
