import { baseApi, TAGS } from '@app/src/api/baseApi';

const pricingApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSubscriptionInfo: builder.query({
      query: () => ({
        url: 'pricing/subscription-info',
        method: 'GET'
      }),
      providesTags: [TAGS.SUBSCRIPTION_INFO],
      transformResponse: (response) => response.data
    })
  })
});

export const { useGetSubscriptionInfoQuery } = pricingApi;
