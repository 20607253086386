import url from 'url';
import _ from 'lodash';
import {
  TAXFLOW__FORM_UPLOAD_FAILED_BODY,
  TAXFLOW__FORM_UPLOAD_OCR_FAILED_TEXT,
  TAXFLOW__FORM_UPLOAD_INCORRECT_FORM_TEXT,
  TAXFLOW__FORM_UPLOAD_IMAGE_QUALITY_TEXT,
  TAXFLOW__FORM_UPLOAD_WRONG_YEAR_TEXT,
  TAXFLOW__FORM_UPLOAD_DRAFT_TEXT,
  TAXFLOW__FORM_UPLOAD_PASSWORD_TEXT
} from '@app/src/taxflow/collection/constants/formUploadConstants';
import {
  SLUG__CREDIT_HOMEOWNER_FORM_UPLOAD,
  PATH_COMPONENT__CREDIT_HOMEOWNER_DETAIL,
  SLUG__CREDIT_HOMEOWNER_PREFILL_LOADING,
  PATH_COMPONENT__CREDIT_HOMEOWNER_PREFILL_LOADING,
  PATH_COMPONENT__CREDIT_HEALTH_FORM_UPLOAD,
  PATH_COMPONENT__CREDIT_HOMEOWNER_FORM_UPLOAD,
  PATH_COMPONENT__CREDIT_STUDENT_TUITION_FORM_UPLOAD,
  PATH_COMPONENT__CREDIT_STUDENT_TUITION_PREFILL_LOADING,
  SLUG__CREDIT_STUDENT_TUITION_PREFILL_LOADING,
  PATH_COMPONENT__CREDIT_STUDENT_TUITION_DETAIL,
  SLUG__CREDIT_STUDENT_TUITION_FORM_UPLOAD
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import { TAXFLOW_BASE_URL, TAX_FILING_YEAR } from '@app/src/taxflow/shared/constants/sharedConstants';

const FORM_UPLOAD_FILE_NAME_MAX_LENGTH = 300;

export const getFormUploadNextPathComponentMap = () => {
  return {
    [SLUG__CREDIT_HOMEOWNER_FORM_UPLOAD]: PATH_COMPONENT__CREDIT_HOMEOWNER_DETAIL,
    [SLUG__CREDIT_STUDENT_TUITION_FORM_UPLOAD]: PATH_COMPONENT__CREDIT_STUDENT_TUITION_DETAIL
  };
};

export const getFormUploadErrorMessage = ({ errorMessage, isBulk }) => {
  if (errorMessage === 'OCR failed') {
    return TAXFLOW__FORM_UPLOAD_OCR_FAILED_TEXT + TAXFLOW__FORM_UPLOAD_FAILED_BODY;
  } else if (errorMessage === 'Incorrect form provided') {
    return `${!isBulk ? TAXFLOW__FORM_UPLOAD_INCORRECT_FORM_TEXT : ''} ${TAXFLOW__FORM_UPLOAD_FAILED_BODY}`;
  } else if (errorMessage === 'Image quality is too poor') {
    return TAXFLOW__FORM_UPLOAD_IMAGE_QUALITY_TEXT + TAXFLOW__FORM_UPLOAD_FAILED_BODY;
  } else if (errorMessage === `Form year is not ${TAX_FILING_YEAR}`) {
    return TAXFLOW__FORM_UPLOAD_WRONG_YEAR_TEXT + TAXFLOW__FORM_UPLOAD_FAILED_BODY;
  } else if (errorMessage === 'Form is a draft') {
    return TAXFLOW__FORM_UPLOAD_DRAFT_TEXT + TAXFLOW__FORM_UPLOAD_FAILED_BODY;
  } else if (errorMessage && errorMessage.includes('Incorrect password')) {
    return TAXFLOW__FORM_UPLOAD_PASSWORD_TEXT;
  } else if (errorMessage === 'Form not supported by Keeper') {
    return '';
  }
  return TAXFLOW__FORM_UPLOAD_FAILED_BODY;
};

export const getPrefillNextPathComponentMap = () => {
  return {
    [SLUG__CREDIT_HOMEOWNER_PREFILL_LOADING]: PATH_COMPONENT__CREDIT_HOMEOWNER_DETAIL,
    [SLUG__CREDIT_STUDENT_TUITION_PREFILL_LOADING]: PATH_COMPONENT__CREDIT_STUDENT_TUITION_DETAIL
  };
};

export const getPrefillPathComponentMap = () => {
  return {
    [SLUG__CREDIT_HOMEOWNER_FORM_UPLOAD]: PATH_COMPONENT__CREDIT_HOMEOWNER_PREFILL_LOADING,
    [SLUG__CREDIT_STUDENT_TUITION_FORM_UPLOAD]: PATH_COMPONENT__CREDIT_STUDENT_TUITION_PREFILL_LOADING
  };
};

export const getPrefillPrevPathComponentMap = () => {
  return {
    [PATH_COMPONENT__CREDIT_HOMEOWNER_PREFILL_LOADING]: SLUG__CREDIT_HOMEOWNER_FORM_UPLOAD,
    [PATH_COMPONENT__CREDIT_STUDENT_TUITION_PREFILL_LOADING]: SLUG__CREDIT_STUDENT_TUITION_FORM_UPLOAD
  };
};

export const getBulkUploadFormUrl = ({ nextPathComponent, collectionId }) => {
  return url.format({
    pathname: `/${TAXFLOW_BASE_URL}/${nextPathComponent}`,
    query: {
      collectionId,
      origin: 'home'
    }
  });
};

export const getFormUploadEnabledMap = () => {
  return {
    [PATH_COMPONENT__CREDIT_HEALTH_FORM_UPLOAD]: false,
    [PATH_COMPONENT__CREDIT_HOMEOWNER_FORM_UPLOAD]: false,
    [PATH_COMPONENT__CREDIT_STUDENT_TUITION_FORM_UPLOAD]: true
  };
};

export const getUploadAttemptFileNames = ({ attempt }) => {
  const joinedFiles = _(attempt.files)
    .sortBy((file) => file.page)
    .map((file) => file.file_name)
    .join(', ');

  if (joinedFiles.length > FORM_UPLOAD_FILE_NAME_MAX_LENGTH) {
    return `${joinedFiles.slice(0, FORM_UPLOAD_FILE_NAME_MAX_LENGTH)}...`;
  }
  return joinedFiles;
};
