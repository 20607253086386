import axios from 'axios';
import { serverUrl } from '@app/src/global/Environment';
import { trackActivity } from '@app/src/services/analyticsService';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import { notify } from '@app/src/utils/snackbarUtils';

const baseUrl = serverUrl();

export const expenseUpload = async ({ file }) => {
  try {
    trackActivity('expense upload: create upload attempt', {
      file_name: file.filename,
      file_format: file.type
    });

    if (file.type === 'file/image') {
      throw new Error('The file type is not supported. Please upload a different file.');
    }

    notify(`${file.filename} upload in process. Expect an update within 48 hours.`, { origin: 'expense upload' });

    const response = await axios.post(`${baseUrl}api/expense/expense-upload`, file);

    return response.data;
  } catch (e) {
    notify(e?.message || 'An error occurred while uploading the file');
    defaultCaptureException(e);
  }
};
