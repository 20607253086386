import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { CloseCircle } from 'iconsax-react';
import { colorFontDark } from '@app/src/theme';
import '@app/src/Components/Assistant/Assistant.scss';

const AssistantFilePreview = ({ file, type, messageSending, onClose }) => {
  const url = URL.createObjectURL(file);

  if (!type) return null;

  return (
    <div className='assistant-file-preview'>
      {messageSending ? (
        <CircularProgress size={16} color={'secondary'} className='assistant-file-preview-sending' />
      ) : (
        <CloseCircle
          size={24}
          className='assistant-file-preview-close'
          variant='Bold'
          color={colorFontDark}
          onClick={onClose}
        />
      )}
      {type === 'file/pdf' && (
        <div className='assistant-file-preview-pdf'>
          <div className='assistant-file-preview-pdf-name'>{file.name}</div>
          <div className='assistant-file-preview-pdf-description'>PDF</div>
        </div>
      )}
      {type === 'file/image' && <img src={url} alt={file.name} className='assistant-file-preview-image' />}
    </div>
  );
};

export default AssistantFilePreview;
