import React from 'react';
import Slide from '@mui/material/Slide';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PATHS_WITHOUT_TRANSITION } from '@app/src/constants/onboardingConstants';
import { initialLoadSelector } from '@app/src/selectors/holisticOnboardingSelectors';

const PageTransition = ({ children }) => {
  const { action, location } = useHistory();
  const { pathname } = location;

  const initialLoad = useSelector(initialLoadSelector);

  return initialLoad || PATHS_WITHOUT_TRANSITION.includes(pathname) ? (
    <div>{children}</div>
  ) : (
    <Slide key={pathname} in timeout={300} direction={action !== 'POP' ? 'left' : 'right'}>
      {children}
    </Slide>
  );
};

export default PageTransition;
