import React, { useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Clock } from 'iconsax-react';
import { connect } from 'react-redux';
import { useWindowSize } from 'react-use';
import AskLink from '@app/src/Components/Common/AskLink/AskLink';
import ReferralsInfoSidebar from '@app/src/Components/Referrals/ReferralsInfoSidebar';
import TaxFlowQuestionFooter from '@app/src/Components/TaxFlow/Question/TaxFlowQuestionFooter';
import { MAX_REFERRALS_ALLOWED_COUNT, SIDEBAR_CONTENT, STATUSES } from '@app/src/constants/referralsConstants.js';
import { userSettingsSelector } from '@app/src/selectors/authSelectors';
import { referralsSelector } from '@app/src/selectors/referralsSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { getReferrals, loadReferralsContent, updatePayPalEmail } from '@app/src/services/referralsService';
import TaxFlowNavigationPanel from '@app/src/taxflow/navigation/components/TaxFlowNavigationPanel';
import { colorFontDark, colorSecondary } from '@app/src/theme';
import { validateEmail } from '@app/src/utils/commonUtils';
import getImageUrl from '@app/src/utils/getImageUrl';
import { notify } from '@app/src/utils/snackbarUtils';
import '@app/src/Components/Referrals/Referrals.scss';

function Referrals({ referrals, getReferrals, updatePayPalEmail, userSettings, loadReferralsContent }) {
  const [sidebarComponent, setSidebarComponent] = useState(null);
  const [payPalEmail, setPayPalEmail] = useState(
    userSettings?.paypal_email ? userSettings?.paypal_email : userSettings?.email
  );

  const [hasEmailError, setHasEmailError] = useState(false);

  const emailIsInvalid = !validateEmail(payPalEmail);

  const { width: windowWidth } = useWindowSize();
  const isDesktop = windowWidth >= 1024;

  useEffect(() => {
    (async () => {
      await getReferrals();
      await loadReferralsContent();
    })();
  }, [getReferrals, loadReferralsContent]);

  const referralsRemaining = useMemo(() => {
    const difference = MAX_REFERRALS_ALLOWED_COUNT - (referrals?.length || 0);
    return Math.max(difference, 0);
  }, [referrals?.length]);

  const handleEmailBlur = () => {
    if (emailIsInvalid) {
      setHasEmailError(true);
      return;
    }

    setHasEmailError(false);
    updatePayPalEmail({ payPalEmail });
  };

  const handleShareLink = () => {
    const link = `https://www.keepertax.com/invite?referrer=${userSettings?.firstname + userSettings?.id}`;

    trackActivity('referral: share link clicked', {
      origin: 'referralTabWeb'
    });

    // eslint-disable-next-line no-undef
    navigator.clipboard
      .writeText(link)
      .then(() => {
        notify('Link copied to clipboard!', 'success');
      })
      .catch(() => {
        notify('Failed to copy link to clipboard', 'error');
      });
  };

  trackActivity('navigation: referral', {
    section: 'referrals',
    source: 'user_action'
  });

  return (
    <div className='referrals-container'>
      <TaxFlowNavigationPanel />
      <div className='referrals-content'>
        <img className='referrals-image' src={getImageUrl('referrals.svg')} alt='Refer your friends.' />
        <div>
          <h1 className='referrals-title'>Give 25% off Keeper, get $50</h1>
          <p className='referrals-subtitle'>
            Get $50 per friend who subscribes to a Keeper annual plan. They get 25% off their first year.
          </p>
        </div>
        <AskLink
          onClick={() => {
            trackActivity('navigation: referrals how it works');
            setSidebarComponent(SIDEBAR_CONTENT.HOW_IT_WORKS);
          }}
          styles={{ marginTop: 0 }}
        >
          How it works
        </AskLink>
        <div className='referrals-card'>
          <h2 className='referrals-card-header'>Your referrals ({referralsRemaining}/10 remaining)</h2>
          <div className='referrals-divider' />
          {!referrals?.length ? (
            <p>Earn up to $500 from referrals! Referrals who have started an annual plan will show up here.</p>
          ) : (
            <>
              {referrals?.map(({ id, name, status }) => (
                <div key={id} className='referrals-referral'>
                  <p>{name}</p>
                  {status === STATUSES.EARNED ? (
                    <img src={getImageUrl('icon-green-check.svg')} alt='Green check icon' width={20} height={20} />
                  ) : (
                    <Clock color={colorFontDark} size={20} />
                  )}
                </div>
              ))}
            </>
          )}
        </div>
        {referrals?.length > 0 && (
          <div className='referrals-paypal-email-container'>
            <label htmlFor='paypalEmail'>PayPal account (for reward payments)</label>
            <TextField
              id='paypalEmail'
              variant='outlined'
              margin='none'
              value={payPalEmail}
              onChange={(e) => setPayPalEmail(e.target.value)}
              error={!!hasEmailError}
              onBlur={handleEmailBlur}
              fullWidth
              inputProps={{
                'data-testid': 'paypalInput'
              }}
            />
          </div>
        )}
        <Button
          fullWidth
          variant='contained'
          color='secondary'
          style={{ borderColor: colorSecondary }}
          onClick={handleShareLink}
        >
          <span>Share my unique link</span>
        </Button>
      </div>
      <ReferralsInfoSidebar component={sidebarComponent} setSidebarComponent={setSidebarComponent} />
      {!isDesktop && <TaxFlowQuestionFooter />}
    </div>
  );
}

const mapStateToProps = (state) => ({
  userSettings: userSettingsSelector(state),
  referrals: referralsSelector(state)
});

const mapDispatchToProps = {
  getReferrals,
  updatePayPalEmail,
  loadReferralsContent
};

const ConnectedReferrals = connect(mapStateToProps, mapDispatchToProps)(Referrals);

export default ConnectedReferrals;
