import React, { useEffect, useCallback, useState } from 'react';
import _ from 'lodash';
import IconButton from '@mui/material/IconButton';
import { ArrowLeft2 } from 'iconsax-react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useGetCampaignQuery } from '@app/src/api/profileApi';
import {
  useGetCollectionTypeItemsQuery,
  useGetCurrentQuestionnaireQuestionQuery,
  useGetTaxDataQuery
} from '@app/src/api/taxDataApi';
import { getIsTaxFilingSandwiched } from '@app/src/global/Helpers';
import { isQuestionnaireFlowSelector } from '@app/src/selectors/taxFlowSelectors';
import { allQuestionsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { getSlugByLocation } from '@app/src/taxflow/main/utils/mainUtils';
import {
  PATH_COMPONENT__HOME,
  SLUG__BULK_UPLOAD_MANUAL_ENTRY,
  SLUG__BULK_UPLOAD_MULTI_IMAGE,
  SLUG__BULK_UPLOAD_PHOTO_CAPTURE,
  SLUG__CREDIT_ADD_MORE,
  SLUG__INCOME_ADD_MORE
} from '@app/src/taxflow/sections/special/constants/specialConstants';
import {
  SLUG__SUBMIT_CONFIRM_ID_INTRO,
  SLUG__SUBMIT_CONFIRM_ID
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import {
  TAXFLOW_BASE_URL,
  STATE_TAX_COLL_TYPE_MAP,
  STATE_NAME_MAP,
  UI_STAGE__CONFIRM_AMOUNTS
} from '@app/src/taxflow/shared/constants/sharedConstants';
import { currentCollectionIdSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';
import '@app/src/Components/TaxFlow/Question/TaxFlowQuestionHeader.scss';

const SLUG_TO_HEADER_TEXT = {
  [SLUG__BULK_UPLOAD_MANUAL_ENTRY]: 'Add tax form',
  [SLUG__BULK_UPLOAD_MULTI_IMAGE]: 'Add tax form',
  [SLUG__BULK_UPLOAD_PHOTO_CAPTURE]: 'Add tax form',
  [SLUG__INCOME_ADD_MORE]: 'Add income',
  [SLUG__CREDIT_ADD_MORE]: 'Add credits',
  [SLUG__SUBMIT_CONFIRM_ID_INTRO]: 'Verification',
  [SLUG__SUBMIT_CONFIRM_ID]: 'Verification'
};

const useHeaders = () => {
  const location = useLocation();
  const isQuestionnaireFlow = useSelector(isQuestionnaireFlowSelector);
  const allQuestions = useSelector(allQuestionsSelector);
  const currentCollectionId = useSelector(currentCollectionIdSelector);

  const { data: currentQuestionnaireQuestion, isFetching: currentQuestionnaireQuestionFetching } =
    useGetCurrentQuestionnaireQuestionQuery();
  const { data: campaign, isFetching: campaignFetching } = useGetCampaignQuery();
  const { data: collectionTypeItems, isFetching: collectionTypeItemsFetching } = useGetCollectionTypeItemsQuery();

  const slug = getSlugByLocation(location);
  const collectionId = isQuestionnaireFlow ? currentQuestionnaireQuestion?.coll_id : currentCollectionId;
  const currentQuestion = _.find(allQuestions, { slug });
  const collectionType = isQuestionnaireFlow
    ? currentQuestionnaireQuestion?.coll_type
    : currentQuestion?.collectionType;

  const { data: collectionTaxData, isFetching: collectionTaxDataFetching } = useGetTaxDataQuery({ collectionType });

  const [headerText, setHeaderText] = useState();
  const [secondaryHeaderText, setSecondaryHeaderText] = useState();

  /**
   * Update the header and secondary header as a function of whether the page is part of the questionnaire flow
   */
  const setHeaders = useCallback(
    (title) => {
      if (isQuestionnaireFlow) {
        setHeaderText('Questionnaire');
        setSecondaryHeaderText(title);
      } else {
        setHeaderText(title);
        setSecondaryHeaderText(null);
      }
    },
    [isQuestionnaireFlow]
  );

  useEffect(() => {
    // Find field pillDisplayPreface for the collection type item respective of the current flow
    // This will serve as the header text in most cases
    const collectionTypeDisplayName = _.chain(collectionTypeItems)
      .filter({ collectionType })
      .find(({ subTypeDifferentiationSlug, subTypeDifferentiationValue }) => {
        const subType = _.chain(collectionTaxData)
          .find({ slug: subTypeDifferentiationSlug, coll_id: collectionId })
          .get('value')
          .value();
        return (
          _.isNil(subTypeDifferentiationSlug) ||
          subType === subTypeDifferentiationValue ||
          (_.isEmpty(subType) && _.isEmpty(subTypeDifferentiationValue))
        );
      })
      .get('pillDisplayPreface')
      .value();
    const maybeState = _.chain(STATE_TAX_COLL_TYPE_MAP)
      .toPairs()
      .find(([, collectionTypes]) => _.includes(collectionTypes, collectionType))
      .get('0')
      .value();

    // Case: in the confirm amounts sub-flow, always display "Sign & file"
    if (_.get(campaign, 'ui_stage') === UI_STAGE__CONFIRM_AMOUNTS) {
      setHeaders('Sign & file');
    }
    // Case: we have provided a hardcoded value for the header of the given question
    // This is useful for questions which don't have a respective encompassing collection type item, usually those belonging to the "special" collection type
    else if (_.has(SLUG_TO_HEADER_TEXT, slug)) {
      setHeaders(_.get(SLUG_TO_HEADER_TEXT, slug));
    }
    // Case: question is part of a states flow
    // Render the state name as the header
    else if (!_.isEmpty(maybeState)) {
      setHeaders(`State return: ${_.get(STATE_NAME_MAP, maybeState)}`);
    }
    // Case: We don't have enough info to decide on the header yet. Do nothing
    else if (
      currentQuestionnaireQuestionFetching ||
      campaignFetching ||
      collectionTypeItemsFetching ||
      collectionTaxDataFetching ||
      _.isNil(collectionType) ||
      _.isNil(collectionId)
    ) {
      return;
    }
    // Case: question is part of the questionnaire flow.
    else if (isQuestionnaireFlow) {
      setHeaders(
        currentQuestionnaireQuestion.type === 'form-review' ? 'Form review' : collectionTypeDisplayName ?? 'General'
      );
    }
    // Case: Use contentful-maintained CollectionTypeItems to derive the header name.
    // This is the case for almost all sub-flows (those not encompassed by special cases above)
    else if (!_.isNil(collectionTypeDisplayName)) {
      setHeaders(collectionTypeDisplayName);
    } else {
      setHeaders(null);
      defaultCaptureMessage('[unexpected_edge_case] could not get question header', {
        slug
      });
    }
  }, [
    campaign,
    isQuestionnaireFlow,
    collectionTypeItems,
    collectionId,
    collectionTaxData,
    collectionType,
    slug,
    currentQuestionnaireQuestion,
    currentQuestionnaireQuestionFetching,
    campaignFetching,
    collectionTypeItemsFetching,
    collectionTaxDataFetching,
    setHeaders
  ]);

  return { headerText, secondaryHeaderText };
};

export const TaxFlowQuestionHeader = () => {
  const history = useHistory();
  const isFilingSandwiched = getIsTaxFilingSandwiched();

  const { headerText, secondaryHeaderText } = useHeaders();

  if (!isFilingSandwiched) {
    return null;
  }

  const navigateHome = () => {
    history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__HOME}`);
  };

  return (
    <div className='taxflow-question-header'>
      <IconButton sx={{ position: 'absolute', left: 8 }} onClick={navigateHome}>
        <ArrowLeft2 className='taxflow-question-header-back' color='white' />
      </IconButton>
      <div className='taxflow-question-header-text'>
        <div>{headerText}</div>
        <div className='taxflow-question-header-text-secondary'>{secondaryHeaderText}</div>
      </div>
    </div>
  );
};
