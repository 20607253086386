import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import PhoneField from '@app/src/Components/Common/PhoneField/PhoneField';
import { setHolisticOnboardingPhone } from '@app/src/actions/holisticOnboardingActions';
import { errorSelector, phoneSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { onContinue } from '@app/src/services/holisticOnboardingService';

const OnboardingPhone = () => {
  const dispatch = useDispatch();

  const phone = useSelector(phoneSelector);
  const error = useSelector(errorSelector);

  const [phoneError, setPhoneError] = useState(false);

  const { pathname } = useLocation();
  const { push } = useHistory();

  const phoneInput = useRef(null);

  const invalidatePhone = () => {
    if (phone.length < 10) {
      return 'Phone number must be 10 digits long';
    } else {
      return false;
    }
  };

  const handleInvalidate = ({ key }) => {
    if (key !== 'Enter') {
      return;
    }

    const isPhoneNumberValid = invalidatePhone();
    setPhoneError(isPhoneNumberValid);

    if (isPhoneNumberValid) {
      phoneInput.current.focus();
    } else {
      dispatch(onContinue({ pathname, push }));
    }
  };

  const handleInput = ({ target: { value } }) => {
    dispatch(setHolisticOnboardingPhone(value));
  };

  return (
    <div>
      <label className='h-onboarding-content-question-label' htmlFor='phone'>
        Phone number
      </label>
      <PhoneField
        id='phone'
        mask='+1 (###) ###-####'
        ref={phoneInput}
        name='phone'
        placeholder='(123) 456-7890'
        value={phone}
        onBlur={invalidatePhone}
        onChange={handleInput}
        onKeyPress={handleInvalidate}
        type='tel'
        inputMode='tel'
        autoComplete='tel'
        error={!!phoneError || !!error}
        helperText={phoneError}
      />
    </div>
  );
};

export default OnboardingPhone;
