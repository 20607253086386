import React from 'react';
import _ from 'lodash';
import ButtonBase from '@mui/material/ButtonBase';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setHolisticOnboardingDrawerOpen } from '@app/src/actions/holisticOnboardingActions';
import { PATHNAME_ONBOARDING__LINK } from '@app/src/constants/onboardingConstants';
import { drawerOpenSelector, learnMoreSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { colorAccent } from '@app/src/theme';

const LearnMoreButton = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const { pathname } = location;

  const learnMoreInfo = useSelector((state) => learnMoreSelector(state, location));
  const drawerOpen = useSelector(drawerOpenSelector);

  const title = _.get(learnMoreInfo, 'title', '');

  const handleClick = () => {
    trackActivity('question: more info clicked', {
      flow: 'holistic onboarding',
      pathname
    });

    dispatch(setHolisticOnboardingDrawerOpen(!drawerOpen));
  };

  const copy = pathname === PATHNAME_ONBOARDING__LINK ? 'How it works' : 'Help me decide';

  return title ? (
    <ButtonBase
      onClick={handleClick}
      style={{
        fontSize: '16px',
        fontWeight: '500',
        fontFamily: 'F37Blanka',
        color: colorAccent,
        marginBottom: '16px',
        alignSelf: 'flex-start'
      }}
    >
      {copy}
    </ButtonBase>
  ) : null;
};

export default LearnMoreButton;
