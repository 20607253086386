import React, { forwardRef } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import 'linkify-plugin-mention';
import Linkify from 'linkify-react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import AssistantMessageAvatar from '@app/src/Components/Assistant/AssistantMessageAvatar';
import AssistantSystemMessage from '@app/src/Components/Assistant/AssistantSystemMessage';
import { INTERNAL_NAVIGATION_ORIGINS } from '@app/src/Components/Assistant/assistantConstants';
import { isReactNative } from '@app/src/global/Helpers';
import { trackActivity } from '@app/src/services/analyticsService';
import '@app/src/Components/Assistant/AssistantMessage.scss';

const AssistantMessage = forwardRef(
  ({ message: { sender, content, type, hideAvatar, markdown, origin }, ...props }, ref) => {
    const role = sender === 'user' ? 'user' : 'assistant';

    const renderMention = ({ content: mention }) => {
      return <span className='assistant-message-mention'>{mention}</span>;
    };

    const renderLink = ({ attributes, content }) => {
      const handleClick = () => {
        if (sender !== 'user') {
          const text = _.get(content, '0.props.children');
          const url = _.get(attributes, 'href');
          trackActivity('messaging: link navigation', {
            text,
            url,
            sender
          });
        }
      };

      if (INTERNAL_NAVIGATION_ORIGINS.includes(origin)) {
        const url = new URL(attributes.href);

        return (
          <Link
            onClick={handleClick}
            to={`${url.pathname}${url.search}${url.hash}`}
            className='assistant-message-link'
            {...attributes}
          >
            {content}
          </Link>
        );
      }

      if (isReactNative()) {
        return (
          <a
            onClick={handleClick}
            className={classNames('assistant-message-link', { 'assistant-message-long-link': content.length > 15 })}
            {...attributes}
          >
            {content}
          </a>
        );
      }

      return (
        <a
          onClick={handleClick}
          target='_blank'
          rel='noopener noreferrer'
          className={classNames('assistant-message-link', { 'assistant-message-long-link': content.length > 15 })}
          {...attributes}
        >
          {content}
        </a>
      );
    };

    if (content?.trim() === '') {
      return <div ref={ref} {...props} />;
    }

    if (type === 'system') {
      return (
        <AssistantSystemMessage ref={ref} {...props}>
          {content}
        </AssistantSystemMessage>
      );
    }

    return (
      <div className={`assistant-message-group assistant-message-group-${role}`} ref={ref} {...props}>
        {!hideAvatar && <AssistantMessageAvatar sender={sender} />}
        <div
          className={classNames('assistant-message', `assistant-${role}`, {
            'assistant-message-no-avatar': role !== 'user' && hideAvatar
          })}
        >
          {markdown ? (
            <ReactMarkdown
              renderers={{
                link: ({ href, children }) => renderLink({ attributes: { href }, content: children })
              }}
            >
              {content}
            </ReactMarkdown>
          ) : (
            <Linkify options={{ render: { mention: renderMention, url: renderLink } }}>{content}</Linkify>
          )}
        </div>
      </div>
    );
  }
);

export default AssistantMessage;
