export const PRICING2__SET_SETTINGS = 'PRICING2__SET_SETTINGS';
export const PRICING2__SET_BANK_LINKS = 'PRICING2__SET_BANK_LINKS';
export const PRICING2__SET_ACCOUNT_DETAILS = 'PRICING2__SET_ACCOUNT_DETAILS';
export const PRICING2__SET_LOADING = 'PRICING2__SET_LOADING';
export const PRICING2__SET_UPDATING = 'PRICING2__SET_UPDATING';
export const PRICING2__SET_ERROR = 'PRICING2__SET_ERROR';
export const PRICING2__SET_WALLET_TYPE = 'PRICING2__SET_WALLET_TYPE';
export const PRICING2__SET_IS_CARD_VALID = 'PRICING2__SET_IS_CARD_VALID';
export const PRICING2__SET_BANK = 'PRICING2__SET_BANK';
export const PRICING2__SET_ORIGIN = 'PRICING2__SET_ORIGIN';
export const PRICING2__SET_SUBSCRIPTION_TYPE = 'PRICING2__SET_SUBSCRIPTION_TYPE';
export const PRICING2__SET_SUBSCRIPTION_INFO = 'PRICING2__SET_SUBSCRIPTION_INFO';
export const PRICING2__SET_SUBSCRIPTION_TYPES = 'PRICING2__SET_SUBSCRIPTION_TYPES';
export const PRICING2__SET_PLANS_SHOWN = 'PRICING2__SET_PLANS_SHOWN';

export const PAYMENT_ERROR_MESSAGE =
  'There was an error processing the payment. Please try again or switch to a new payment method.';

export const PRICING_ORIGIN__SETTINGS = 'settings';
export const PRICING_ORIGIN__EXPENSE_REVIEW = 'expense-review';
export const PRICING_ORIGIN__EDIT_PAYMENT = 'edit_payment';
export const PRICING_ORIGIN__EDIT_PLAN = 'edit_plan';

// eslint-disable-next-line import/no-unused-modules
export const PRICING_ORIGIN__WEB_PAYWALL = 'web-paywall';
// eslint-disable-next-line import/no-unused-modules
export const PRICING_ORIGIN__PREMIUM_UPSELL_MODAL = 'premium-upsell-modal';
// eslint-disable-next-line import/no-unused-modules
export const PRICING_ORIGIN__SUBSCRIPTION_FLOW = 'subscription';

export const PRICING_ORIGIN__UPGRADE_ORIGINS = [
  PRICING_ORIGIN__SETTINGS,
  PRICING_ORIGIN__WEB_PAYWALL,
  PRICING_ORIGIN__PREMIUM_UPSELL_MODAL,
  PRICING_ORIGIN__EXPENSE_REVIEW,
  PRICING_ORIGIN__SUBSCRIPTION_FLOW,
  PRICING_ORIGIN__EDIT_PLAN
];
