import _ from 'lodash';
import { useGetSubscriptionInfoQuery } from '@app/src/api/pricingApi';
import { SUBSCRIPTION_TYPES } from '@app/src/constants/constants';

/**
 * Gets whether the user is a premium subscriber.
 *
 * Note: this does NOT check if the subscription is active
 */
export const useIsPremiumSubscriber = () => {
  const { data: subscriptionInfo, isLoading: subscriptionInfoLoading } = useGetSubscriptionInfoQuery();

  if (subscriptionInfoLoading) {
    return { isLoading: true };
  }

  const isPremiumSubscriber = _.get(subscriptionInfo, ['subscription', 'type']) === SUBSCRIPTION_TYPES.PREMIUM;
  return { data: isPremiumSubscriber, isLoading: false };
};
