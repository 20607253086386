import {
  SET_TAXFILING_ALL_QUESTIONS,
  SET_TAXFILING_QUERY_RESULTS,
  SET_TAXFILING_COGS_SUMMARY,
  SET_TAXFILING_CAR_PRIOR_DEPRECIATION,
  SET_TAXFILING_HOME_PRIOR_DEPRECIATION,
  SET_TAXFILING_FORM_UPLOAD_ATTEMPTS,
  SET_TAXFILING_DEDUCTIONS,
  SET_TAXFILING_APP_OS,
  SET_TAXFILING_IS_PAYMENT_VALID,
  SET_IS_NEXT_BUTTON_LOADING,
  SET_TAXFILING_QUESTIONS_ANSWERED,
  SET_TAXFILING_UPLOAD_ERROR
} from '@app/src/actions/types';
import { formUploadCollectionTypesSelector } from '@app/src/selectors/taxFlowSelectors';

export const setAllQuestions = (payload) => ({
  type: SET_TAXFILING_ALL_QUESTIONS,
  payload
});

export const setQueryResults = (payload) => ({
  type: SET_TAXFILING_QUERY_RESULTS,
  payload
});

export const setQuestionsAnswered = (payload) => ({
  type: SET_TAXFILING_QUESTIONS_ANSWERED,
  payload
});

export const setCogsSummary = (payload) => ({
  type: SET_TAXFILING_COGS_SUMMARY,
  payload
});

export const setDeductions = (payload) => ({
  type: SET_TAXFILING_DEDUCTIONS,
  payload
});

export const setCarPriorDepreciation = (payload) => ({
  type: SET_TAXFILING_CAR_PRIOR_DEPRECIATION,
  payload
});

export const setHomePriorDepreciation = (payload) => ({
  type: SET_TAXFILING_HOME_PRIOR_DEPRECIATION,
  payload
});

export const updateFormUploadAttempts =
  ({ attempts }) =>
  async (dispatch, getState) => {
    const newAttempts = {};
    const formUploadCollectionTypes = formUploadCollectionTypesSelector(getState());
    attempts.forEach((attempt, i) => {
      if (attempt.collectionType && formUploadCollectionTypes.includes(attempt.collectionType)) {
        newAttempts[attempt.collectionType] = {
          ...newAttempts[attempt.collectionType],
          [attempt.collectionId]: attempt
        };
      } else {
        newAttempts.bulk = {
          ...newAttempts.bulk,
          [i + 1]: attempt
        };
      }
    });

    await dispatch(setFormUploadAttempts(newAttempts));
  };

const setFormUploadAttempts = (payload) => ({
  type: SET_TAXFILING_FORM_UPLOAD_ATTEMPTS,
  payload
});

export const setUploadError = (payload) => ({
  type: SET_TAXFILING_UPLOAD_ERROR,
  payload
});

export const setAppOs = (payload) => ({
  type: SET_TAXFILING_APP_OS,
  payload
});

export const setIsPaymentValid = (payload) => ({
  type: SET_TAXFILING_IS_PAYMENT_VALID,
  payload
});

export const setIsNextButtonLoading = (payload) => ({
  type: SET_IS_NEXT_BUTTON_LOADING,
  payload
});
