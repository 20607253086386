import { useEffect, useState } from 'react';
import _ from 'lodash';
import { usePrevious } from 'react-use';
import useEvent from 'react-use-event-hook';
import { useGetCampaignQuery } from '@app/src/api/profileApi';
import {
  useGetBulkUploadPillsQuery,
  useGetBulkUploadSuggestionPillsQuery,
  useGetReviewPillsQuery,
  useGetSubmitIssuesQuery,
  useGetSubmitWarningsQuery
} from '@app/src/api/taxDataApi';
import { useHasTransactions } from '@app/src/hooks/useHasDeductions';
import { useUIState } from '@app/src/hooks/useUIState';
import { trackActivity } from '@app/src/services/analyticsService';
import {
  TAX_FILING_YEAR,
  UI_STAGE__ACCEPTED_BY_IRS,
  UI_STAGE__BLOCKED_FINAL_REVIEW,
  UI_STAGE__BLOCKED_PREFIX,
  UI_STAGE__CONFIRM_AMOUNTS,
  UI_STAGE__DO_NOT_FILE,
  UI_STAGE__EMAIL_OUTBOX_PREFIX,
  UI_STAGE__FILED_WITH_IRS,
  UI_STAGE__FINAL_REVIEW,
  UI_STAGE__OPS_REVIEW,
  UI_STAGE__PURGATORY,
  UI_STAGE__UPLOAD_ESC
} from '@app/src/taxflow/shared/constants/sharedConstants';

export const useHomeAnalytics = ({ isLoading }) => {
  const { uiState, isLoading: uiStateLoading } = useUIState();
  const { data: campaign, isLoading: uiStageLoading } = useGetCampaignQuery();
  const { data: bulkUploadPills } = useGetBulkUploadPillsQuery();
  const { data: bulkUploadSuggestionPills } = useGetBulkUploadSuggestionPillsQuery();
  const { data: submitWarnings } = useGetSubmitWarningsQuery();
  const { data: submitIssues } = useGetSubmitIssuesQuery();
  const { data: reviewPills } = useGetReviewPillsQuery();
  const { hasTransactions } = useHasTransactions({ year: TAX_FILING_YEAR });

  const uiStateSlug = uiState?.slug;
  const uiStage = campaign?.ui_stage;
  const [loadStart, setLoadStart] = useState(0);
  const prevIsLoading = usePrevious(isLoading);

  const trackNavigationFileTaxes = useEvent(() => {
    const flattenedReviewPills = _.chain(reviewPills).map('pills').flatten().value();
    trackActivity('navigation: file taxes', {
      uiState: uiStateSlug,
      uiStage: campaign?.ui_stage,
      hasTransactions,
      bulkUploadCount: _.size(bulkUploadPills),
      warningBulkUploadCount: _.chain(bulkUploadPills)
        .filter(({ validationFailed, parseFailed }) => validationFailed || parseFailed)
        .size()
        .value(),
      warningBulkUploadCollectionTypes: _.chain(bulkUploadPills)
        .filter(({ validationFailed, parseFailed }) => validationFailed || parseFailed)
        .map('collectionType')
        .uniq()
        .value(),
      bulkUploadSuggestionCount: _.size(bulkUploadSuggestionPills),
      bulkUploadSuggestionCollectionTypes: _.chain(bulkUploadSuggestionPills).map('collectionType').uniq().value(),
      warningReviewPillCount: _.chain(flattenedReviewPills).filter({ warning: true }).size().value(),
      warningReviewPillCollectionTypes: _.chain(flattenedReviewPills)
        .filter({ warning: true })
        .map('collectionType')
        .uniq()
        .value(),
      submitWarningsCount: _.size(submitWarnings),
      submitWarningsSlugs: _.map(submitWarnings, 'slug'),
      escalationErrorsCount: _.size(submitIssues)
    });
  });

  useEffect(() => {
    if (isLoading && !prevIsLoading) {
      setLoadStart(performance.now());
      trackActivity('tax home load start');
    }
    if (!isLoading && prevIsLoading) {
      trackActivity('home load time', { loadTime: performance.now() - loadStart, uiState: uiStateSlug });
    }
  }, [isLoading, prevIsLoading, loadStart, uiStateSlug]);

  useEffect(() => {
    if (_.isNil(uiStateSlug) || uiStateLoading || isLoading) {
      return;
    }
    trackNavigationFileTaxes();
  }, [uiStateSlug, uiStateLoading, isLoading, trackNavigationFileTaxes]);

  // Keeping question: view for event reverse-compatibility
  // TODO We should eventually remove this once all amplitude charts are updated to account for the new 'navigation: file taxes' event
  useEffect(() => {
    if (uiStageLoading) {
      return;
    }
    // These questions no longer exist.
    // "Faking" question prop for reverse compatibility
    const question = _.get(
      {
        [UI_STAGE__PURGATORY]: 'submit-bye',
        [UI_STAGE__OPS_REVIEW]: 'submit-bye-manual',
        [UI_STAGE__UPLOAD_ESC]: 'submit-bye-manual-esc',
        [UI_STAGE__CONFIRM_AMOUNTS]: 'submit-tax-amount',
        [UI_STAGE__FINAL_REVIEW]: 'submit-sent-to-irs',
        [UI_STAGE__BLOCKED_FINAL_REVIEW]: 'submit-blocked-final-review',
        [UI_STAGE__BLOCKED_PREFIX]: 'submit-blocked',
        [UI_STAGE__EMAIL_OUTBOX_PREFIX]: 'email-outbox',
        [UI_STAGE__FILED_WITH_IRS]: 'submit-filed-with-irs',
        [UI_STAGE__ACCEPTED_BY_IRS]: 'submit-accepted-by-irs',
        [UI_STAGE__DO_NOT_FILE]: 'submit-unable-to-file'
      },
      _.first(_.split(uiStage, ' - ')),
      'submit-finish'
    );
    trackActivity('question: view', { question, uiStage });
  }, [uiStage, uiStageLoading]);
};
