import _ from 'lodash';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serverUrl } from '@app/src/global/Environment';
import history from '@app/src/keeperHistory';
import { logout } from '@app/src/services/authService';

const baseUrl = serverUrl();

export const TAGS = {
  // tax data
  ALL_EXPENSES: 'AllExpenses',
  BULK_UPLOAD_PILLS: 'BulkUploadPills',
  CAR_PILLS: 'CarPills',
  CURRENT_QUESTIONNAIRE_QUESTION: 'CurrentQuestionnaireQuestion',
  HOME_ADDRESS_PRESELECT_OPTIONS: 'HomeAddressPreselectOptions',
  HOME_PILLS: 'HomePills',
  ID_VERIFICATION_RESULT: 'IdVerificationResult',
  ID_VERIFICATION_QUESTIONS: 'IdVerificationQuestions',
  RETURN_STATUS: 'ReturnStatus',
  REVIEW_PILLS: 'QuestionnaireSummaryPills',
  QUESTIONNAIRE_PROGRESS: 'QuestionnaireProgress',
  QUESTIONNAIRE_SUMMARY_PILLS: 'QuestionnaireSummaryPills',
  SSN_MATCHED: 'SsnMatched',
  SUBMIT_ISSUES: 'SubmitIssues',
  SUBMIT_TIMESTAMP: 'SubmitTimestamp',
  SUBMIT_WARNINGS: 'SubmitWarnings',
  TAX_AMOUNTS: 'TaxAmounts',
  TAX_DATA: 'TaxData',

  // profile
  ACCOUNT_DETAILS: 'AccountDetails',
  CAMPAIGN: 'Campaign',
  FEATURES: 'Features',
  JOBS: 'Jobs',
  RETRO_STATUS: 'RetroStatus',
  TAX_ESTIMATE: 'TaxEstimate',
  WORK_INFO: 'WorkInfo',

  // plaid
  PAID: 'Paid',

  // expenses
  EXPENSE_REVIEW_DETAILS: 'ExpenseReviewDetails',

  // pricing
  SUBSCRIPTION_INFO: 'SubscriptionInfo'
};

export const FIXED_CACHE_KEYS = {
  GENERATE_QUESTIONNAIRE: 'generate-questionnaire'
};

const baseQuery = async (args, api, extraOptions) => {
  const result = await fetchBaseQuery({
    baseUrl: `${baseUrl}api/`,
    prepareHeaders: (headers) => headers.set('Authorization', localStorage.getItem('KeeperToken'))
  })(args, api, extraOptions);

  // Logout on unauthorized - the users token is not valid
  if (result?.data?.msg === 'Unauthorized Request') {
    await api.dispatch(logout());
    history.push('/');
  }

  return result;
};

export const baseApi = createApi({
  refetchOnReconnect: true,
  refetchOnFocus: true,
  // Keep unused query data for 3 mins (this is how long cached query state is valid after removal of all subscriptions)
  keepUnusedDataFor: 3 * 60,
  baseQuery,
  tagTypes: _.values(TAGS),
  endpoints: () => ({})
});
