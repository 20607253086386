import React from 'react';
import _ from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import OnboardingTaxProfileInputs from '@app/src/Components/HolisticOnboarding/components/OnboardingTaxProfile/OnboardingTaxProfileInputs';
import { TaxProfileFormContext } from '@app/src/Components/TaxProfile/TaxProfileFormContext';
import { useGetWorkInfoQuery, useUpdateWorkInfoMutation } from '@app/src/api/profileApi';

const OnboardingTaxProfile = () => {
  const { data: workinfo } = useGetWorkInfoQuery();

  const [updateWorkInfo] = useUpdateWorkInfoMutation();

  const methods = useForm({
    values: workinfo,
    defaultValues: {
      filing_status: '',
      tax_calc_credits: {
        numDependents: 0
      },
      state_residence: '',
      w2_income_amount: null,
      agi: null,
      spouse_w2_income: null,
      spouse_agi: null,
      percent_phone: 50,
      percent_home: 10,
      percent_car: 50
    }
  });

  return (
    <FormProvider {...methods}>
      <TaxProfileFormContext.Provider
        value={{
          onSubmit: (property) => async (data) => {
            const previousValue = _.get(workinfo, property);
            const newValue = _.get(data, property);

            if (previousValue === newValue) {
              return;
            }

            await updateWorkInfo(data);
          }
        }}
      >
        <OnboardingTaxProfileInputs workInfo={workinfo} />
      </TaxProfileFormContext.Provider>
    </FormProvider>
  );
};

export default OnboardingTaxProfile;
