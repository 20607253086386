import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { connect } from 'react-redux';
import CloseIcon from '@app/src/assets/close.svg?react';
import { blockingModalPropsSelector } from '@app/src/selectors/navigationListSelectors';
import { setBlockingModalProps } from '@app/src/taxflow/navigation/actions/navigationActions';
import '@app/src/Components/TaxFlow/Common/TaxFlowBlockingModal.scss';

const TaxFlowBlockingModal = ({ blockingModalProps, setBlockingModalProps }) => {
  const { slug, open, title, body, iconUrl } = blockingModalProps;
  return (
    <Dialog
      open={open}
      onClose={() => {
        setBlockingModalProps({ ...blockingModalProps, open: false });
      }}
      sx={{ '.MuiPaper-root': { maxWidth: '700px', borderRadius: '8px' } }}
    >
      <DialogTitle
        sx={{
          marginTop: 1.5,
          marginRight: 1.5,
          marginLeft: 1.5,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {iconUrl && <img className='taxflow-blocking-modal-icon' src={iconUrl} alt={`${slug}-icon`} />}
        <div>{title}</div>
        <IconButton
          aria-label='close'
          onClick={() => {
            setBlockingModalProps({ ...blockingModalProps, open: false });
          }}
          sx={{
            color: 'primary.main',
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ margin: 1 }}>
        <DialogContentText>{body}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  blockingModalProps: blockingModalPropsSelector(state)
});

const mapDispatchToProps = {
  setBlockingModalProps
};

const ConnectedTaxFlowBlockingModal = connect(mapStateToProps, mapDispatchToProps)(TaxFlowBlockingModal);

export default ConnectedTaxFlowBlockingModal;
