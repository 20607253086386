import React from 'react';
import { useSelector } from 'react-redux';
import WarningCircle from '@app/src/assets/warning-circle.svg?react';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { TAX_FILING_YEAR } from '@app/src/taxflow/shared/constants/sharedConstants';
import '@app/src/taxflow/main/components/YearInfoBanner.scss';

const YearInfoBanner = () => {
  const year = useSelector(yearSelector);

  if (Number(year) !== TAX_FILING_YEAR + 1) {
    return null;
  }

  return (
    <div className='year-info-banner'>
      <WarningCircle />
      <span>
        Heads up! {year} tax filing opens January {Number(year) + 1}
      </span>
    </div>
  );
};

export default YearInfoBanner;
