import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { JobSelect } from '@app/src/Components/Common/JobSelect/JobSelect';
import CloseIcon from '@app/src/assets/close.svg?react';
import '@app/src/Components/TaxProfile/ManageJobsPanel.scss';

const ManageJobsPanel = ({ open, onClose, value, allJobs, topJobCategories, onSave }) => {
  const jobsBySlug = useMemo(() => _.keyBy(allJobs, 'slug'), [allJobs]);
  const [selectedJobs, setSelectedJobs] = useState(value);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={onClose}
      TransitionProps={{
        onEntering: () => {
          setSelectedJobs(value);
        }
      }}
    >
      <DialogTitle
        className='dialog-title'
        style={{
          paddingBottom: 0,
          borderBottom: 'none'
        }}
      >
        <div className='dialog-title-child'>
          <span>Manage Jobs</span>
          <IconButton aria-label='close' edge='end' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <div className='dialog-divider' />
      <DialogContent className='dialog-content'>
        <JobSelect
          allJobs={allJobs}
          topJobCategories={topJobCategories}
          selectedJobs={selectedJobs.map(
            (slug) =>
              jobsBySlug[slug] ?? {
                slug,
                name: slug
              }
          )}
          onAdd={(job) => setSelectedJobs([job.slug, ...selectedJobs])}
          onRemove={(item) => setSelectedJobs(selectedJobs.filter((j) => j !== item.slug))}
          emptyUntilFocused={false}
          listProps={{
            sx: {
              height: 283,
              marginBottom: 2
            }
          }}
        />
        <Button
          variant='contained'
          color='secondary'
          fullWidth
          onClick={() => {
            onSave && onSave(selectedJobs);
            onClose();
          }}
        >
          Save
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ManageJobsPanel;
