import React from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { CloseCircle, TickCircle } from 'iconsax-react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { subscriptionTypesSelector } from '@app/src/selectors/pricingSelectors';
import { colorError, colorFontDark, colorSuccess } from '@app/src/theme';
import '@app/src/Components/Subscription/Common/SubscriptionTypeElement.scss';

export const SubscriptionTypeElement = ({
  type,
  onClick,
  focused,
  selected,
  style,
  children,
  hideBubble,
  ...props
}) => {
  const subscriptionTypes = useSelector(subscriptionTypesSelector);

  const values = _.get(subscriptionTypes, type, {});

  const name = values.name;
  const price = values.price;
  const period = values.period;
  const subtitle = values.subtitle;
  const features = values.features;
  const pillText = values?.pillText;
  const bubbleText = values?.bubbleText;

  return (
    <Button
      className='subscription-type-element'
      onClick={() => onClick(type)}
      style={{ ...style, padding: focused ? '16px' : '16px 16px 0' }}
      sx={{ height: 'fit-content', position: 'relative' }}
      variant={selected ? 'subscription-type-selected' : 'subscription-type'}
      {...props}
    >
      {bubbleText && !hideBubble && (
        <div className='bubble'>
          <div className='bubble-text'>{bubbleText}</div>
        </div>
      )}
      <div style={{ width: '100%' }}>
        <Stack direction='column' justifyContent='space-between'>
          <Stack direction='column' justifyContent='space-between'>
            <Stack direction='row' justifyContent='space-between'>
              <div className='base-label title'>{name}</div>
              <div style={{ color: colorFontDark, display: 'flex' }}>
                <span style={{ alignSelf: 'flex-start', fontSize: '12px' }}>$</span>
                <span className='base-label' style={{ fontSize: '24px' }}>
                  {price}
                </span>
                <span style={{ alignSelf: 'center', fontSize: '12px' }}>/{period === 'monthly' ? 'mo' : 'yr'}</span>
              </div>
            </Stack>
            <Stack direction='row' justifyContent='space-between'>
              <div className='base-label subtitle'>{subtitle}</div>
              {pillText && (
                <div
                  className='base-label'
                  style={{ color: '#054628', backgroundColor: '#DBFFDB', padding: '4px 8px', borderRadius: '14px' }}
                >
                  {pillText}
                </div>
              )}
            </Stack>
          </Stack>
          <Collapse in={focused}>
            <Stack direction='column' justifyContent='space-between' spacing={1}>
              {features?.map(({ text, included }, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row' }}>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                    {included ? (
                      <TickCircle variant='Bulk' color={colorSuccess} />
                    ) : (
                      <CloseCircle variant='Bulk' color={colorError} />
                    )}
                  </div>
                  <div style={{ marginLeft: '4px' }}>
                    <ReactMarkdown
                      components={{
                        h4: 'p'
                      }}
                    >
                      {text}
                    </ReactMarkdown>
                  </div>
                </div>
              ))}
            </Stack>
          </Collapse>
        </Stack>
        {children}
      </div>
    </Button>
  );
};

export default SubscriptionTypeElement;
