import React, { useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TaxFlowQuestionItem from '@app/src/Components/TaxFlow/Question/TaxFlowQuestionItem';
import { setErrors } from '@app/src/actions/taxValidationActions';
import { useGoBackToPreviousQuestionnaireQuestionMutation } from '@app/src/api/taxDataApi';
import { useRedirectOnInvalidCollectionId } from '@app/src/hooks/useRedirectOnInvalidCollectionId';
import { isQuestionnaireFlowSelector } from '@app/src/selectors/taxFlowSelectors';
import { errorsSelector, isTaxFilingNextEnabledSelector } from '@app/src/selectors/taxValidationSelectors';
import { replaceStrings } from '@app/src/taxflow/common';
import { showNextBtn } from '@app/src/taxflow/main/utils/mainUtils';
import {
  currentAnswerSelector,
  currentCollectionIdSelector,
  currentQuestionSelector,
  queryResultsSelector,
  statusSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { scrollToTop } from '@app/src/utils';

const TaxFlowQuestion = (props) => {
  const {
    currentQuestion,
    errors,
    history,
    isQuestionnaireFlow,
    isTaxFilingNextEnabled,
    currentCollectionId,
    onPrev: passedOnPrev,
    onNext: passedOnNext,
    replaceStrings,
    setErrors
  } = props;

  useRedirectOnInvalidCollectionId();

  const [goBackToPreviousQuestionnaireQuestion] = useGoBackToPreviousQuestionnaireQuestionMutation();
  const onPrev = async () => {
    if (passedOnPrev) {
      passedOnPrev();
      return;
    }

    // If "back" is clicked while in the questionnaire flow, navigate to the previous questionnaire question.
    // If the user exits the flow (occurs if they are on the first question), navigate back to bulk upload
    if (isQuestionnaireFlow) {
      const {
        data: { questionnaireExited }
      } = await goBackToPreviousQuestionnaireQuestion();
      if (!questionnaireExited) {
        return;
      }
    }

    history.goBack();
  };

  const onNext = () => {
    if (passedOnNext) {
      passedOnNext();
      return;
    }
  };

  const onFocus = (question, collectionId) => {
    const collectionType = _.get(question, 'collectionType', _.get(currentQuestion, 'collectionType'));
    const endpoint_attr = _.get(question, 'endpoint_attr', _.get(currentQuestion, 'endpoint_attr'));

    const error = _.find(errors, {
      coll_type: collectionType,
      slug: endpoint_attr,
      coll_id: collectionId || currentCollectionId
    });

    if (error) {
      _.chain(errors)
        .filter(
          (err) => !(err.slug === error.slug && err.coll_type === error.coll_type && err.coll_id === error.coll_id)
        )
        .tap(setErrors)
        .value();
    }
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <TaxFlowQuestionItem
      isPrev
      {...props}
      urlObj={{}}
      isNext={showNextBtn({ slug: currentQuestion.slug })}
      onNextQuest={onNext}
      onPrev={onPrev}
      onNext={onNext}
      onFocus={onFocus}
      isNextDisabled={!isTaxFilingNextEnabled}
      replaceStrings={replaceStrings}
    />
  );
};

const mapStateToProps = (state) => ({
  bank: state.bank,
  currentQuestion: currentQuestionSelector(state),
  currentAnswer: currentAnswerSelector(state),
  queryResults: queryResultsSelector(state),
  currentCollectionId: currentCollectionIdSelector(state),
  isTaxFilingNextEnabled: isTaxFilingNextEnabledSelector(state),
  status: statusSelector(state),
  errors: errorsSelector(state),
  isQuestionnaireFlow: isQuestionnaireFlowSelector(state)
});

const mapDispatchToProps = {
  replaceStrings,
  setErrors
};

const ConnectedTaxFlowQuestion = connect(mapStateToProps, mapDispatchToProps)(withRouter(TaxFlowQuestion));

export default ConnectedTaxFlowQuestion;
