import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { setExpenseReviewModalType } from '@app/src/actions/expenseReviewActions';
import useExpenseUploadFiles from '@app/src/hooks/useExpenseUploadFiles';
import '@app/src/Components/Common/ExpenseUploadBanner/ExpenseUploadBanner.scss';

const ExpenseUploadBanner = ({ className, title, origin, setExpenseReviewModalType }) => {
  const { handleUploadClick } = useExpenseUploadFiles({ origin, setExpenseReviewModalType });

  return (
    <div className={classNames('expense-upload-banner', className)}>
      <div className='expense-upload-banner-title'>{title}</div>
      <div className='expense-upload-banner-button' onClick={handleUploadClick}>
        Upload expenses
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  setExpenseReviewModalType
};

const ConnectedExpenseUploadBanner = connect(null, mapDispatchToProps)(ExpenseUploadBanner);

export default ConnectedExpenseUploadBanner;
