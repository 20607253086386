import React, { useCallback, useMemo, useState } from 'react';
import url from 'url';
import _ from 'lodash';
import Fuse from 'fuse.js';
import { connect } from 'react-redux';
import SearchList from '@app/src/Components/Common/SearchList/SearchList';
import history from '@app/src/keeperHistory';
import { trackActivity } from '@app/src/services/analyticsService';
import { requestInfoResponseMessage } from '@app/src/services/assistantService';
import { bulkUploadItemsSelector } from '@app/src/taxflow/main/selectors/formUploadSelectors';
import { getNextCollectionId } from '@app/src/taxflow/main/services/taxFlowDataService';
import { getPathComponentBySlug } from '@app/src/taxflow/main/utils/mainUtils';
import { TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';
import { currentQuestionSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { colorFilterSecondary } from '@app/src/theme';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';

const TaxFlowFormInputDropdown = ({ options, requestInfoResponseMessage, currentQuestion }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleClick = useCallback(
    async ({ startSlug, collectionType, name, message, response }) => {
      if (!_.isNil(message) && !_.isNil(response)) {
        // If the selected pill has a message and response, have the assistant send the canned response
        requestInfoResponseMessage({
          message,
          response,
          origin: 'taxflow bulk upload manual entry',
          slug: currentQuestion.slug
        });
        return;
      }

      // Else, navigate to the linked question
      const collectionId = await getNextCollectionId({ collectionType });
      trackActivity('review: add collection item', { collectionType, collectionId, pillName: name });
      const nextPathComponent = getPathComponentBySlug(startSlug);
      if (_.isNil(nextPathComponent)) {
        defaultCaptureMessage('bulk upload manual entry: could not advance to next item', {
          coll_type: collectionType,
          coll_id: collectionId,
          slug: startSlug
        });
        return;
      }
      history.push(
        url.format({
          pathname: `/${TAXFLOW_BASE_URL}/${nextPathComponent}`,
          query: {
            collectionId: collectionId === '0' ? undefined : collectionId
          }
        })
      );
    },
    [requestInfoResponseMessage, currentQuestion.slug]
  );

  const formatListItem = useCallback(
    ({ listDisplayName: text, iconUrl, collectionType, message, response, formInputQuestionSlug, synonyms = [] }) => {
      return {
        id: text,
        name: text,
        iconUrl,
        showInList: true,
        collectionType,
        message,
        response,
        synonyms,
        startSlug: formInputQuestionSlug
      };
    },
    []
  );

  const fuse = useMemo(
    () =>
      new Fuse(options, {
        keys: ['text', 'synonyms'],
        threshold: 0.5
      }),
    [options]
  );

  const listItems = useMemo(() => {
    const searchMatches = (
      searchQuery.trim().length ? fuse.search(searchQuery).map(({ item }) => item) : options
    ).concat({
      listDisplayName: 'Something Else',
      message: 'I need help adding something else to my return',
      response: "Tell us more about your tax situation and we'll take it from there.",
      iconUrl:
        '//images.ctfassets.net/xauw5r0xpp02/7cQany4RAZi0oQcEN6QOqJ/59ec7704181f976fffc9d7ab44897466/help-support.png'
    });
    return searchMatches.map(formatListItem);
  }, [options, formatListItem, fuse, searchQuery]);

  return (
    <div className='steps-body'>
      <SearchList
        iconFilter={colorFilterSecondary}
        items={listItems}
        onAddItem={handleClick}
        onSearch={(search) => setSearchQuery(search)}
        query={searchQuery}
        searchPlaceholderText='Search'
        style={{ margin: 'auto' }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  options: bulkUploadItemsSelector(state).filter((item) => item.supportManualEntry), // Filter out items that don't support manual entry
  currentQuestion: currentQuestionSelector(state)
});

const mapDispatchToProps = {
  requestInfoResponseMessage
};

const ConnectedTaxFlowFormInputDropdown = connect(mapStateToProps, mapDispatchToProps)(TaxFlowFormInputDropdown);

export default ConnectedTaxFlowFormInputDropdown;
