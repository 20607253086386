import {
  SET_TAXFILING_NAVIGATION_ITEMS,
  SET_TAXFILING_CURRENT_NAVIGATION_SECTION,
  SET_TAXFILING_SUMMARY_ITEMS,
  SET_TAXFILING_IS_SUBMIT_DONE,
  SET_TAXFILING_IS_NAVIGATION_PANEL_OPEN,
  SET_TAXFILING_SUPPORT_ACCESS_ORIGIN,
  SET_TAXFILING_SELF_UNLOCK_MODAL_OPEN,
  SET_TAXFILING_NAV_SECTION_PILLS,
  SET_TAXFILING_BLOCKING_MODAL_PROPS
} from '@app/src/actions/types';

const initialState = {
  navigationItems: [],
  currentNavigationSection: {},
  navSectionPills: {},
  summaryItems: {},
  isSpecialDone: false,
  isSubmitDone: false,
  isFilingQualified: false,
  isNavigationPanelOpen: false,
  supportAccessOrigin: null,
  selfUnlockModalOpen: false,
  blockingModalProps: {
    open: false,
    slug: '',
    title: '',
    body: '',
    iconUrl: ''
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_TAXFILING_NAVIGATION_ITEMS: {
      return {
        ...state,
        navigationItems: action.payload
      };
    }

    case SET_TAXFILING_CURRENT_NAVIGATION_SECTION: {
      return {
        ...state,
        currentNavigationSection: action.payload
      };
    }

    case SET_TAXFILING_NAV_SECTION_PILLS: {
      return {
        ...state,
        navSectionPills: action.payload
      };
    }

    case SET_TAXFILING_SUMMARY_ITEMS: {
      return {
        ...state,
        summaryItems: action.payload
      };
    }

    case SET_TAXFILING_IS_SUBMIT_DONE: {
      return {
        ...state,
        isSubmitDone: action.payload
      };
    }

    case SET_TAXFILING_IS_NAVIGATION_PANEL_OPEN: {
      return {
        ...state,
        isNavigationPanelOpen: action.payload
      };
    }

    case SET_TAXFILING_SUPPORT_ACCESS_ORIGIN: {
      return {
        ...state,
        supportAccessOrigin: action.payload
      };
    }

    case SET_TAXFILING_SELF_UNLOCK_MODAL_OPEN: {
      return {
        ...state,
        selfUnlockModalOpen: action.payload
      };
    }

    case SET_TAXFILING_BLOCKING_MODAL_PROPS: {
      return {
        ...state,
        blockingModalProps: action.payload
      };
    }

    default:
      return state;
  }
}
