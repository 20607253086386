import React from 'react';
import _ from 'lodash';
import LinearProgress from '@mui/material/LinearProgress';
import { useGetCurrentQuestionnaireQuestionQuery, useGetQuestionnaireProgressQuery } from '@app/src/api/taxDataApi';
import ArrowRightIcon from '@app/src/assets/arrow-right.svg?react';
import { getIsTaxFilingSandwiched } from '@app/src/global/Helpers';
import { colorNeutralGainsboro, colorSuccess } from '@app/src/theme';
import '@app/src/Components/TaxFlow/Common/TaxFlowQuestionnaireProgressBar.scss';

function TaxFlowQuestionnaireProgressBar() {
  const { data: currentQuestionnaireQuestion, isLoading: isCurrentQuestionnaireQuestionLoading } =
    useGetCurrentQuestionnaireQuestionQuery();
  const { data: progress, isLoading: progressLoading } = useGetQuestionnaireProgressQuery();
  const isFilingSandwiched = getIsTaxFilingSandwiched();
  if (progressLoading || isCurrentQuestionnaireQuestionLoading) {
    return null;
  }
  const { totalQuestions, passedQuestions } = progress;
  if (_.isNil(totalQuestions) || _.isNil(passedQuestions) || progressLoading) {
    return null;
  }

  return (
    <div className='questionnaire-progress-bar'>
      <LinearProgress
        sx={{
          backgroundColor: colorNeutralGainsboro,
          color: colorSuccess,
          borderRadius: 2,
          height: 8,
          '& .MuiLinearProgress-bar': { borderRadius: 5, backgroundColor: colorSuccess }
        }}
        variant='determinate'
        value={_.max([_.divide(passedQuestions * 100, totalQuestions), 5])}
      />
      {!isFilingSandwiched && (
        <div className='questionnaire-progress-bar-breadcrumb'>
          <div className='questionnaire-progress-bar-breadcrumb-parent'>Questionnaire</div>
          <ArrowRightIcon width={16} height={16} />
          <div className='questionnaire-progress-bar-breadcrumb-child'>
            {currentQuestionnaireQuestion.type === 'form-review' ? 'Review forms' : 'Follow up questions'}
          </div>
        </div>
      )}
    </div>
  );
}

export default TaxFlowQuestionnaireProgressBar;
