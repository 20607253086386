import {
  SET_TAXFILING_IS_NAVIGATION_PANEL_OPEN,
  SET_TAXFILING_BLOCKING_MODAL_PROPS,
  SET_TAXFILING_SELF_UNLOCK_MODAL_OPEN,
  SET_TAXFILING_SUPPORT_ACCESS_ORIGIN
} from '@app/src/actions/types';

export const setIsNavigationPanelOpen = (payload) => ({
  type: SET_TAXFILING_IS_NAVIGATION_PANEL_OPEN,
  payload
});

export const setSupportAccessOrigin = (payload) => ({
  type: SET_TAXFILING_SUPPORT_ACCESS_ORIGIN,
  payload
});

export const setSelfUnlockModalOpen = (payload) => ({
  type: SET_TAXFILING_SELF_UNLOCK_MODAL_OPEN,
  payload
});

export const setBlockingModalProps = (payload) => ({
  type: SET_TAXFILING_BLOCKING_MODAL_PROPS,
  payload
});
