import _ from 'lodash';
import { StreamChat } from 'stream-chat';
import {
  setAssistantConnected,
  setAssistantHasError,
  setAssistantLoading,
  setAssistantMessages,
  setAssistantOpen,
  setAssistantTyping,
  setAssistantUnreadMessages
} from '@app/src/actions/assistantActions';
import { SEND_STREAM_MESSAGE, SET_ASSISTANT_OPEN, SET_CURRENT_USER } from '@app/src/actions/types';
import { TAGS } from '@app/src/api/baseApi';
import profileApi from '@app/src/api/profileApi';
import { STREAM_KEY } from '@app/src/global/Environment';
import { getAttachmentType, getIsTaxFilingSandwiched, sentMsgToReactNative } from '@app/src/global/Helpers';
import { trackActivity } from '@app/src/services/analyticsService';
import { generatePageSummary, getMessageList, uploadFile } from '@app/src/services/assistantService';
import { getOrCreateChannel } from '@app/src/services/messageService';
import { formatAndAddNewMessage, formatStreamMessage, getImageDimensions } from '@app/src/utils/assistantUtils';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';

export const streamMiddleware = (store) => {
  const client = StreamChat.getInstance(STREAM_KEY, { timeout: 6000 });

  let streamChannel = null;

  return (next) => (action) => {
    switch (action.type) {
      case SET_CURRENT_USER: {
        (async () => {
          const user = !_.isEmpty(action.payload) ? action.payload : null;

          if (user) {
            try {
              streamChannel = await initializeStream(store, client, user);
            } catch (e) {
              defaultCaptureException(e);
              store.dispatch(setAssistantHasError(true));
            }
          } else {
            // Logging out
            if (client.userID) {
              await client.disconnectUser();
            }
          }
        })();

        return next(action);
      }
      case SET_ASSISTANT_OPEN: {
        if (action.payload) {
          store.dispatch(setAssistantUnreadMessages(0));
        }

        if (client.userID) {
          client.markChannelsRead();
        }

        return next(action);
      }
      case SEND_STREAM_MESSAGE: {
        return (async () => {
          const { file, message } = action.payload;

          if (_.isEmpty(message.text) && _.isEmpty(file)) {
            return;
          }

          const origin = message?.origin ?? 'ask tab';

          const dimensions = await getImageDimensions(file);

          trackActivity('messaging: user submitted message from client', {
            message: message.text,
            origin,
            ...(file?.type && { attachmentTypes: getAttachmentType(file.type) })
          });

          const pageSummary = await store.dispatch(generatePageSummary());

          const commonMessageProps = {
            client_type: 'web',
            origin,
            location: window.location.pathname
          };

          try {
            if (_.isString(message.text) && message.text.trim().length > 0) {
              const response = await streamChannel.sendMessage({
                ...commonMessageProps,
                pageSummary,
                has_file: !_.isEmpty(file),
                ...message
              });

              const uploadedFile = !_.isNil(file) ? await uploadFile(file) : null;

              if (response?.message?.id && uploadedFile) {
                await client.partialUpdateMessage(response?.message?.id, {
                  set: {
                    attachments: _.compact([
                      ...(response?.message?.attachments || []),
                      uploadedFile && {
                        type: getAttachmentType(file.type),
                        contentType: file.type,
                        key: uploadedFile.key,
                        filename: uploadedFile.filename,
                        thumbnailWidth: uploadedFile.thumbnailWidth,
                        ...(dimensions ? { ...dimensions } : {})
                      }
                    ])
                  }
                });
              }
            } else {
              const uploadedFile = await uploadFile(file);

              if (!uploadedFile) {
                throw new Error('Unable to send message');
              }

              await streamChannel.sendMessage({
                ...commonMessageProps,
                pageSummary,
                attachments: _.compact([
                  uploadedFile && {
                    type: getAttachmentType(file.type),
                    contentType: file.type,
                    key: uploadedFile.key,
                    filename: uploadedFile.filename,
                    thumbnailWidth: uploadedFile.thumbnailWidth,
                    ...(dimensions ? { ...dimensions } : {})
                  }
                ]),
                has_file: true,
                client_type: 'web'
              });
            }
          } catch (e) {
            defaultCaptureException(e);
            store.dispatch(setAssistantHasError(true));
            throw e;
          }

          // If sandwiched, open the native chat tab. Else, open the web assistant
          if (getIsTaxFilingSandwiched()) {
            sentMsgToReactNative('tax_support_ask_assistant');
          } else {
            store.dispatch(setAssistantOpen(true));
          }
        })();
      }
      default: {
        return next(action);
      }
    }
  };
};

const getInitialMessages = async (user) => {
  const messages = await getMessageList();

  return messages.map((message) => ({
    id: message.sid,
    sender: message.from === user.phone ? 'user' : 'keeper',
    content: message.body,
    created_at: new Date(message.time)
  }));
};

const initializeStream = async (store, client, user) => {
  const initialMessages = await getInitialMessages(user);

  const syncMessages = (streamMessages, unreadCount) => {
    const messagesWithTimestamps = _.chain(streamMessages)
      .map(formatStreamMessage)
      .unionBy(initialMessages, 'id')
      .sortBy('created_at')
      .reduce(formatAndAddNewMessage, [])
      .value();

    store.dispatch(setAssistantMessages(messagesWithTimestamps));

    store.dispatch(setAssistantUnreadMessages(unreadCount));
  };

  try {
    store.dispatch(setAssistantLoading(true));

    const channelData = await getOrCreateChannel(user);

    if (!_.has(channelData, 'token')) {
      const error = new Error('Channel token not found');
      error.context = { extra: { channelData } };

      throw error;
    }

    if (client.userID) {
      await client.disconnectUser();
    }

    await client.connectUser(
      {
        id: `user_id_${user.id}`,
        name: user.firstname
      },
      channelData.token
    );

    if (!_.has(channelData, 'channel_id')) {
      const error = new Error('Channel ID not found');
      error.context = { extra: { channelData } };

      throw error;
    }

    const channel = client.channel('messaging', channelData.channel_id);

    await channel.watch();

    syncMessages(channel.state.messages, channel.countUnread());

    channel.on('message.new', (event) => {
      syncMessages(channel.state.messages, event.total_unread_count);

      if (_.get(event, ['message', 'refresh_feature_flags'])) {
        store.dispatch(profileApi.util.invalidateTags([TAGS.FEATURES]));
      }
    });

    channel.on('message.updated', (event) => {
      syncMessages(channel.state.messages, event.total_unread_count);
    });

    channel.on('typing.start', (event) => {
      store.dispatch(setAssistantTyping(_.get(event, ['user', 'name'], 'assistant')));
    });

    channel.on('typing.stop', () => {
      store.dispatch(setAssistantTyping(null));
    });

    store.dispatch(setAssistantHasError(false));
    store.dispatch(setAssistantConnected(true));

    return channel;
  } catch (e) {
    store.dispatch(setAssistantHasError(true));
    throw e;
  } finally {
    store.dispatch(setAssistantLoading(false));
  }
};
