import _ from 'lodash';
import {
  PATH_COMPONENT__CREDIT_QUARTERLY_TOTAL,
  PATH_COMPONENT__CREDIT_QUARTERLY_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_HEALTH_OPTIONS,
  PATH_COMPONENT__CREDIT_HEALTH_EMPLOYER,
  PATH_COMPONENT__CREDIT_HEALTH_PLAN,
  PATH_COMPONENT__CREDIT_HEALTHCARE_1095A_PLAN_DETAIL,
  PATH_COMPONENT__CREDIT_HEALTH_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_HSA_DETAIL,
  PATH_COMPONENT__CREDIT_HSA_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_RETIREMENT_ACCOUNT_DETAIL,
  PATH_COMPONENT__CREDIT_RETIREMENT_ACCOUNT_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_STUDENT_LOAN_DETAIL,
  PATH_COMPONENT__CREDIT_STUDENT_LOAN_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_STUDENT_TUITION_DETAIL,
  PATH_COMPONENT__CREDIT_STUDENT_TUITION_EXIT_BOUNCE,
  SLUG__CREDIT_QUARTERLY_TOTAL,
  SLUG__CREDIT_QUARTERLY_EXIT_BOUNCE,
  SLUG__CREDIT_HEALTH_OPTIONS,
  SLUG__CREDIT_HEALTH_EMPLOYER,
  SLUG__CREDIT_HEALTH_PLAN,
  SLUG__CREDIT_HEALTHCARE_1095A_PLAN_DETAIL,
  SLUG__CREDIT_HEALTH_EXIT_BOUNCE,
  SLUG__CREDIT_HSA_DETAIL,
  SLUG__CREDIT_HSA_EXIT_BOUNCE,
  SLUG__CREDIT_RETIREMENT_ACCOUNT_DETAIL,
  SLUG__CREDIT_RETIREMENT_ACCOUNT_EXIT_BOUNCE,
  SLUG__CREDIT_STUDENT_LOAN_DETAIL,
  SLUG__CREDIT_STUDENT_LOAN_EXIT_BOUNCE,
  SLUG__CREDIT_STUDENT_TUITION_DETAIL,
  SLUG__CREDIT_STUDENT_TUITION_EXIT_BOUNCE,
  SLUG__CREDIT_CHILD_CARE_DETAIL,
  SLUG__CREDIT_CHILD_CARE_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_CHILD_CARE_DETAIL,
  PATH_COMPONENT__CREDIT_CHILD_CARE_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_EARNED_INCOME_DONE,
  SLUG__CREDIT_EARNED_INCOME_DONE,
  PATH_COMPONENT__CREDIT_COGS_INTRO,
  PATH_COMPONENT__CREDIT_COGS_FIRST_YEAR,
  PATH_COMPONENT__CREDIT_COGS_START_INVENTORY,
  PATH_COMPONENT__CREDIT_COGS_NEW_INVENTORY,
  PATH_COMPONENT__CREDIT_COGS_END_INVENTORY,
  SLUG__CREDIT_COGS_INTRO,
  SLUG__CREDIT_COGS_FIRST_YEAR,
  SLUG__CREDIT_COGS_START_INVENTORY,
  SLUG__CREDIT_COGS_NEW_INVENTORY,
  SLUG__CREDIT_COGS_END_INVENTORY,
  PATH_COMPONENT__CREDIT_CHARITY_AMOUNT,
  PATH_COMPONENT__CREDIT_CHARITY_EXIT_BOUNCE,
  SLUG__CREDIT_CHARITY_AMOUNT,
  SLUG__CREDIT_CHARITY_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_COGS_EXIT_BOUNCE,
  SLUG__CREDIT_COGS_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_HOMEOWNER_DETAIL,
  PATH_COMPONENT__CREDIT_HOMEOWNER_EXIT_BOUNCE,
  SLUG__CREDIT_HOMEOWNER_DETAIL,
  SLUG__CREDIT_HOMEOWNER_EXIT_BOUNCE,
  SLUG__CREDIT_HOMEOWNER_INTEREST,
  PATH_COMPONENT__CREDIT_HOMEOWNER_ITEMIZED,
  SLUG__CREDIT_HOMEOWNER_ITEMIZED,
  PATH_COMPONENT__CREDIT_STUDENT_TUITION_QUALIFY,
  SLUG__CREDIT_STUDENT_TUITION_QUALIFY,
  SLUG__CREDIT_CAPITAL_LOSS_INFO,
  SLUG__CREDIT_CAPITAL_LOSS_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_CAPITAL_LOSS_INFO,
  PATH_COMPONENT__CREDIT_CAPITAL_LOSS_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_STANDARD_DEDUCTION,
  SLUG__CREDIT_STANDARD_DEDUCTION,
  SLUG__CREDIT_STANDARD_ITEMIZED,
  PATH_COMPONENT__CREDIT_STANDARD_ITEMIZED,
  SLUG__CREDIT_STANDARD_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_STANDARD_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_HOMEOWNER_FORM_UPLOAD,
  SLUG__CREDIT_HOMEOWNER_FORM_UPLOAD,
  SLUG__CREDIT_STANDARD_RESULT,
  PATH_COMPONENT__CREDIT_STANDARD_RESULT,
  PATH_COMPONENT__CREDIT_CHILD_TAX_DETAIL,
  SLUG__CREDIT_CHILD_TAX_DETAIL,
  PATH_COMPONENT__CREDIT_CHILD_TAX_EXIT_BOUNCE,
  SLUG__CREDIT_CHILD_TAX_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_HOMEOWNER_PREFILL_LOADING,
  SLUG__CREDIT_HOMEOWNER_PREFILL_LOADING,
  PATH_COMPONENT__CREDIT_ENERGY_INFO,
  SLUG__CREDIT_ENERGY_INFO,
  PATH_COMPONENT__CREDIT_ENERGY_EXIT_BOUNCE,
  SLUG__CREDIT_ENERGY_EXIT_BOUNCE,
  SLUG__CREDIT_TEACHING_INFO,
  PATH_COMPONENT__CREDIT_TEACHING_EXIT_BOUNCE,
  SLUG__CREDIT_TEACHING_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_TEACHING_INFO,
  PATH_COMPONENT__CREDIT_DISALLOWED,
  SLUG__CREDIT_DISALLOWED,
  PATH_COMPONENT__CREDIT_DISALLOWED_NAMES,
  SLUG__CREDIT_DISALLOWED_NAMES,
  PATH_COMPONENT__CREDIT_DISALLOWED_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_QBI,
  SLUG__CREDIT_QBI,
  PATH_COMPONENT__CREDIT_STUDENT_TUITION_FORM_UPLOAD,
  SLUG__CREDIT_STUDENT_TUITION_FORM_UPLOAD,
  PATH_COMPONENT__CREDIT_STUDENT_TUITION_AOTC_QUALIFY,
  SLUG__CREDIT_STUDENT_TUITION_AOTC_QUALIFY,
  PATH_COMPONENT__CREDIT_STUDENT_TUITION_PREFILL_LOADING,
  SLUG__CREDIT_STUDENT_TUITION_PREFILL_LOADING,
  SLUG__CREDIT_STANDARD_ITEMIZED_SUCCESS,
  PATH_COMPONENT__CREDIT_STANDARD_ITEMIZED_SUCCESS,
  PATH_COMPONENT__CREDIT_STANDARD_HOUSEHOLD_INCOMPLETE,
  SLUG__CREDIT_STANDARD_HOUSEHOLD_INCOMPLETE,
  PATH_COMPONENT__CREDIT_BUSINESS_LOANS_INTRO,
  SLUG__CREDIT_BUSINESS_LOANS_INTRO,
  PATH_COMPONENT__CREDIT_BUSINESS_LOANS_KNOWLEDGE,
  SLUG__CREDIT_BUSINESS_LOANS_KNOWLEDGE,
  SLUG__CREDIT_BUSINESS_LOANS_INTEREST,
  PATH_COMPONENT__CREDIT_BUSINESS_LOANS_INTEREST,
  PATH_COMPONENT__CREDIT_BUSINESS_LOANS_AMOUNT,
  SLUG__CREDIT_BUSINESS_LOANS_AMOUNT,
  PATH_COMPONENT__CREDIT_BUSINESS_LOANS_PERCENT,
  SLUG__CREDIT_BUSINESS_LOANS_PERCENT,
  PATH_COMPONENT__CREDIT_BUSINESS_LOANS_CONFIRM,
  SLUG__CREDIT_BUSINESS_LOANS_CONFIRM,
  PATH_COMPONENT__CREDIT_BUSINESS_LOANS_EXIT_BOUNCE,
  SLUG__CREDIT_BUSINESS_LOANS_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_HEALTHCARE_1095A_EXIT_BOUNCE,
  SLUG__CREDIT_HEALTHCARE_1095A_EXIT_BOUNCE,
  ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_OPTIONS,
  ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_HAS_1095A,
  SLUG__CREDIT_DISALLOWED_EXIT_BOUNCE,
  PATH_COMPONENT__CREDIT_HSA_DETAIL_FAMILY,
  SLUG__CREDIT_HSA_DETAIL_FAMILY,
  PATH_COMPONENT__CREDIT_SAVERS_INTRO,
  SLUG__CREDIT_SAVERS_INTRO,
  PATH_COMPONENT__CREDIT_SAVERS_INFO,
  SLUG__CREDIT_SAVERS_INFO,
  PATH_COMPONENT__CREDIT_SAVERS_WITHDRAWAL,
  SLUG__CREDIT_SAVERS_WITHDRAWAL,
  PATH_COMPONENT__CREDIT_SAVERS_EXIT_BOUNCE,
  SLUG__CREDIT_SAVERS_EXIT_BOUNCE
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import { INCOME_COLLECTION_TYPES, INCOME_ENDPOINT_ATTRIBUTES } from '@app/src/taxflow/sections/income/incomeConstants';
import {
  COLLECTION_TYPE__DEPENDENT,
  ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME
} from '@app/src/taxflow/sections/personal/constants/personalConstants';
import { getFilingStatus } from '@app/src/taxflow/shared/utils/sharedUtils';

export const getNextPathComponentMap = ({ isCollectionUploaded } = { isCollectionUploaded: false }) => {
  return {
    [SLUG__CREDIT_STANDARD_RESULT]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_STANDARD_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_STANDARD_ITEMIZED_SUCCESS]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_STANDARD_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_QUARTERLY_TOTAL]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_QUARTERLY_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_HEALTH_EMPLOYER]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_HEALTH_PLAN,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_HEALTHCARE_1095A_PLAN_DETAIL]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_HEALTHCARE_1095A_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_HSA_DETAIL_FAMILY]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_HSA_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_CAPITAL_LOSS_INFO]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_CAPITAL_LOSS_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_RETIREMENT_ACCOUNT_DETAIL]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_RETIREMENT_ACCOUNT_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_STUDENT_LOAN_DETAIL]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_STUDENT_LOAN_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_STUDENT_TUITION_DETAIL]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_STUDENT_TUITION_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_STUDENT_TUITION_AOTC_QUALIFY]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_STUDENT_TUITION_FORM_UPLOAD,
      questionnaireEarlyExit: isCollectionUploaded
    },
    [SLUG__CREDIT_STUDENT_TUITION_FORM_UPLOAD]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_STUDENT_TUITION_DETAIL,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_CHILD_CARE_DETAIL]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_CHILD_CARE_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_CHARITY_AMOUNT]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_CHARITY_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_COGS_START_INVENTORY]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_COGS_NEW_INVENTORY,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_COGS_NEW_INVENTORY]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_COGS_END_INVENTORY,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_COGS_END_INVENTORY]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_COGS_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_HOMEOWNER_ITEMIZED]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_HOMEOWNER_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_CHILD_TAX_DETAIL]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_CHILD_TAX_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_HOMEOWNER_FORM_UPLOAD]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_HOMEOWNER_DETAIL,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_ENERGY_INFO]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_ENERGY_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_TEACHING_INFO]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_TEACHING_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_DISALLOWED_NAMES]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_DISALLOWED_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_BUSINESS_LOANS_INTRO]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_BUSINESS_LOANS_KNOWLEDGE,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_BUSINESS_LOANS_AMOUNT]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_BUSINESS_LOANS_PERCENT,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_BUSINESS_LOANS_PERCENT]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_BUSINESS_LOANS_CONFIRM,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_BUSINESS_LOANS_KNOWLEDGE]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_BUSINESS_LOANS_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_BUSINESS_LOANS_CONFIRM]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_BUSINESS_LOANS_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_SAVERS_INTRO]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_SAVERS_INFO,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_SAVERS_INFO]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_SAVERS_WITHDRAWAL,
      questionnaireEarlyExit: false
    },
    [SLUG__CREDIT_SAVERS_WITHDRAWAL]: {
      nextPathComponent: PATH_COMPONENT__CREDIT_SAVERS_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    }
  };
};

export const getOptionPathComponentMap = ({ isCollectionUploaded }) => {
  return {
    [SLUG__CREDIT_STANDARD_DEDUCTION]: {
      itemized: {
        nextPathComponent: PATH_COMPONENT__CREDIT_STANDARD_ITEMIZED,
        questionnaireEarlyExit: false
      },
      standard: {
        nextPathComponent: PATH_COMPONENT__CREDIT_STANDARD_EXIT_BOUNCE,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__CREDIT_STUDENT_TUITION_QUALIFY]: {
      0: {
        nextPathComponent: PATH_COMPONENT__CREDIT_STUDENT_TUITION_FORM_UPLOAD,
        questionnaireEarlyExit: isCollectionUploaded
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_STUDENT_TUITION_AOTC_QUALIFY,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__CREDIT_COGS_INTRO]: {
      0: {
        nextPathComponent: PATH_COMPONENT__CREDIT_COGS_EXIT_BOUNCE,
        questionnaireEarlyExit: false
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_COGS_FIRST_YEAR,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__CREDIT_COGS_FIRST_YEAR]: {
      0: {
        nextPathComponent: PATH_COMPONENT__CREDIT_COGS_NEW_INVENTORY,
        questionnaireEarlyExit: false
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_COGS_START_INVENTORY,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__CREDIT_DISALLOWED]: {
      0: {
        nextPathComponent: PATH_COMPONENT__CREDIT_DISALLOWED_EXIT_BOUNCE,
        questionnaireEarlyExit: false
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_DISALLOWED_NAMES,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__CREDIT_BUSINESS_LOANS_KNOWLEDGE]: {
      0: {
        nextPathComponent: PATH_COMPONENT__CREDIT_BUSINESS_LOANS_AMOUNT,
        questionnaireEarlyExit: false
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_BUSINESS_LOANS_INTEREST,
        questionnaireEarlyExit: false
      },
      2: {
        nextPathComponent: PATH_COMPONENT__CREDIT_BUSINESS_LOANS_EXIT_BOUNCE,
        questionnaireEarlyExit: false
      }
    },
    [SLUG__CREDIT_SAVERS_INFO]: {
      0: {
        nextPathComponent: PATH_COMPONENT__CREDIT_SAVERS_WITHDRAWAL,
        questionnaireEarlyExit: false
      },
      1: {
        nextPathComponent: PATH_COMPONENT__CREDIT_SAVERS_EXIT_BOUNCE,
        questionnaireEarlyExit: false
      }
    }
  };
};

export const getNextPathComponent = ({ question, answer, deductions, queryResults }) => {
  if (question.slug === SLUG__CREDIT_STANDARD_ITEMIZED) {
    if (_.get(deductions, 'itemized')) {
      return { nextPathComponent: PATH_COMPONENT__CREDIT_STANDARD_ITEMIZED_SUCCESS };
    } else {
      return { nextPathComponent: PATH_COMPONENT__CREDIT_STANDARD_RESULT };
    }
  } else if (question.slug === SLUG__CREDIT_HOMEOWNER_DETAIL) {
    const interest = _.get(answer, ['value', SLUG__CREDIT_HOMEOWNER_INTEREST, 'value']);
    const standardDeduction = _.get(deductions, 'standard');

    if (interest > standardDeduction) {
      return { nextPathComponent: PATH_COMPONENT__CREDIT_HOMEOWNER_ITEMIZED };
    } else {
      return { nextPathComponent: PATH_COMPONENT__CREDIT_HOMEOWNER_EXIT_BOUNCE };
    }
  } else if (question.slug === SLUG__CREDIT_HEALTH_OPTIONS) {
    return _.get(answer, ['value', ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_OPTIONS, 'value']) === '0'
      ? {}
      : { nextPathComponent: PATH_COMPONENT__CREDIT_HEALTH_EMPLOYER };
  } else if (question.slug === SLUG__CREDIT_HEALTH_PLAN) {
    return _.get(answer, ['value', ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_HAS_1095A, 'value']) === '0'
      ? {
          nextPathComponent: PATH_COMPONENT__CREDIT_HEALTH_EXIT_BOUNCE,
          questionnaireEarlyExit: false
        }
      : {
          nextPathComponent: PATH_COMPONENT__CREDIT_HEALTH_EXIT_BOUNCE,
          questionnaireEarlyExit: false,
          questionnaireNextPathComponent: PATH_COMPONENT__CREDIT_HEALTHCARE_1095A_PLAN_DETAIL
        };
  } else if (question.slug === SLUG__CREDIT_HSA_DETAIL) {
    const filingStatus = getFilingStatus({ queryResults });
    return ['married', 'married_separate', 'head'].includes(filingStatus)
      ? { nextPathComponent: PATH_COMPONENT__CREDIT_HSA_DETAIL_FAMILY }
      : { nextPathComponent: PATH_COMPONENT__CREDIT_HSA_EXIT_BOUNCE };
  } else {
    return { nextPathComponent: null };
  }
};

export const getNextQuery = ({ question, nextPathComponent }) => {
  if (
    question.slug === SLUG__CREDIT_HEALTH_PLAN &&
    nextPathComponent === PATH_COMPONENT__CREDIT_HEALTHCARE_1095A_PLAN_DETAIL
  ) {
    return {
      collectionId: 1
    };
  }
  return {};
};

export const getSlugMap = () => {
  return {
    [PATH_COMPONENT__CREDIT_STANDARD_DEDUCTION]: SLUG__CREDIT_STANDARD_DEDUCTION,
    [PATH_COMPONENT__CREDIT_STANDARD_HOUSEHOLD_INCOMPLETE]: SLUG__CREDIT_STANDARD_HOUSEHOLD_INCOMPLETE,
    [PATH_COMPONENT__CREDIT_STANDARD_ITEMIZED]: SLUG__CREDIT_STANDARD_ITEMIZED,
    [PATH_COMPONENT__CREDIT_STANDARD_RESULT]: SLUG__CREDIT_STANDARD_RESULT,
    [PATH_COMPONENT__CREDIT_STANDARD_ITEMIZED_SUCCESS]: SLUG__CREDIT_STANDARD_ITEMIZED_SUCCESS,
    [PATH_COMPONENT__CREDIT_STANDARD_EXIT_BOUNCE]: SLUG__CREDIT_STANDARD_EXIT_BOUNCE,
    [PATH_COMPONENT__CREDIT_EARNED_INCOME_DONE]: SLUG__CREDIT_EARNED_INCOME_DONE,
    [PATH_COMPONENT__CREDIT_QUARTERLY_TOTAL]: SLUG__CREDIT_QUARTERLY_TOTAL,
    [PATH_COMPONENT__CREDIT_QUARTERLY_EXIT_BOUNCE]: SLUG__CREDIT_QUARTERLY_EXIT_BOUNCE,
    [PATH_COMPONENT__CREDIT_HEALTH_OPTIONS]: SLUG__CREDIT_HEALTH_OPTIONS,
    [PATH_COMPONENT__CREDIT_HEALTH_EMPLOYER]: SLUG__CREDIT_HEALTH_EMPLOYER,
    [PATH_COMPONENT__CREDIT_HEALTH_PLAN]: SLUG__CREDIT_HEALTH_PLAN,
    [PATH_COMPONENT__CREDIT_HEALTH_EXIT_BOUNCE]: SLUG__CREDIT_HEALTH_EXIT_BOUNCE,
    [PATH_COMPONENT__CREDIT_HEALTHCARE_1095A_PLAN_DETAIL]: SLUG__CREDIT_HEALTHCARE_1095A_PLAN_DETAIL,
    [PATH_COMPONENT__CREDIT_HEALTHCARE_1095A_EXIT_BOUNCE]: SLUG__CREDIT_HEALTHCARE_1095A_EXIT_BOUNCE,
    [PATH_COMPONENT__CREDIT_HSA_DETAIL]: SLUG__CREDIT_HSA_DETAIL,
    [PATH_COMPONENT__CREDIT_HSA_DETAIL_FAMILY]: SLUG__CREDIT_HSA_DETAIL_FAMILY,
    [PATH_COMPONENT__CREDIT_HSA_EXIT_BOUNCE]: SLUG__CREDIT_HSA_EXIT_BOUNCE,
    [PATH_COMPONENT__CREDIT_CAPITAL_LOSS_INFO]: SLUG__CREDIT_CAPITAL_LOSS_INFO,
    [PATH_COMPONENT__CREDIT_CAPITAL_LOSS_EXIT_BOUNCE]: SLUG__CREDIT_CAPITAL_LOSS_EXIT_BOUNCE,
    [PATH_COMPONENT__CREDIT_RETIREMENT_ACCOUNT_DETAIL]: SLUG__CREDIT_RETIREMENT_ACCOUNT_DETAIL,
    [PATH_COMPONENT__CREDIT_RETIREMENT_ACCOUNT_EXIT_BOUNCE]: SLUG__CREDIT_RETIREMENT_ACCOUNT_EXIT_BOUNCE,
    [PATH_COMPONENT__CREDIT_STUDENT_LOAN_DETAIL]: SLUG__CREDIT_STUDENT_LOAN_DETAIL,
    [PATH_COMPONENT__CREDIT_STUDENT_LOAN_EXIT_BOUNCE]: SLUG__CREDIT_STUDENT_LOAN_EXIT_BOUNCE,
    [PATH_COMPONENT__CREDIT_STUDENT_TUITION_QUALIFY]: SLUG__CREDIT_STUDENT_TUITION_QUALIFY,
    [PATH_COMPONENT__CREDIT_STUDENT_TUITION_AOTC_QUALIFY]: SLUG__CREDIT_STUDENT_TUITION_AOTC_QUALIFY,
    [PATH_COMPONENT__CREDIT_STUDENT_TUITION_FORM_UPLOAD]: SLUG__CREDIT_STUDENT_TUITION_FORM_UPLOAD,
    [PATH_COMPONENT__CREDIT_STUDENT_TUITION_PREFILL_LOADING]: SLUG__CREDIT_STUDENT_TUITION_PREFILL_LOADING,
    [PATH_COMPONENT__CREDIT_STUDENT_TUITION_DETAIL]: SLUG__CREDIT_STUDENT_TUITION_DETAIL,
    [PATH_COMPONENT__CREDIT_STUDENT_TUITION_EXIT_BOUNCE]: SLUG__CREDIT_STUDENT_TUITION_EXIT_BOUNCE,
    [PATH_COMPONENT__CREDIT_CHILD_CARE_DETAIL]: SLUG__CREDIT_CHILD_CARE_DETAIL,
    [PATH_COMPONENT__CREDIT_CHILD_CARE_EXIT_BOUNCE]: SLUG__CREDIT_CHILD_CARE_EXIT_BOUNCE,
    [PATH_COMPONENT__CREDIT_CHARITY_AMOUNT]: SLUG__CREDIT_CHARITY_AMOUNT,
    [PATH_COMPONENT__CREDIT_CHARITY_EXIT_BOUNCE]: SLUG__CREDIT_CHARITY_EXIT_BOUNCE,
    [PATH_COMPONENT__CREDIT_COGS_INTRO]: SLUG__CREDIT_COGS_INTRO,
    [PATH_COMPONENT__CREDIT_COGS_FIRST_YEAR]: SLUG__CREDIT_COGS_FIRST_YEAR,
    [PATH_COMPONENT__CREDIT_COGS_START_INVENTORY]: SLUG__CREDIT_COGS_START_INVENTORY,
    [PATH_COMPONENT__CREDIT_COGS_NEW_INVENTORY]: SLUG__CREDIT_COGS_NEW_INVENTORY,
    [PATH_COMPONENT__CREDIT_COGS_END_INVENTORY]: SLUG__CREDIT_COGS_END_INVENTORY,
    [PATH_COMPONENT__CREDIT_COGS_EXIT_BOUNCE]: SLUG__CREDIT_COGS_EXIT_BOUNCE,
    [PATH_COMPONENT__CREDIT_HOMEOWNER_DETAIL]: SLUG__CREDIT_HOMEOWNER_DETAIL,
    [PATH_COMPONENT__CREDIT_HOMEOWNER_FORM_UPLOAD]: SLUG__CREDIT_HOMEOWNER_FORM_UPLOAD,
    [PATH_COMPONENT__CREDIT_HOMEOWNER_PREFILL_LOADING]: SLUG__CREDIT_HOMEOWNER_PREFILL_LOADING,
    [PATH_COMPONENT__CREDIT_HOMEOWNER_ITEMIZED]: SLUG__CREDIT_HOMEOWNER_ITEMIZED,
    [PATH_COMPONENT__CREDIT_HOMEOWNER_EXIT_BOUNCE]: SLUG__CREDIT_HOMEOWNER_EXIT_BOUNCE,
    [PATH_COMPONENT__CREDIT_CHILD_TAX_DETAIL]: SLUG__CREDIT_CHILD_TAX_DETAIL,
    [PATH_COMPONENT__CREDIT_CHILD_TAX_EXIT_BOUNCE]: SLUG__CREDIT_CHILD_TAX_EXIT_BOUNCE,
    [PATH_COMPONENT__CREDIT_ENERGY_INFO]: SLUG__CREDIT_ENERGY_INFO,
    [PATH_COMPONENT__CREDIT_ENERGY_EXIT_BOUNCE]: SLUG__CREDIT_ENERGY_EXIT_BOUNCE,
    [PATH_COMPONENT__CREDIT_TEACHING_INFO]: SLUG__CREDIT_TEACHING_INFO,
    [PATH_COMPONENT__CREDIT_TEACHING_EXIT_BOUNCE]: SLUG__CREDIT_TEACHING_EXIT_BOUNCE,
    [PATH_COMPONENT__CREDIT_DISALLOWED]: SLUG__CREDIT_DISALLOWED,
    [PATH_COMPONENT__CREDIT_DISALLOWED_NAMES]: SLUG__CREDIT_DISALLOWED_NAMES,
    [PATH_COMPONENT__CREDIT_DISALLOWED_EXIT_BOUNCE]: SLUG__CREDIT_DISALLOWED_EXIT_BOUNCE,
    [PATH_COMPONENT__CREDIT_QBI]: SLUG__CREDIT_QBI,
    [PATH_COMPONENT__CREDIT_BUSINESS_LOANS_INTRO]: SLUG__CREDIT_BUSINESS_LOANS_INTRO,
    [PATH_COMPONENT__CREDIT_BUSINESS_LOANS_KNOWLEDGE]: SLUG__CREDIT_BUSINESS_LOANS_KNOWLEDGE,
    [PATH_COMPONENT__CREDIT_BUSINESS_LOANS_INTEREST]: SLUG__CREDIT_BUSINESS_LOANS_INTEREST,
    [PATH_COMPONENT__CREDIT_BUSINESS_LOANS_AMOUNT]: SLUG__CREDIT_BUSINESS_LOANS_AMOUNT,
    [PATH_COMPONENT__CREDIT_BUSINESS_LOANS_PERCENT]: SLUG__CREDIT_BUSINESS_LOANS_PERCENT,
    [PATH_COMPONENT__CREDIT_BUSINESS_LOANS_CONFIRM]: SLUG__CREDIT_BUSINESS_LOANS_CONFIRM,
    [PATH_COMPONENT__CREDIT_BUSINESS_LOANS_EXIT_BOUNCE]: SLUG__CREDIT_BUSINESS_LOANS_EXIT_BOUNCE,
    [PATH_COMPONENT__CREDIT_SAVERS_INTRO]: SLUG__CREDIT_SAVERS_INTRO,
    [PATH_COMPONENT__CREDIT_SAVERS_INFO]: SLUG__CREDIT_SAVERS_INFO,
    [PATH_COMPONENT__CREDIT_SAVERS_WITHDRAWAL]: SLUG__CREDIT_SAVERS_WITHDRAWAL,
    [PATH_COMPONENT__CREDIT_SAVERS_EXIT_BOUNCE]: SLUG__CREDIT_SAVERS_EXIT_BOUNCE
  };
};

export const getQuestionQueries = ({ question }) => {
  if (
    question.slug === PATH_COMPONENT__CREDIT_HEALTHCARE_1095A_PLAN_DETAIL ||
    question.slug === PATH_COMPONENT__CREDIT_CHILD_CARE_DETAIL ||
    question.slug === PATH_COMPONENT__CREDIT_STUDENT_TUITION_DETAIL
  ) {
    return [
      {
        coll_type: COLLECTION_TYPE__DEPENDENT,
        slug: ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME
      }
    ];
  } else if (question.slug === SLUG__CREDIT_QBI) {
    return [
      {
        coll_type: INCOME_COLLECTION_TYPES.FREELANCE,
        slug: INCOME_ENDPOINT_ATTRIBUTES.FREELANCE_INCOME
      }
    ];
  }

  return [];
};
