import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { colorDeactivatedMid, colorNeutralGraphite } from '@app/src/theme';
import '@app/src/Components/Common/ProgressBarWithTitle/ProgressBarWithTitle.scss';

const ProgressBarWithTitle = ({ title, progress, style }) => {
  return (
    <div style={style}>
      <div className='text-base bold progress-bar-title' style={{ color: colorNeutralGraphite }}>
        {title}
      </div>
      <div className='progress-bar-container'>
        <LinearProgress
          variant='determinate'
          value={progress}
          color='success'
          style={{ backgroundColor: colorDeactivatedMid, borderRadius: 50 }}
        />
      </div>
    </div>
  );
};

export default ProgressBarWithTitle;
