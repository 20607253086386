import React, { useEffect } from 'react';
import Fade from '@mui/material/Fade';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Briefcase, Building4, Heart, House, Personalcard, UserSquare } from 'iconsax-react';
import { useDispatch, useSelector } from 'react-redux';
import ToggleButton from '@app/src/Components/Common/ToggleButton/ToggleButton';
import ResponseText from '@app/src/Components/HolisticOnboarding/components/ResponseText/ResponseText';
import VerticalIconButton from '@app/src/Components/HolisticOnboarding/components/VerticalIconButton/VerticalIconButton';
import {
  setHolisticOnboardingDependents,
  setHolisticOnboardingHomeOwner,
  setHolisticOnboardingIncomeType,
  setHolisticOnboardingInitialLoad,
  setHolisticOnboardingMarried,
  setHolisticOnboardingRent,
  setHolisticOnboardingStudentLoans
} from '@app/src/actions/holisticOnboardingActions';
import EducationIcon from '@app/src/assets/expenseCategoryIcons/Education.svg?react';
import ChildCare from '@app/src/assets/holisticOnboarding/child-care.svg?react';
import {
  dependentsSelector,
  homeOwnerSelector,
  incomeTypeSelector,
  initialLoadSelector,
  marriedSelector,
  rentSelector,
  studentLoansSelector
} from '@app/src/selectors/holisticOnboardingSelectors';
import { colorPrimary } from '@app/src/theme';

const OnboardingSituations = () => {
  const dispatch = useDispatch();

  const dependents = useSelector(dependentsSelector);
  const homeOwner = useSelector(homeOwnerSelector);
  const incomeTypes = useSelector(incomeTypeSelector);
  const married = useSelector(marriedSelector);
  const rent = useSelector(rentSelector);
  const studentLoans = useSelector(studentLoansSelector);
  const loading = useSelector(initialLoadSelector);

  const options = [
    {
      name: 'Married',
      icon: Heart,
      onClick: () => {
        const updatedValues = married === 'married' ? 'single' : 'married';

        dispatch(setHolisticOnboardingMarried(updatedValues));
      },
      selected: married === 'married'
    },
    {
      name: 'Dependents',
      icon: ChildCare,
      onClick: () => {
        const updatedValues = dependents > 0 ? 0 : 1;

        dispatch(setHolisticOnboardingDependents(updatedValues));
      },
      selected: dependents > 0
    },
    {
      name: 'Employee (W-2)',
      icon: Personalcard,
      onClick: () => {
        const isSelected = incomeTypes?.includes('salaried');
        const updatedValues = isSelected
          ? incomeTypes?.filter((type) => type !== 'salaried')
          : [...incomeTypes, 'salaried'];

        dispatch(setHolisticOnboardingIncomeType(updatedValues));
      },
      selected: incomeTypes?.includes('salaried')
    },
    {
      name: 'Freelance (1099)',
      icon: UserSquare,
      onClick: () => {
        const isSelected = incomeTypes?.includes('contractor');
        const updatedValues = isSelected
          ? incomeTypes?.filter((type) => type !== 'contractor')
          : [...incomeTypes, 'contractor'];

        dispatch(setHolisticOnboardingIncomeType(updatedValues));
      },
      selected: incomeTypes?.includes('contractor')
    },
    {
      name: 'Business owner',
      icon: Briefcase,
      onClick: () => {
        const isSelected = incomeTypes?.includes('owner');
        const updatedValues = isSelected ? incomeTypes?.filter((type) => type !== 'owner') : [...incomeTypes, 'owner'];

        dispatch(setHolisticOnboardingIncomeType(updatedValues));
      },
      selected: incomeTypes?.includes('owner')
    },
    {
      name: 'Student loans',
      icon: () => <EducationIcon stroke={colorPrimary} style={{ width: 24, height: 24 }} />,
      onClick: () => {
        dispatch(setHolisticOnboardingStudentLoans(!studentLoans));
      },
      selected: studentLoans
    },
    {
      name: 'Homeowner',
      icon: House,
      onClick: () => dispatch(setHolisticOnboardingHomeOwner(!homeOwner)),
      selected: homeOwner
    },
    {
      name: 'Renter',
      icon: Building4,
      onClick: () => dispatch(setHolisticOnboardingRent(!rent)),
      selected: rent
    }
  ];

  useEffect(() => {
    setTimeout(() => {
      dispatch(setHolisticOnboardingInitialLoad(false));
    }, 500);
  }, [dispatch]);

  return (
    <>
      <div className='h-onboarding-options-container'>
        {options.map(({ name, icon, onClick, selected }, idx) =>
          loading ? (
            <Skeleton key={idx} width='46%' height={100} variant='rounded' animation='pulse' />
          ) : (
            <VerticalIconButton
              key={idx}
              Icon={icon}
              text={name}
              color='primary'
              selected={selected}
              onClick={() => onClick(name)}
            />
          )
        )}
      </div>
      <Fade in={dependents > 0} unmountOnExit>
        <Stack direction='column'>
          <div className='h-onboarding-content-question-title'>How many dependents do you have?</div>
          <Stack spacing={1} direction='row'>
            {[1, 2, 3, 4, 5].map((value) => (
              <ToggleButton
                key={value}
                selected={dependents === value}
                onClick={() => dispatch(setHolisticOnboardingDependents(value))}
                center
              >
                {value}
              </ToggleButton>
            ))}
            <ToggleButton center selected={dependents > 5} onClick={() => dispatch(setHolisticOnboardingDependents(6))}>
              6+
            </ToggleButton>
          </Stack>
        </Stack>
      </Fade>
      <ResponseText
        style={{ marginTop: 16 }}
        visible={incomeTypes?.includes('owner') || incomeTypes?.includes('contractor')}
      />
    </>
  );
};

export default OnboardingSituations;
