import React from 'react';
import _ from 'lodash';
import LoadingButton from '@mui/lab/LoadingButton';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import classNames from 'classnames';
import { UserTag } from 'iconsax-react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toggleAssistantOpen } from '@app/src/actions/assistantActions';
import ArrowLeft from '@app/src/assets/arrow-left.svg?react';
import MenuIcon from '@app/src/assets/menu.svg?react';
import { getIsTaxFilingSandwiched, isReactNative } from '@app/src/global/Helpers';
import {
  hideAssistantBadgeSelector,
  assistantMessagesSelector,
  assistantOpenSelector,
  assistantSessionIdSelector,
  assistantUnreadMessagesSelector
} from '@app/src/selectors/assistantSelectors';
import { messagingPlatformSelector } from '@app/src/selectors/authSelectors';
import { isNavigationPanelEnabledSelector } from '@app/src/selectors/navigationListSelectors';
import { isTaxFilingSkipEnabledSelector } from '@app/src/selectors/taxValidationSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { updateHideAssistantBadgeCampaign } from '@app/src/services/taxFlowService';
import { setIsNavigationPanelOpen } from '@app/src/taxflow/navigation/actions/navigationActions';
import { SLUG__BULK_UPLOAD_QUESTIONS_INTRO } from '@app/src/taxflow/sections/special/constants/specialConstants';
import {
  currentQuestionSelector,
  isNextButtonLoadingSelector,
  resultLoadingSelector,
  showFooterSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';
import '@app/src/Components/TaxFlow/Question/TaxFlowQuestionFooter.scss';

const TaxFlowQuestionFooter = ({
  currentQuestion,
  onPrev,
  onNext,
  isPrev,
  isNext,
  isNextDisabled,
  isTaxFilingSkipEnabled,
  isUpdating,
  showFooter,
  isNavigationPanelEnabled,
  sessionId,
  messages,
  assistantOpen,
  unreadMessages,
  messagingPlatform,
  contextLoading,
  resultLoading,
  NextButtonLabel = 'Next',
  fullWidth,
  isNextButtonLoading,
  hideAssistantBadge,
  renderFooterLeft,
  setIsNavigationPanelOpen,
  toggleAssistantOpen,
  updateHideAssistantBadgeCampaign
}) => {
  const location = useLocation();

  const isSkipEnabled = isTaxFilingSkipEnabled;
  const isDisabled =
    currentQuestion.slug === SLUG__BULK_UPLOAD_QUESTIONS_INTRO
      ? resultLoading
      : isNextDisabled || isUpdating || contextLoading;
  const isPrevDisabled = (isReactNative() && !location.key) || isUpdating || contextLoading;

  let buttonLabel;
  if (isSkipEnabled) {
    buttonLabel = 'Skip';
  } else {
    buttonLabel = NextButtonLabel;
  }

  const isFilingSandwiched = getIsTaxFilingSandwiched();

  if (isFilingSandwiched && contextLoading) {
    return null;
  }

  const onNavigationPanelButtonClicked = () => {
    setIsNavigationPanelOpen(true);
  };

  const handleAssistantToggle = async () => {
    await updateHideAssistantBadgeCampaign();
    const opening = !assistantOpen;
    const analyticsProps = {
      currentQuestion: _.get(currentQuestion, 'slug'),
      sessionId,
      messages: _.size(messages),
      origin: 'footer'
    };

    if (opening) {
      trackActivity('tax assistant: panel opened', analyticsProps);
    } else {
      trackActivity('tax assistant: panel closed', analyticsProps);
    }

    toggleAssistantOpen();
  };

  if (fullWidth) {
    return (
      <div
        className={classNames('taxflow-foot', {
          'taxflow-foot-loading': contextLoading,
          'taxflow-foot-app': isFilingSandwiched,
          'taxflow-foot-web': !isFilingSandwiched
        })}
      >
        <Button
          data-testid='next-button'
          fullWidth
          className='taxflow-foot-button'
          size='large'
          variant='contained'
          onClick={() => onNext && onNext()}
        >
          <div className='taxflow-foot-button-content'>{buttonLabel}</div>
        </Button>
      </div>
    );
  }

  return (
    <div
      className={classNames('taxflow-foot', {
        'taxflow-foot-loading': contextLoading,
        'taxflow-foot-visible': showFooter,
        'taxflow-foot-app': isFilingSandwiched,
        'taxflow-foot-web': !isFilingSandwiched
      })}
    >
      <div className='taxflow-foot-left-spacer' />
      <div className='taxflow-foot-content'>
        {renderFooterLeft
          ? renderFooterLeft()
          : !isFilingSandwiched && (
              <div className='taxflow-foot-content-nav'>
                <Button
                  variant='outlined'
                  className={classNames('taxflow-foot-left-button', 'mobile-only', {
                    'taxflow-foot-left-button-disabled': !isNavigationPanelEnabled
                  })}
                  onClick={onNavigationPanelButtonClicked}
                >
                  <MenuIcon />
                </Button>
                {messagingPlatform && (
                  <Badge
                    color='error'
                    className='taxflow-foot-left-button mobile-only'
                    badgeContent={(() => {
                      if (assistantOpen) {
                        return null;
                      }

                      if (!hideAssistantBadge) {
                        return ' ';
                      }

                      if (hideAssistantBadge && unreadMessages > 0) {
                        return ' ';
                      }

                      return null;
                    })()}
                  >
                    <Button disableFocusRipple variant='outlined' onClick={handleAssistantToggle}>
                      <UserTag />
                    </Button>
                  </Badge>
                )}
              </div>
            )}
        {showFooter && (
          <Stack spacing={1} direction='row' className='actions-tools'>
            {isPrev && (
              <Button
                data-testid='prev-button'
                variant={isPrevDisabled ? 'contained' : 'outlined'}
                disableElevation
                className='taxflow-foot-button-prev'
                disabled={isPrevDisabled}
                onClick={() => onPrev && onPrev()}
              >
                <ArrowLeft />
              </Button>
            )}
            {isNext && (
              <div>
                <LoadingButton
                  data-testid='next-button'
                  variant={isSkipEnabled && !isDisabled ? 'outlined' : 'contained'}
                  color='primary'
                  disableElevation
                  className='taxflow-foot-button'
                  disabled={isDisabled}
                  loading={isNextButtonLoading}
                  onClick={() => onNext && onNext()}
                >
                  <div className='taxflow-foot-button-content'>{buttonLabel}</div>
                </LoadingButton>
              </div>
            )}
          </Stack>
        )}
      </div>
      <div className='taxflow-foot-right-spacer' />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isTaxFilingSkipEnabled: isTaxFilingSkipEnabledSelector(state),
  isNavigationPanelEnabled: isNavigationPanelEnabledSelector(state),
  isNextButtonLoading: isNextButtonLoadingSelector(state),
  showFooter: showFooterSelector(state),
  sessionId: assistantSessionIdSelector(state),
  messages: assistantMessagesSelector(state),
  assistantOpen: assistantOpenSelector(state),
  currentQuestion: currentQuestionSelector(state),
  hideAssistantBadge: hideAssistantBadgeSelector(state),
  unreadMessages: assistantUnreadMessagesSelector(state),
  resultLoading: resultLoadingSelector(state),
  messagingPlatform: messagingPlatformSelector(state)
});

const mapDispatchToProps = {
  setIsNavigationPanelOpen,
  toggleAssistantOpen,
  updateHideAssistantBadgeCampaign
};
const ConnectedTaxFlowQuestionFooter = connect(mapStateToProps, mapDispatchToProps)(TaxFlowQuestionFooter);

export default ConnectedTaxFlowQuestionFooter;
