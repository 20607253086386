import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import FuzzySearch from 'fuzzy-search';
import { connect } from 'react-redux';
import SearchList from '@app/src/Components/Common/SearchList/SearchList';
import { setCurrentAnswer } from '@app/src/actions/taxFlowActions';
import { BUSINESS_CODES_SEARCH_LIST_MAX_ITEMS } from '@app/src/constants/constants';
import { INCOME_SLUGS } from '@app/src/taxflow/sections/income/incomeConstants';
import { colorFilterSecondary } from '@app/src/theme';
const TaxFlowBusinessCategoryItem = ({ taxFlow, setCurrentAnswer }) => {
  const businessTypes = _.get(taxFlow, 'businessCodes');
  const selectedBusinessType = _.find(businessTypes, {
    code: _.get(taxFlow, ['currentAnswer', 'value', INCOME_SLUGS.FREELANCE_BUSINESS_CODE, 'value'])
  });

  const [listItems, setListItems] = useState([]);
  const [fuzzySearcher, setFuzzySearcher] = useState(
    new FuzzySearch([], ['code', 'label', 'industry'], { sort: true })
  );
  const [query, setQuery] = useState('');

  useEffect(() => {
    setFuzzySearcher(
      new FuzzySearch(
        _.sortBy(businessTypes, ({ order }) => (_.isUndefined(order) ? Infinity : order)),
        ['code', 'label', 'industry'],
        { sort: true }
      )
    );
  }, [businessTypes, setFuzzySearcher]);

  useEffect(() => {
    const itemsMatchingQuery = fuzzySearcher.search(query);
    const fallbackBusinessTypes = _.filter(businessTypes, { fallback: true });

    if (_.isNil(selectedBusinessType)) {
      setListItems(
        _.chain(itemsMatchingQuery)
          .filter(({ fallback }) => !fallback)
          .concat(fallbackBusinessTypes)
          .slice(0, BUSINESS_CODES_SEARCH_LIST_MAX_ITEMS)
          .map(({ label, code, industry }, idx) => ({
            id: idx,
            name: label,
            checked: _.get(selectedBusinessType, 'code') === code,
            showInList: true,
            code,
            industry
          }))
          .value()
      );
    } else {
      setListItems([]);
    }
  }, [selectedBusinessType, businessTypes, query, fuzzySearcher]);

  const onSelectItem = (item) => {
    const industry = _.get(item, 'industry');
    const code = _.get(item, 'code');
    const answer = {
      [INCOME_SLUGS.FREELANCE_INDUSTRY]: { value: industry },
      [INCOME_SLUGS.FREELANCE_BUSINESS_CODE]: { value: code }
    };
    setCurrentAnswer({ value: answer });
    setQuery(item.name);
  };

  const onUnselectItem = () => {
    const answer = {
      [INCOME_SLUGS.FREELANCE_INDUSTRY]: { value: undefined },
      [INCOME_SLUGS.FREELANCE_BUSINESS_CODE]: { value: undefined }
    };
    setCurrentAnswer({ value: answer });
  };

  const onSearch = (query) => {
    setQuery(query);
    setCurrentAnswer({ value: null });
  };

  return (
    <div className='steps-body'>
      <SearchList
        items={listItems}
        listHeaderText={_.isEmpty(query) && 'Frequently selected categories:'}
        query={query}
        onAddItem={onSelectItem}
        onRemoveItem={onUnselectItem}
        onSearch={onSearch}
        iconFilter={colorFilterSecondary}
      />
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = { setCurrentAnswer };

const ConnectedTaxFlowBusinessCategoryItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowBusinessCategoryItem);

export default ConnectedTaxFlowBusinessCategoryItem;
