import React, { useEffect, useState } from 'react';
// Redux init
// Get Store init
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
// Util
// Date Localization
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// Notistack
// Components
import jwt_decode from 'jwt-decode';
import { SnackbarProvider } from 'notistack';
import { Provider, connect } from 'react-redux';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import PageNotFound from '@app/src/Components/Common/PageNotFound/PageNotFound';
import PrivateRoute from '@app/src/Components/Common/PrivateRoute';
import KeeperHelmet from '@app/src/Components/KeeperHelmet/KeeperHelmet';
import LoginPhone from '@app/src/Components/Login/LoginWithOTP/LoginPhone';
import LoginPublicOnlyRoute from '@app/src/Components/Login/LoginWithOTP/LoginPublicOnlyRoute';
import { SnackbarUtilsConfigurator } from '@app/src/Components/Snackbar/Snackbar';
import { setCurrentUser } from '@app/src/actions/authActions';
import history from '@app/src/keeperHistory';
import routes from '@app/src/routes';
import '@app/src/sentMsgToWeb';
import { setUser } from '@app/src/services/analyticsService';
import { logout, setLoggedInCookie, getSettings } from '@app/src/services/authService';
import store from '@app/src/store/store';
// Styles
import theme from '@app/src/theme';
import { setAuthToken } from '@app/src/utils';
import '@app/src/index.scss';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  setCurrentUser,
  logout,
  getSettings
};

const Routes = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ setCurrentUser, logout, getSettings }) => {
  const [analyticsReady, setAnalyticsReady] = useState(false);
  useEffect(() => {
    const prepareUser = async () => {
      try {
        // Mange Authentication
        if (localStorage.KeeperToken) {
          /**
           * Set auth token header auth
           */
          const token = localStorage.KeeperToken;
          setAuthToken(token);
          /**
           * Decode token and get user info and exp
           * Set user and isAuthenticated
           **/
          const decoded = jwt_decode(token);
          setCurrentUser(decoded);
          /**
           * Check for expired token
           */
          const currentTime = Date.now() / 1000;
          if (decoded.exp < currentTime) {
            /**
             * Logout user
             */
            logout();
            /**
             * Clear current Profile
             * Redirect to login
             */
            window.location.href = '/';
          }
          //set user for analytics
          await setUser(decoded);
          //set login flag cookie
          setLoggedInCookie(decoded);
          getSettings();
        } else {
          /**
           * Logout user
           */
          logout({ origin: 'page_load' });
        }
      } finally {
        setAnalyticsReady(true);
      }
    };
    prepareUser();
  }, [setCurrentUser, getSettings, logout]);

  const routeComponents = routes.map((r, i) => {
    if (r.private) {
      return <PrivateRoute key={i} {...r} />;
    } else if (r.publicOnly) {
      return <LoginPublicOnlyRoute key={i} {...r} />;
    } else {
      return <Route key={i} {...r} />;
    }
  });

  // Only render routes if analytics tracking is ready.
  // If we don't wait, we run the risk of pushing events prior to identifying a user
  return (
    analyticsReady && (
      <Router history={history}>
        <>
          <KeeperHelmet />
          <SnackbarUtilsConfigurator />
          <Switch>
            <LoginPublicOnlyRoute exact path='/' component={LoginPhone} />
            <Route exact path='/signup' render={() => <Redirect to='/onboarding' />} />
            <Redirect exact path='/subscriptions' to='/settings/subscription' />
            <Redirect exact path='/file-taxes' to='/tax-filing' />
            <Redirect exact path='/expenses/export' to='/expenses' />
            <Redirect exact path='/expenses/new' to='/expenses' />
            <Redirect exact path='/expenses/rules' to='/expenses' />
            <Redirect exact path='/expenses/rules/new' to='/expenses' />
            <Redirect exact path='/tax-filing/profile' to='/tax-filing' />
            {routeComponents}
            <Route exact component={PageNotFound} />
          </Switch>
        </>
      </Router>
    )
  );
});

const App = () => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Provider store={store}>
          <SnackbarProvider className='snackbar' autoHideDuration={5000} maxSnack={3} preventDuplicate>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Routes />
            </ThemeProvider>
          </SnackbarProvider>
        </Provider>
      </LocalizationProvider>
    </>
  );
};

export default App;
