export const Url_EXPENSE_RETRO_LOADING_INITIAL = 'expense-retro-loading-initial';
export const Url_FACEBOOK_CLICK_ID_PARAM_KEY = 'fbclid';
export const Url_FACEBOOK_CLICK_ID_STORAGE_KEY = 'fbc';
export const Url_FACEBOOK_CLICK_ID_COOKIE_KEY = '_fbc';
export const Url_GOOGLE_CLICK_ID_PARAM_KEY = 'gclid';
export const Url_GOOGLE_CLICK_ID_STORAGE_KEY = 'gclid';
export const Url_ONBOARDING_DURATION = 'onboarding-duration';
export const Url_ONBOARDING_DURATION_MONTH = 'onboarding-duration-month';
export const Url_ONBOARDING_DURATION_YEAR = 'onboarding-duration-year';
export const Url_ONBOARDING_DURATION_DATE = 'onboarding-duration-date';
export const Url_ONBOARDING_ESTIMATE_REFUND = 'onboarding-estimate-refund';
export const Url_ONBOARDING_HOME = 'onboarding-home';
export const Url_ONBOARDING_INCOME_TYPE = 'onboarding-income-type';
export const Url_ONBOARDING_JOB_SELECT = 'onboarding-job-select';
export const Url_ONBOARDING_MEAL = 'onboarding-meal';
export const Url_ONBOARDING_SAVINGS_CALC = 'onboarding-savings-calc';
export const Url_ONBOARDING_TRANSPORT = 'onboarding-transport';
export const Url_ONBOARDING_TRAVEL = 'onboarding-travel';
export const Url_ONBOARDING_WRITE_OFFS = 'onboarding-write-offs';
export const Url_ONBOARDING_APP_DOWNLOAD = 'onboarding-app-download';
export const Url_ONBOARDING_VERIFY = 'onboarding-verify';
export const Url_PRESELECT_JOB_PARAM = 'job';
export const Url_SIGNUP = 'signup';
export const Url_SIGNUP_LINK = 'signup-link';
export const Url_SIGNUP_PHONE = 'signup-phone';
export const Url_SIGNUP_PHONE_ONLY = 'signup-phone-only';
export const Url_SIGNUP_PHONE_ONLY_TEST = 'signup-phone-only-test';
export const Url_TIKTOK_PARAM_KEY = 'ttclid';
export const Url_TIKTOK_STORAGE_KEY = 'ttclid';
export const Url_TUNE_TRANSACTION_PARAM_KEY = 'tid';
export const Url_TUNE_TRANSACTION_STORAGE_KEY = 'tune_transaction_id';
export const Url_ONBOARDING_ANCHOR_FIRST = 'onboarding-anchor-first';
export const Url_ONBOARDING_ANCHOR_LAST = 'onboarding-anchor-last';
export const Url_ONBOARDING_ANCHOR_BANK = 'onboarding-anchor-bank';
export const Url_SIGNUP_PHONE_ONLY_PHONE = 'signup-phone-only-phone';

// Experiments Key in Local Storage
export const ONBOARDING_EXPERIMENTS_KEY = 'onboardingExperiments';

// Holistic Onboarding Constants
const BASE_PATH = '/h/onboarding';

export const PATHNAME_ONBOARDING__APP_DOWNLOAD = `${BASE_PATH}/app-download`;
export const PATHNAME_ONBOARDING__DRIVE = `${BASE_PATH}/drive`;
export const PATHNAME_ONBOARDING__HOME = `${BASE_PATH}/home`;
export const PATHNAME_ONBOARDING__JOB_DURATION = `${BASE_PATH}/job-duration`;
export const PATHNAME_ONBOARDING__JOB_SELECT = `${BASE_PATH}/job-select`;
export const PATHNAME_ONBOARDING__LINK = `${BASE_PATH}/link`;
export const PATHNAME_ONBOARDING__LOADING_INITIAL = `${BASE_PATH}/loading-initial`;
export const PATHNAME_ONBOARDING__PERCENTAGES = `${BASE_PATH}/percentages`;
export const PATHNAME_ONBOARDING__MEAL = `${BASE_PATH}/meal`;
export const PATHNAME_ONBOARDING__PHONE = `${BASE_PATH}/phone`;
export const PATHNAME_ONBOARDING__SIGNUP = `${BASE_PATH}/signup`;
export const PATHNAME_ONBOARDING__SITUATIONS = `${BASE_PATH}/situations`;
export const PATHNAME_ONBOARDING__TRAVEL = `${BASE_PATH}/travel`;

export const PATHS_WITHOUT_PROGRESS_BAR = [
  PATHNAME_ONBOARDING__LOADING_INITIAL,
  PATHNAME_ONBOARDING__APP_DOWNLOAD,
  PATHNAME_ONBOARDING__PERCENTAGES
];

export const PATHS_WITH_RETRO_LOADING_PROGRESS_BAR = [
  PATHNAME_ONBOARDING__APP_DOWNLOAD,
  PATHNAME_ONBOARDING__PERCENTAGES
];
export const PATHS_WITHOUT_BACK_BUTTON = [PATHNAME_ONBOARDING__LINK, PATHNAME_ONBOARDING__PERCENTAGES];
export const PATHS_WITHOUT_FOOTER = [PATHNAME_ONBOARDING__LOADING_INITIAL, PATHNAME_ONBOARDING__APP_DOWNLOAD];
export const PATHS_WITHOUT_TRANSITION = [PATHNAME_ONBOARDING__SITUATIONS];

export const SIGNUP_EMAIL_EXISTS_SNACKBAR_MESSAGE = 'This email is already associated with an account. Please log in.';
export const SIGNUP_PHONE_EXISTS_SNACKBAR_MESSAGE =
  'This phone number is already associated with an account. Please log in.';
