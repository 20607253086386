import React from 'react';
import _ from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames';
import AddIcon from '@app/src/assets/add.svg?react';
import CloseIcon from '@app/src/assets/close.svg?react';
import WarningIcon from '@app/src/assets/warning.svg?react';
import '@app/src/Components/TaxFlow/Common/TaxFlowPill.scss';

const TaxFlowPill = ({ text, icon, loading, removable, variant = 'default', onClick = _.noop, onRemove = _.noop }) => {
  const onRemovePill = (e) => {
    e.stopPropagation();
    onRemove();
  };

  return (
    <div
      className={classNames([
        'taxflow-pill',
        {
          'taxflow-pill-warning': variant === 'warning',
          'taxflow-pill-addable': variant === 'add',
          'taxflow-pill-disabled': variant === 'disabled'
        }
      ])}
      onClick={() => variant !== 'disabled' && onClick()}
      role='button'
      name={text}
    >
      {variant === 'warning' && !icon && <WarningIcon className='taxflow-pill-icon' width={16} height={16} />}
      {variant === 'add' && !icon && <AddIcon className='taxflow-pill-icon' width={16} height={16}></AddIcon>}
      {icon}
      <div
        className={classNames(['taxflow-pill-text'], {
          'taxflow-pill-text-pad-left': !_.includes(['warning', 'add'], variant) && !icon,
          'taxflow-pill-text-pad-right': !removable && !loading
        })}
      >
        {text}
      </div>
      {!loading && removable && (
        <CloseIcon
          className={classNames(['taxflow-pill-icon', 'taxflow-pill-remove'])}
          width={16}
          height={16}
          onClick={onRemovePill}
        />
      )}
      {loading && <CircularProgress className='taxflow-pill-icon' color='success' size={16} />}
    </div>
  );
};

export default TaxFlowPill;
