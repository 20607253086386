import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Bank } from 'iconsax-react';
import { colorPrimaryAlt3 } from '@app/src/theme';
import '@app/src/Components/Common/BankAccountLoader/BankAccountLoader.scss';

const BankAccountLoader = ({ name, style, startProgress = 0 }) => {
  const [progress, setProgress] = useState(startProgress);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 1));
    }, 500);

    return () => clearInterval(interval);
  });

  return (
    <div className='bank-account-loader' style={style}>
      <div className='bank-account-info'>
        <Bank size={28} />
        <div className='text-base'>{name}</div>
      </div>
      <div className='progress'>
        <CircularProgress
          value={progress}
          variant='determinate'
          size='36px'
          color='success'
          thickness={4}
          className='loader'
        />
        <svg
          className='center-circle'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle cx='12' cy='12' r='12' fill={colorPrimaryAlt3} />
        </svg>
      </div>
    </div>
  );
};

export default BankAccountLoader;
