import { useMemo } from 'react';
import _ from 'lodash';

export const useJobType = (jobs = [], incomeTypes, allJobs) => {
  const incomeType = useMemo(() => {
    const isOwner = incomeTypes.includes('owner');

    const isContractor = incomeTypes.includes('contractor');

    if (isOwner && isContractor) {
      return 'business / freelancing';
    }

    if (isOwner) {
      return 'business';
    }

    return 'freelancing';
  }, [incomeTypes]);

  if (jobs.length === 1) {
    const job = _.find(allJobs, { slug: jobs[0] });

    const jobAsVerb = job?.jobAsVerb;

    if (job?.jobAsVerb) {
      return {
        jobType: jobAsVerb,
        incomeType
      };
    }
  }

  return {
    jobType: incomeType,
    incomeType
  };
};
